import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Box, Typography, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import { useNavigate } from 'react-router-dom';
import Footer from "assets/Footer";
import SalesTab from 'layouts/data-validation/components/SalesTab';
import MarketingTab from 'layouts/data-validation/components/MarketingTab';
import DataReadinessTab from 'layouts/data-validation/components/DataReadinessTab';
import OthersTab from 'layouts/data-validation/components/OthersTab';
import DataLogTab from 'layouts/data-validation/components/DataLogTab';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import { useBrand } from 'layouts/utils/BrandContext';

function DataValidation() {
  const { selectedBrand, selectedRefresh, selectedFile } = useBrand();
  const { brandName, refreshName, tab } = useParams();
  const navigate = useNavigate();

  // Define tab indexes based on the tab names in the URL
  const tabMapping = {
    sales: 0,
    marketing: 1,
    dataReadiness: 2,
  //  dataLog: 3,
  };

  const [tabValue, setTabValue] = useState(tabMapping[tab] || 0);

 useEffect(() => {
    // Update the tab value whenever the URL changes
    if (tabMapping[tab] !== undefined) {
      setTabValue(tabMapping[tab]);
    }
  }, [tab]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    // Get the key from the tabMapping and navigate to the correct URL
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/data-validation/${brandName}/${refreshName}/${selectedTab}`);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });



  return (
    <DashboardLayout>
      <DashboardNavbar />

      <AppBar position="static">
        <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Sales"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>leaderboard</Icon>}
            style={tabStyle(0)}
            sx={{
                    backgroundColor: tabValue === 0 ? '#E3F2FD' : 'transparent',
                    border: tabValue === 0 ? '2px solid #247EEA' : 'none'
            }}
          />
          <Tab
            label="Marketing"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>stacked_line_chart</Icon>}
            style={tabStyle(1)}
            sx={{
                    backgroundColor: tabValue === 1 ? '#E3F2FD' : 'transparent',
                    border: tabValue === 1 ? '2px solid #247EEA' : 'none'
           }}
          />
          <Tab
             label="Data Readiness"
             icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>query_builder</Icon> }
             style={tabStyle(2)}
             sx={{
                    backgroundColor: tabValue === 2 ? '#E3F2FD' : 'transparent',
                    border: tabValue === 2 ? '2px solid #247EEA' : 'none'
             }}
          />
{/*           <Tab */}
{/*             label="Others" */}
{/*             icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>miscellaneous_services</Icon>} */}
{/*             style={tabStyle(2)} */}
{/*           /> */}
{/*           <Tab */}
{/*             label="Data Log" */}
{/*             icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>view_list</Icon>} */}
{/*             style={tabStyle(3)} */}
{/*           /> */}
        </Tabs>
            <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
      </AppBar>


      {tabValue === 0 && <SalesTab />}
      {tabValue === 1 && <MarketingTab />}
      {tabValue === 2 && <DataReadinessTab />}
{/*       {tabValue === 3 && <DataLogTab />} */}
      <Footer />
    </DashboardLayout>
  );
}

export default DataValidation;

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from 'layouts/utils/BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, salesOthers, theme) {
  return {
    fontWeight:
      salesOthers.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SalesOthers({ others_variable = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(others_variable)) {
    others_variable = [];
  }

  const filterValidVariables = (variables) => Array.isArray(variables) ? variables.filter(variable => others_variable.includes(variable)) : [];

  const [salesOthers, setSalesOthers] = useState(() => {
    const initialSalesOthers = filterValidVariables(configurationDataUserSelection?.salesOthers || []);
    return initialSalesOthers.length ? initialSalesOthers : [];
  });

  useEffect(() => {
    const initialSalesOthers = filterValidVariables(configurationDataUserSelection?.salesOthers || []);
    setSalesOthers(initialSalesOthers);
    updateConfigurationDataUserSelection('salesOthers', initialSalesOthers);
  }, [others_variable, configurationDataUserSelection?.salesOthers, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let newValue = [];

    if (value.includes('Select All')) {
      newValue = others_variable;
    } else if (value.includes('Deselect All')) {
      newValue = [];
    } else {
      newValue = Array.isArray(value) ? value : value.split(',');
      const validValues = filterValidVariables(newValue);
      newValue = validValues;
    }

    setSalesOthers(newValue);
    updateConfigurationDataUserSelection('salesOthers', newValue);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="others-variable-label">Others</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="others-variable-label"
        id="multiple-others-variable"
        multiple
        value={salesOthers.length > 0 ? salesOthers : []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-others-variable" label="Others" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        <MenuItem
          sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
          value="Select All"
        >
          Select All
        </MenuItem>
        <MenuItem
          sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
          value="Deselect All"
        >
          Deselect All
        </MenuItem>
        {others_variable.length > 0 ? (
          others_variable.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, salesOthers, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

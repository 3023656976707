import { useEffect, useState, useContext } from 'react';
import { SignedUrlContext } from 'hooks/useSignedUrl';

export const useSignedThumbnailUrls = (thumbnailUrls) => {
    const [signedThumbnailUrls, setSignedThumbnailUrls] = useState([]);
    const { getSignedUrl } = useContext(SignedUrlContext); // Get the getSignedUrl function from context

    useEffect(() => {
        const fetchSignedUrls = async () => {
            const signedUrlsPromises = thumbnailUrls.map((thumbnailUrl) => getSignedUrl(thumbnailUrl));
            const signedUrls = await Promise.all(signedUrlsPromises);
            setSignedThumbnailUrls(signedUrls); // Update state after all URLs are signed
        };

        if (thumbnailUrls.length > 0) {
            fetchSignedUrls();
        }
    }, [thumbnailUrls, getSignedUrl]);

    return signedThumbnailUrls;
};

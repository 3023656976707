import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Card, Alert, Button, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { blue } from '@mui/material/colors';
import Pymc_png from 'assets/images/pymc.png';
import Robyn_png from 'assets/images/robyn.png';
import Lightweight_png from 'assets/images/lightweight.png';
import Ols_png from 'assets/images/ols.png';
import Unknown_png from 'assets/images/unknown.png';
import { useBrand } from 'layouts/utils/BrandContext';
import { useDataConfiguration } from 'hooks/useDataConfiguration';
import useSaveBrandRefreshStepSelection from 'layouts/utils/SaveBrandRefreshStepSelection';

const ModelingSetupTab = ( {setTabValue} ) => {
    const navigate = useNavigate();
    const {
        selectedBrand,
        selectedRefresh,
        setSelectedRefresh,
        configurationData,
        setConfigurationData,
        saveContext,
        loadContext,
        updateModelLogs,
        updateModelDownloadLink,
        updateIsRunning,
        user_company,
        user_email,
        currentStep,
        updateStep } = useBrand();
    const { brandName, refreshName, tab } = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [currentModelType, setCurrentModelType] = useState(null);
    const auth = useAuthInfo();
    const saveBrandRefreshStepSelection = useSaveBrandRefreshStepSelection();

    const mmm_labs_bucket = process.env.REACT_APP_MMM_LABS_BUCKET;

    const [models, setModels] = useState(() => {
      const savedModels = JSON.parse(localStorage.getItem('models') || '{}');
      return {
        pymc: { taskID: null, ...savedModels.pymc },
        robyn: { taskID: null, ...savedModels.robyn },
        lightweight: { taskID: null, ...savedModels.lightweight },
        ols: { taskID: null, ...savedModels.ols },
      };
    });

    useEffect(() => {
      localStorage.setItem('models', JSON.stringify(models));
      //console.log("models storage: ",  JSON.stringify(models));
    }, [models]);


	useEffect(() => {
		const fetchContext = async () => {
			if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
			  await loadContext(brandName, refreshName);
			}

		    if (selectedRefresh?.models) {
		        const updatedModels  = {
                    pymc: {
                        ...selectedRefresh.models.pymc,
                        logs: selectedRefresh.models.pymc?.logs || [],
                        downloadLink: selectedRefresh.models.pymc?.downloadLink || '',
                        isRunning: selectedRefresh.models.pymc?.isRunning || false,
                    },
                    robyn: {
                        ...selectedRefresh.models.robyn,
                        logs: selectedRefresh.models.robyn?.logs || [],
                        downloadLink: selectedRefresh.models.robyn?.downloadLink || '',
                        isRunning: selectedRefresh.models.robyn?.isRunning || false,
                    },
                    lightweight: {
                        ...selectedRefresh.models.lightweight,
                        logs: selectedRefresh.models.lightweight?.logs || [],
                        downloadLink: selectedRefresh.models.lightweight?.downloadLink || '',
                        isRunning: selectedRefresh.models.lightweight?.isRunning || false,
                    },
                    ols: {
                        ...selectedRefresh.models.ols,
                        logs: selectedRefresh.models.ols?.logs || [],
                        downloadLink: selectedRefresh.models.ols?.downloadLink || '',
                        isRunning: selectedRefresh.models.ols?.isRunning || false,
                    },
	            };
				const updatedRefresh = {
	                ...selectedRefresh,
	                models: updatedModels,
	            };

	            // Update only if the new refresh data differs
	            if (JSON.stringify(updatedRefresh) !== JSON.stringify(selectedRefresh)) {
	                setSelectedRefresh(updatedRefresh);
	            }
		    }
	  };
	  fetchContext();
	}, [brandName, refreshName, loadContext, selectedBrand])

    const {
        fetchDataConfigurationUserSelection,
        getUiConfigurationValues
    } = useDataConfiguration();

    const [taskIDs, setTaskIDs] = useState(() => {
        const savedTaskIDs = JSON.parse(localStorage.getItem('taskIDs'));
        return savedTaskIDs || {
            pymc: null,
            robyn: null,
            lightweight: null,
            ols: null,
        };
    });

    const updateTaskID = (modelType, taskID) => {
        setTaskIDs((prevTaskIDs) => {
            const updatedTaskIDs = {
                ...prevTaskIDs,
                [modelType]: taskID,
            };
            localStorage.setItem('taskIDs', JSON.stringify(updatedTaskIDs));
            return updatedTaskIDs;
        });
    };

    const [shouldConnect, setShouldConnect] = useState(() => {
        const savedShouldConnect = JSON.parse(localStorage.getItem('shouldConnect'));
        return savedShouldConnect || {
            pymc: false,
            robyn: false,
            lightweight: false,
            ols: false,
        };
    });

    const MAX_RECONNECT_ATTEMPTS = 3;
    const RECONNECT_DELAY = 5000; // 5 seconds between attempts

    const updateShouldConnect = (modelType, shouldConnectValue) => {
        console.log('updateShouldConnect', modelType, shouldConnectValue);
        setShouldConnect((prev) => {
            const updatedShouldConnect = {
                ...prev,
                [modelType]: shouldConnectValue
            };
            localStorage.setItem('shouldConnect', JSON.stringify(updatedShouldConnect));
            return updatedShouldConnect;
        });
    };

    const [reconnectAttempts, setReconnectAttempts] = useState(() => {
        const savedReconnectAttempts = JSON.parse(localStorage.getItem('reconnectAttempts'));
        return savedReconnectAttempts || {
            pymc: 0,
            robyn: 0,
            lightweight: 0,
            ols: 0,
        };
    });

    useEffect(() => {
        localStorage.setItem('reconnectAttempts', JSON.stringify(reconnectAttempts));
    }, [reconnectAttempts]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const updateModel = (modelType, taskID) => {
         updateTaskID(modelType, taskID);

        setModels(prevModels => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    taskID: taskID,
                }
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });
    };

    const clearLogs = useCallback((modelType) => {
        console.log("Clearing logs for model type: ", modelType);
        updateModelLogs(modelType, 'CLEAR_LOGS');
    }, [updateModelLogs]);

    const updateLogs = useCallback((modelType, newLog) => {
	    updateModelLogs(modelType, newLog);
    }, [updateModelLogs]);

    const handleDataConfiguration = async () =>  {
        if (!selectedBrand || !selectedRefresh) {
            navigate(`/brand-manager`);
        } else {
            const userSelection = await fetchDataConfigurationUserSelection();
            getUiConfigurationValues(userSelection);
            navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-configuration`);
        };
    };

    const hasModelResultsForType = useCallback((modelType) => {
        if (!selectedRefresh?.Models_results) {
            return false;
        }
        return selectedRefresh.Models_results.some(file => file.model_type === modelType);
    }, [selectedRefresh]);

    const hasModelRunForType = useCallback((modelType) => {
        const prefix = `https://storage.cloud.google.com/${mmm_labs_bucket}/`;
        const downloadLink = selectedRefresh.models[modelType].downloadLink?.startsWith(prefix)
            ? selectedRefresh.models[modelType].downloadLink.slice(prefix.length)
            : selectedRefresh.models[modelType].downloadLink;

        if (downloadLink) {
            const pathParts = downloadLink.split('/');
            const extractedRefreshName = pathParts[pathParts.length - 2];

            // Return true if the refresh matches
            return extractedRefreshName === selectedRefresh?.refresh_name;
        }

        // Return false if no model run is found
        return false;
    }, [selectedRefresh?.models]);

   useEffect(() => {
	    if (!selectedRefresh || !selectedRefresh.models) return;

        const prefix = `https://storage.cloud.google.com/${mmm_labs_bucket}/`;

        Object.keys(selectedRefresh.models).forEach((modelType) => {
            const downloadLink = selectedRefresh.models[modelType].downloadLink?.startsWith(prefix)
                ? selectedRefresh.models[modelType].downloadLink.slice(prefix.length)
                : selectedRefresh.models[modelType].downloadLink;

            if (downloadLink) {
                const pathParts = downloadLink.split('/');
                const extractedRefreshName = pathParts[pathParts.length - 2];

                // Check if the refresh name matches and if the model results need to be updated
                if (extractedRefreshName === selectedRefresh?.refresh_name &&
                    !selectedRefresh?.Models_results?.some(file => file.filename === downloadLink && file.model_type === modelType)) {

                    // Update the selected refresh and save the context only if the data has changed
                    const updatedRefresh = {
                        ...selectedRefresh,
                        has_model_results: true,
                        Models_results: [
                            ...(selectedRefresh?.Models_results || []),
                            {
                                filename: downloadLink,
                                file_url: `https://storage.cloud.google.com/${mmm_labs_bucket}/${downloadLink}`,
                                model_type: modelType
                            }
                        ]
                    };

                    setSelectedRefresh(updatedRefresh);
                    updateStep(4); // Move to 'View Results' step
                    saveBrandRefreshStepSelection(selectedBrand.brand_name, updatedRefresh.refresh_name, 4);
                    // Save the context after the refresh is updated
                    saveContext({
                        selectedBrand,
                        selectedRefresh: updatedRefresh,
                        configurationData,
                        currentStep: 4
                    });
                }
            }
        });
    }, [selectedRefresh, setSelectedRefresh, saveContext]);

    const hasUserSelectionChanged = useCallback((modelType) => {

        if (!selectedBrand && !selectedRefresh) return false;

        const hasModelAlreadyRunInCurrentSession = hasModelRunForType(modelType);

        const cachedUserSelection = localStorage.getItem(`previousUserSelection_${modelType}`);
        const previousUserSelection = cachedUserSelection && cachedUserSelection !== "undefined"
            ? JSON.parse(cachedUserSelection)
            : {};

         // Get the current UserSelection from configurationData
        const currentUserSelection = selectedRefresh?.UserSelection;

        // Compare the previous and current UserSelections
        const hasChanged = JSON.stringify(previousUserSelection) !== JSON.stringify(currentUserSelection);

        // Log for debugging
//         console.log(`${modelType} - Previous UserSelection: `, JSON.stringify(previousUserSelection));
//         console.log(`${modelType} - Current UserSelection: `, JSON.stringify(currentUserSelection));
//         console.log(`${modelType} - UserSelection has changed: ${hasChanged}`);
//         console.log(`${modelType} - Has Model Already Run: ${hasModelAlreadyRunInCurrentSession}`);

        // Enable the button if the model hasn't run or if UserSelection has changed
        return !hasModelAlreadyRunInCurrentSession || hasChanged;
    }, [selectedRefresh, configurationData]);

    const backToModelResults = (modelType) => {
        setTabValue(1);
    };

    const showAlert = (message, color = "info", modelType = null) => {
        setAlert({ show: true, message, color });
        setCurrentModelType(modelType);
        setOpenDialog(true);
    };

    const wsRefs = useRef({ pymc: null, robyn: null, lightweight: null, ols: null });

    const reconnectWebSocket = (modelType) => {
        if (selectedRefresh.models[modelType].isRunning && reconnectAttempts[modelType] < MAX_RECONNECT_ATTEMPTS) {
            setTimeout(() => {
                console.log("ModelingSetupTab>>index.jsx - Models: ", selectedRefresh.models);
                console.log("Reconnect Attempts: ", reconnectAttempts[modelType]);
                console.log(`Attempting to reconnect WebSocket for ${modelType}, attempt ${reconnectAttempts[modelType] + 1}`);
                setReconnectAttempts(prev => ({
                    ...prev,
                    [modelType]: prev[modelType] + 1,
                }));
                updateShouldConnect(modelType, true);
            }, RECONNECT_DELAY);
        } else {
            console.error(`Max reconnection attempts reached for ${modelType}. No further attempts will be made.`);
            updateIsRunning(modelType, false);
        }
    };

    useEffect(() => {
        const currentWsRefs = wsRefs.current;
        Object.keys(shouldConnect).forEach(modelType => {
            if (shouldConnect[modelType] && !currentWsRefs[modelType]) {
                const socketUrl = process.env.REACT_APP_WEB_SOCKET_SERVER;
                const socket = new WebSocket(socketUrl);

                socket.onopen = () => {
                    console.log(`WebSocket connection opened for ${modelType}`);
                    setReconnectAttempts(prev => ({
                        ...prev,
                        [modelType]: 0, // Reset on successful connection
                    }));
                    socket.send(JSON.stringify({ taskId: taskIDs[modelType], modelType }));
                    console.log("ModelingSetupTab>>index.jsx>>socket.onopen  - Models: ", selectedRefresh.models);
                    console.log(`Reconnect Attempts:  ${reconnectAttempts[modelType]} for model ${modelType}`);
                };

                socket.onmessage = (event) => {
                    const log = JSON.parse(event.data);
                    const timestamp = new Date().toLocaleString();
                    console.log("=================================================")
                    console.log(`log.model_type === modelType && log.task_id === taskIDs[modelType] ==> [${log.model_type} === ${modelType} && ${log.task_id} === ${taskIDs[modelType]}]`);
                    console.log(`${timestamp} socket.onmessage: ${log.message}`);
                    console.log("=================================================")

                    if (log.model_type === modelType && log.task_id === taskIDs[modelType]) {
                        updateLogs(modelType, `${timestamp} - ${log.message}`);

                        if (log.message.includes("Download results")) {
							updateIsRunning(modelType, false);
							updateShouldConnect(modelType, false);
							socket.close();
							currentWsRefs[modelType] = null;
                            const downloadLink = log.message.match(/Download results (.+)/)[1];
                            const log_message = `${timestamp} - <b>Model ${modelType} modeling completed successfully.</b>`;
                            console.log(`Download complete - WebSocket connection closed for ${modelType} task_id: ${taskIDs[modelType]}`);
                            updateModelDownloadLink(modelType, downloadLink);
                            showAlert(`Model ${modelType} modeling completed successfully!`, "success", modelType);
                        }
                        if (log.message.includes("Finished")) {
                            updateShouldConnect(modelType, false);
                            updateIsRunning(modelType, false);
                            socket.close();
                            currentWsRefs[modelType] = null;
                            console.log(`Finished - WebSocket connection closed for ${modelType} task_id: ${taskIDs[modelType]}`);
                            showAlert(log.message, "info", modelType);
                        }
                        if (log.message.includes("Error")) {
                            updateShouldConnect(modelType, false);
                            updateIsRunning(modelType, false);
                            socket.close();
                            currentWsRefs[modelType] = null;
                            console.log(`log error - WebSocket connection closed for ${modelType} task_id: ${taskIDs[modelType]}`);
                            showAlert(log.message, "error", modelType);
                        }
                        if (log.message.includes("Warning")) {
                            updateLogs(modelType, `${timestamp} - ${log.message}`);
                            showAlert(log.message, "warning", modelType);
                        } else {
                            updateLogs(log.model_type, log.message);
                        }
                    }
                };
                socket.onerror = (error) => {
                    console.error(`WebSocket error for ${modelType}:`, error);
                };
                socket.onclose = () => {
                    console.log(`socket.onclose - WebSocket connection closed for ${modelType}`);
                    currentWsRefs[modelType] = null;

                    // Check if we should reconnect
                    setTimeout(() => {
                        if (shouldConnect[modelType]) {
                            reconnectWebSocket(modelType);  // Attempt to reconnect
                        }
                    }, 0); // Ensure this check is done after state update
                };
                currentWsRefs[modelType] = socket;
            }
        });
        // Clean up WebSocket connections on component unmount
        return () => {
            Object.values(currentWsRefs).forEach(socket => {
                if (socket) {
                    socket.close();
                }
            });
        };
    }, [shouldConnect, taskIDs]); // Run effect when shouldConnect or taskIDs change

    const runModel = async (modelType) => {
        const taskID = uuidv4();
        updateIsRunning(modelType, true);

        localStorage.setItem(`previousUserSelection_${modelType}`, JSON.stringify(configurationData.UserSelection));

        const queryParams = new URLSearchParams({
            file_name: selectedRefresh.selectedFile.file.file_url,
            brand: selectedBrand.brand_name,
            refresh_name: selectedRefresh.refresh_name,
            user_selection: JSON.stringify(configurationData.UserSelection),
            task_id: taskID,
            model_type: modelType,
            user_email: user_email,
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/model/start_modeling?${queryParams}`;
        try {
            clearLogs(modelType);
            updateShouldConnect(modelType, true);
            updateModel(modelType, taskID);

            const timestamp = new Date().toLocaleString();
            updateLogs(modelType, `${timestamp} - Starting ${modelType} modeling...`);

            const response = await axios.post(
                fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
            if (response.status === 200) {
                const log_message = `${timestamp} - Modeling for ${modelType} for brand ${selectedBrand.brand_name} has started.\nTask id: ${taskID} - Model type: ${modelType}`;
                updateLogs(modelType, log_message);
            } else {
                showAlert(`Error running model: ${response.statusText || 'Unknown error'}`, 'error', modelType);
                updateShouldConnect(modelType, false);
                updateIsRunning(modelType, false);
            }

        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message || 'Error running model';
            showAlert(errorMessage, 'error', modelType);
            updateShouldConnect(modelType, false);
            updateIsRunning(modelType, false);
        }
    };

    const handleRunAllModels = async () =>  {
        ['robyn', 'pymc', 'lightweight', 'ols'].forEach(runModel);
    };

    return (
        <>
            <Dialog open={openDialog} aria-labelledby="Modeling" aria-describedby="run-models" onClose={handleCloseDialog}>
                <DialogTitle>Modeling Status for {currentModelType}</DialogTitle>
                <DialogContent>
                    {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {loading ? (
                <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress sx={{ color: blue[500] }} />
                    <MDTypography variant="normal" sx={{ mt: 2 }}>
                        Loading data configuration for <strong>{selectedRefresh?.refresh_name}</strong>...
                    </MDTypography>
                </MDBox>
            ) : (
           <Card sx={{  mb: '15px' }}>
           <MDBox sx={{ width: { xs: '100%', sm: '70%', md: '60%' }, margin: '0 auto', p: { xs: 2, sm: 4 }}}>
                <MDTypography component="div" sx={{ mt: 2, mb: 2, width: '100%', textAlign: 'center' }} variant="body2">
                        Select model to run for <strong>{selectedRefresh?.selectedFile?.display_name ? selectedRefresh?.selectedFile?.display_name : selectedRefresh?.selectedFile?.file.file_name || 'None'}</strong>
                        <br/>
                        Click on <PlayArrowIcon sx={{ color: '#006400', verticalAlign: 'middle' }}/> to start a model and on <VisibilityIcon sx={{ color: '#0000FF', verticalAlign: 'middle'  }}/> to view model results or select the tab
                        <a href="#" onClick={() => navigate(`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelsResults`)}> Model Results</a>.
                        <Tooltip title="Select a model to generate insights based on your data. Click on Model Results to view the best performing model and models' insights.">
                          <HelpOutlineIcon sx={{ fontSize: '16px', ml: 1, cursor: 'pointer', verticalAlign: 'middle' }} />
                        </Tooltip>
                </MDTypography>

                {selectedRefresh && ['robyn', 'pymc', 'ols', 'lightweight'].map(modelType => (
                        <MDBox key={modelType} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', margin: '20px 0' }}>
                            <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img
									    src={
									        modelType === 'robyn' ? Robyn_png :
									        modelType === 'pymc' ? Pymc_png :
									        modelType === 'ols' ? Ols_png :
									        modelType === 'lightweight' ? Lightweight_png :
									        Unknown_png
									    }
									    alt={modelType}
									    style={{ width: '40px', marginRight: '10px' }}
									/>
                                    <MDTypography variant="h6" component="div">{modelType.charAt(0).toUpperCase() + modelType.slice(1)}</MDTypography>
                                </MDBox>
                                <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title="Run Model">
                                        <span>
                                            <IconButton
                                                onClick={() => runModel(modelType)}
                                                sx={{ color: !hasUserSelectionChanged(modelType) ? 'lightgrey' : '#006400', fontSize: '0.875rem', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'  }}
                                                disabled={!selectedBrand || !selectedRefresh || !selectedRefresh.selectedFile || !hasUserSelectionChanged(modelType)}
                                            >
                                               Run {modelType.charAt(0).toUpperCase() + modelType.slice(1)}  <PlayArrowIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    {selectedRefresh?.models[modelType].isRunning && (
                                        <CircularProgress size={18} sx={{ color: blue[500], margin: '0 10px' }} />
                                    )}
                                    <Tooltip title="Last Run Results">
                                        <span>
                                            <IconButton
                                                disabled={!hasModelResultsForType(modelType)}
                                                onClick={() => backToModelResults(modelType)}
                                                sx={{ color: hasModelResultsForType(modelType) ? 'blue' : 'lightgrey', fontSize: '0.875rem', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'  }}
                                            >
                                                View results&nbsp;<VisibilityIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            {selectedRefresh?.models[modelType] && (
                                <MDBox sx={{ marginBottom: '20px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                                    <MDTypography variant="normal" component="div">Logs</MDTypography>
                                    <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
									    {selectedRefresh.models[modelType].logs?.map((log, index) => (
									            <MDTypography variant="caption" component="div" key={index}>
									                <li>
									                    <div
									                         dangerouslySetInnerHTML={{
										                        __html: log
										                            .replace(/\n/g, '<br />')       // Preserve line breaks
										                            .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // Replace tabs with spaces
										                            .replace(/  /g, '&nbsp;&nbsp;') // Preserve multiple spaces
										                    }}
														>
									                    </div>
									                </li>
									            </MDTypography>
									   ))}
									</MDBox>
                                    {selectedRefresh.models[modelType].downloadLink && !selectedRefresh.models[modelType].isRunning && (
                                        <MDBox sx={{ marginTop: 2, fontWeight: 'bold', textAlign: 'center' }}>
                                            <MDTypography variant="normal" component="div">
                                                <a href="#" onClick={() => navigate(`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelsResults`)}> Click here to view model results</a>
                                            </MDTypography>
                                        </MDBox>
                                    )}
                                </MDBox>
                            )}
                        </MDBox>
                    ))}
                    <MDTypography component="div" sx={{ mb: 2, width: '100%', textAlign: 'center' }} variant="h4">
                        <MDButton
                            variant="contained"
                            disabled={!selectedBrand || !selectedRefresh || !selectedRefresh.selectedFile}
                            color="dark"
                            onClick={handleRunAllModels}
                            sx={{ alignSelf: 'center', mb: '5px', mr: 2 }}
                        >
                            Run All Models
                        </MDButton>
                        <MDButton
                            variant="contained"
                            disabled={!selectedBrand || !selectedRefresh || !selectedRefresh.selectedFile}
                            color="dark"
                            onClick={handleDataConfiguration}
                            sx={{ alignSelf: 'center', mb: '5px' }}
                        >
                            Data Configuration
                        </MDButton>
                    </MDTypography>
                </MDBox>
            </Card>
        )}
     </>
	);
};

export default ModelingSetupTab;
import AIAgent from "layouts/ai-agent/AIAgent";
import BrandManager from "layouts/brand-manager/BrandManager";
import DataValidation from "layouts/data-validation/DataValidation";
import DataHub from "layouts/data-hub/DataHub";
import Modeling from "layouts/modeling/Modeling";
import AIInsights from "layouts/ai-insights/AIInsights";
import InsightDeckBuilder from "layouts/insight-deck-builder/InsightDeckBuilder";
import ScenarioPlanner from "layouts/scenario-planner/ScenarioPlanner";
import StarterGuide from "layouts/starter-guide/StarterGuide";
import LogOut from "layouts/authentication/logout";
import Icon from "@mui/material/Icon";
import getAccountUrl from "layouts/utils/Config";

const accountUrl = getAccountUrl();

const RoutesAdmin = ({ selectedBrand, selectedRefresh }) => {
    const routes = [
       {
            key: "start-guide",
            type: "collapse",
            name: "Starter Guide",
            icon: <Icon fontSize="small">flag</Icon>,
            route: selectedBrand && selectedRefresh
                ? `/starter-guide/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/step`
                : "/starter-guide",
            component: <StarterGuide />,
       },
        {
            key: "brand-manager",
            type: "collapse",
            name: "Brand Manager",
            icon: <Icon fontSize="small">add_task</Icon>,
            route: "/brand-manager",
            component: <BrandManager />,
        },
        {
            key: "data-hub",
            type: "collapse",
            name: "Data Hub",
            icon: <Icon fontSize="small">device_hub</Icon>,
            route: selectedBrand && selectedRefresh
                ? `/data-hub/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-connection`
                : "/data-hub",
            component: <DataHub />,
            subMenu: [
                {
                    key: "data-connection",
                    name: "Data Connection",
                    route: `/data-hub/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/data-connection`,
                    icon: "schema",
                    component: <DataHub />,
                },
            ],
        },
        {
            key: "data-validation",
            type: "collapse",
            name: "Data Validation",
            icon: <Icon fontSize="small">checklist</Icon>,
            route: selectedBrand && selectedRefresh
                ? `/data-validation/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/sales`
                : "/data-validation",
            component: <DataValidation />,
            subMenu: [
                {
                    key: "sales",
                    name: "Sales",
                    route: `/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/sales`,
                    icon: "leaderboard",
                    component: <DataValidation />,
                },
                {
                    key: "marketing",
                    name: "Marketing",
                    route: `/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/marketing`,
                    icon: "stacked_line_chart",
                    component: <DataValidation />,
                },
                {
                    key: "data-readiness",
                    name: "Data Readiness",
                    route: `/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/dataReadiness`,
                    icon: "query_builder",
                    component: <DataValidation />,
                },
            ],
        },
        {
            key: "modeling",
            type: "collapse",
            name: "Modeling",
            icon: <Icon fontSize="small">model_training</Icon>,
            route: selectedBrand && selectedRefresh
                ? `/modeling/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/modelingSetup`
                : "/modeling",
            component: <Modeling />,
            subMenu: [
                {
                    key: "modelingSetup",
                    name: "Modeling Setup",
                    route: `/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelingSetup`,
                    icon: "model_training",
                    component: <Modeling />,
                },
                {
                    key: "modelsResults",
                    name: "Model Results",
                    route: `/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelsResults`,
                    icon: "checklist_rtl",
                    component: <Modeling />,
                },
            ],
        },
        {
            key: "ai-insights",
            type: "collapse",
            name: "Insights",
            icon: <Icon fontSize="small">insights</Icon>,
            route: selectedBrand && selectedRefresh
                ? `/ai-insights/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/exec`
                : "/ai-insights",
            component: <AIInsights />,
            subMenu: [
                {
                    key: "exec",
                    name: "Executive Summary",
                    route: `/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/exec`,
                    icon: "summarize",
                    component: <AIInsights />,
                },
                {
                    key: "roi",
                    name: "ROI Results",
                    route: `/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/roi`,
                    icon: "dataset",
                    component: <AIInsights />,
                },
                {
                    key: "stats",
                    name: "Model Statistics",
                    route: `/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/stats`,
                    icon: "show_chart",
                    component: <AIInsights />,
                },
                {
                    key: "key",
                    name: "Key Recommendations",
                    route: `/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/key`,
                    icon: "vpn_key",
                    component: <AIInsights />,
                },
            ],
        },
        {
            key: "scenario-planner",
            type: "collapse",
            name: "Scenario Planner",
            icon: <Icon fontSize="small">edit_calendar</Icon>,
            route: selectedBrand && selectedRefresh
                ? `/scenario-planner/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}`
                : "/scenario-planner",
            component: <ScenarioPlanner />,
        },
        {
            key: "insight-deck-builder",
            type: "collapse",
            name: "Insight Deck Builder",
            icon: <Icon fontSize="small">present_to_all</Icon>,
            route: selectedBrand && selectedRefresh
                ? `/insight-deck-builder/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/deckBuilder`
                : "/insight-deck-builder",
            component: <InsightDeckBuilder />,
        },
       {
        key: "ai-agent",
        type: "collapse",
        name: "AI Agent",
        icon: <Icon fontSize="small">support_agent</Icon>,
        route: selectedBrand
            ? `/ai-agent/${selectedBrand.brand_name}`
            : "/ai-agent",
        component: <AIAgent />,
      },
        {
            key: "account",
            type: "collapse",
            name: "Account",
            icon: <Icon fontSize="small">manage_accounts</Icon>,
            route: accountUrl + '/account',
        },
        {
            key: "logout",
            type: "collapse",
            name: "Log Out",
            icon: <Icon fontSize="small">logout</Icon>,
            route: "/authentication/logout",
            component: <LogOut />,
        },
    ];
    return routes;
};

export default RoutesAdmin;

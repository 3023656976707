import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthInfo } from "@propelauth/react";
import Card from "@mui/material/Card";
import { Divider, Alert, DialogContent, DialogTitle, Dialog, DialogActions, Button } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import axios from 'axios';
import DateVariable from "layouts/data-configuration/components/DateVariable";
import SalesMetric from "layouts/data-configuration/components/sales/SalesMetric";
import SalesPrice from "layouts/data-configuration/components/sales/SalesPrice";
import SalesVariable from "layouts/data-configuration/components/sales/SalesVariable";
import SalesOthers from "layouts/data-configuration/components/sales/SalesOthers";
import SalesChannelNames from "layouts/data-configuration/components/sales/SalesChannelNames";
import AdsVariable from "layouts/data-configuration/components/advertising/AdsVariable";
import AdsChannels from "layouts/data-configuration/components/advertising/AdsChannels";
import AdsSpendMetric from "layouts/data-configuration/components/advertising/AdsSpendMetric";
import AdsModeledMetric from "layouts/data-configuration/components/advertising/AdsModeledMetric";
import OrganicVariables from "layouts/data-configuration/components/organic/OrganicVariables";
import UncontrollableVariables from "layouts/data-configuration/components/uncontrollable/UncontrollableVariables";
import { useBrand } from 'layouts/utils/BrandContext';
import { useSignedUrl } from 'hooks/useSignedUrl';
import useSaveBrandRefreshStepSelection from 'layouts/utils/SaveBrandRefreshStepSelection';

const DataConfiguration = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const { brandName, refreshName } = useParams();
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const { selectedBrand, selectedRefresh, configurationData, setConfigurationData, setConfigurationDataUserSelection, saveContext, loadContext, user_email, currentStep, updateStep } = useBrand();
  const [isModelInputsFilePresent, setIsModelInputsFilePresent] = useState(false);
  const mmm_labs_bucket = process.env.REACT_APP_MMM_LABS_BUCKET;
  const auth = useAuthInfo();

  const saveBrandRefreshStepSelection = useSaveBrandRefreshStepSelection();

  // Call the useSignedUrl hook at the top level of the component
  const signedModelInputsPath = useSignedUrl(`https://storage.cloud.google.com/${mmm_labs_bucket}/${user_email}/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/model_inputs.json`);

  useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedRefresh && !selectedBrand) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext(); // Call the async function
   }, [brandName, refreshName, loadContext]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const generateModelInputsFile = async () => {
        try {
            // Ensure all necessary data is available
            if (!selectedRefresh?.selectedFile?.file?.file_url || !selectedBrand?.brand_name || !selectedRefresh?.refresh_name || !configurationData?.UserSelection) {
                console.error("Missing necessary data to generate model inputs file.");
                return;
            }

            // Construct the query parameters
            const queryParams = new URLSearchParams({
                file_name: selectedRefresh.selectedFile.file.file_url,
                brand: selectedBrand.brand_name,
                refresh_name: selectedRefresh.refresh_name,
                user_selection: JSON.stringify(configurationData.UserSelection),
            }).toString();

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const fullUrl = `${baseUrl}/model/generate_model_inputs_file?${queryParams}`;

            const response = await axios.post( fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
            if (response.status === 200) {
                console.log("Model inputs file was successfully generated");
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            setAlert({ show: true, message: `Error generating model inputs file: ${error.message}`, color: 'error' });
            setOpenDialog(true);
        }
  };

   const generateUserSelectionFile = async () => {
        try {
            // Ensure all necessary data is available
            if (!selectedRefresh?.selectedFile?.file?.file_url || !selectedBrand?.brand_name || !selectedRefresh?.refresh_name || !configurationData?.UserSelection) {
                console.error("Missing necessary data to generate user selection file.");
                return;
            }

            // Construct the query parameters
            const queryParams = new URLSearchParams({
                brand: selectedBrand.brand_name,
                refresh_name: selectedRefresh.refresh_name,
                user_selection: JSON.stringify(configurationData.UserSelection),
            }).toString();

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const fullUrl = `${baseUrl}/model/generate_user_selection_file?${queryParams}`;

            const response = await axios.post( fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
            if (response.status === 200) {
                console.log("User selection file was successfully generated");
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            setAlert({ show: true, message: `Error generating user selection file: ${error.message}`, color: 'error' });
            setOpenDialog(true);
        }
  };

  useEffect(() => {
        const checkModelInputsFilePresence = async () => {
			console.log("Checking model inputs file");
            if (!signedModelInputsPath) {
                setIsModelInputsFilePresent(false);
                return;
            }
            try {
                const response = await axios.get(signedModelInputsPath);

                if (response.status === 200) {
                    setIsModelInputsFilePresent(true);
                } else {
                    console.warn("Model inputs file not found, status code:", response.status);
                    setIsModelInputsFilePresent(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    console.warn("File not found (NoSuchKey): Attempting to generate model inputs file.");
                    //setAlert({ show: true, message: "DataConfiguration>>useEffect - Model inputs file not found.", color: 'warning' });
                    //setOpenDialog(true);
                    generateModelInputsFile();
                    setIsModelInputsFilePresent(true);
                } else if (error.code === 'ECONNABORTED' || error.message.includes("Network Error")) {
                    console.error("Network error while checking model inputs file:", error.message);
                    setIsModelInputsFilePresent(false);
                } else {
                    console.error("Unexpected error fetching model inputs file:", error.message);
                    setIsModelInputsFilePresent(false);
                }
            }
        };
        if (signedModelInputsPath) {
            checkModelInputsFilePresence();
        }
  }, [signedModelInputsPath]);

  const handleDataReadiness = (e) => {
    generateModelInputsFile();
    updateStep(2) // Move to 'Configure variables' step
    saveBrandRefreshStepSelection(selectedBrand.brand_name, selectedRefresh.refresh_name, 2);
    saveContext({
        configurationData,
        selectedRefresh,
        currentStep: 2
    });
    if (selectedRefresh.selectedFile) {
        navigate(`/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/dataReadiness`);
    } else {
        setAlert({ show: true, message: 'No file selected for data readiness', color: 'error' });
        setOpenDialog(true);
        return;
    }
  };

  const handleDataValidation = (e) => {
    // Check if model inputs file is present and generate it if absent
    if (!isModelInputsFilePresent) {
        generateModelInputsFile();
    }
    updateStep(2); // Move to 'Configure variables' step
    saveBrandRefreshStepSelection(selectedBrand.brand_name, selectedRefresh.refresh_name, 2);
    saveContext({
        configurationData,
        selectedRefresh,
        currentStep: 2
    });
    if (selectedRefresh.selectedFile) {
        navigate(`/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/sales`);
    } else {
        setAlert({ show: true, message: 'No file selected for data validation', color: 'error' });
        setOpenDialog(true);
        return;
    }
  };

  const handleModelingSetup = (e) => {
    // Check if model inputs file is present and generate it if absent
    if (!isModelInputsFilePresent) {
		console.log('generateModelInputFile');
        generateModelInputsFile();
    }
    updateStep(3); // Move to 'Run Models' step
    saveBrandRefreshStepSelection(selectedBrand.brand_name, selectedRefresh.refresh_name, 3);
    setConfigurationDataUserSelection(configurationData.UserSelection);
    console.log("configurationData: ", configurationData);
    saveContext({
        configurationData,
        selectedRefresh,
        currentStep: 3
    });
    if (selectedRefresh.selectedFile) {
         navigate(`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelingSetup`);
    } else {
      setAlert({ show: true, message: 'No file selected for modeling', color: 'error' });
      setOpenDialog(true);
      return;
    }
  };

  const salesData = configurationData?.sales_data || {};
  const adsData = configurationData?.ads_data || {};
  const othersData = configurationData?.others_data || {};

  if (salesData.length === 0 && adsData.length === 0 && othersData.length === 0) {
      return;
 }

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Alert color={alert.color}>{alert.message}</Alert>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <MDBox p={1}>
        <MDTypography variant="h3">Data Configuration</MDTypography>
        <MDTypography component="div" sx={{ m: 0, width: '100%', textAlign: 'center' }} variant="normal">
          Brand: <strong>{selectedBrand?.brand_name ? selectedBrand.brand_name : 'NA'}</strong> - Refresh: <strong>{selectedRefresh?.refresh_name ? selectedRefresh.refresh_name : 'NA'}</strong>
            <MDBox sx={{ textAlign: 'center' }}>File: <strong>{selectedRefresh?.selectedFile.display_name ? selectedRefresh?.selectedFile.display_name : selectedRefresh?.selectedFile.file.file_name}</strong> </MDBox>
        </MDTypography>
        <br />
        <Card sx={{ p: 2 }}>
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Date</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your date variable</MDTypography>
          <DateVariable dateColumns={salesData.date_columns} />
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Sales Metrics</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your sales channels and metrics</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <SalesVariable variables={salesData.dimension_columns} />
            <SalesChannelNames channels_variable={salesData.channels} />
            <SalesMetric metric_variable={salesData.metric_columns} />
            <SalesPrice price_variable={salesData.price_columns} />
            <SalesOthers others_variable={salesData.others_columns} />
          </MDBox>
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Advertising</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your advertising variables and metrics</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <AdsVariable ads_variable={adsData.dimension_columns} />
            <AdsChannels ads_channels={adsData.ads_channels} />
            <AdsSpendMetric ads_spend_metric={adsData.metric_columns} />
            <AdsModeledMetric ads_modeled_metric={adsData.metric_columns} />
          </MDBox>
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Organic Advertising</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select your unpaid variables</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <OrganicVariables organic_variables={othersData.organic_variables} />
          </MDBox>
          <Divider />
          <MDTypography variant="h5" style={{ margin: '0 5px 0 10px' }}>Uncontrollable Metrics</MDTypography>
          <MDTypography variant="body2" style={{ marginLeft: '10px' }}>Select variables that are outside of your control</MDTypography>
          <MDBox sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 2 }}>
            <UncontrollableVariables uncontrollable_variables={othersData.uncontrollable_variables} />
          </MDBox>
        <MDBox sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, mb: 2 }}>
          <MDButton variant="contained" color="dark" onClick={handleDataReadiness} >Data Readiness</MDButton>
          <MDButton variant="contained" color="dark" onClick={handleDataValidation}>Data Validation</MDButton>
          <MDButton variant="contained" color="dark" onClick={handleModelingSetup}>Start Modeling</MDButton>
        </MDBox>
        </Card>
      </MDBox>
    </>
  );
};

export default DataConfiguration;

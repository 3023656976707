import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import {  Card, MenuItem, Select, InputLabel, FormControl, CircularProgress } from '@mui/material';
import Plot from 'react-plotly.js';
import { blue } from '@mui/material/colors';
import { MaterialReactTable } from 'material-react-table';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useBrand } from 'layouts/utils/BrandContext';

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e0e0',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#F1F1F1',
        },
        root: {
          backgroundColor: '#ffffff',
          '&:hover': {
            backgroundColor: '#cccccc',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#333',
        },
      },
    },
  },
});

const formatCurrency = (value) => {
  const formattedValue = Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return value < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
};

const ModelStatisticsTab = React.memo(({ refresh }) => {
    const [errors, setErrors] = useState({});
    const [modelsOutputs, setModelsOutputs] = useState({});
    const { selectedRefresh, setSelectedRefresh } = useBrand();
    const [selectedModelType, setSelectedModelType] = useState('');
    const championModel = selectedRefresh?.championModel || {};
    const [loading, setLoading] = useState(true);
    const auth = useAuthInfo();

    const handleModelTypeChange = (event) => {
      const newModelType = event.target.value;
      setSelectedModelType(newModelType);
      const outputs = {};
      refresh['Models_results'].forEach(file => {
        outputs[file.model_type] = file.file_url;
      });
      fetchModelsOutputs(outputs, newModelType);
    };

    // Fetch model outputs
    const fetchModelsOutputs = useCallback(async (modelsOutputs, newModelType='') => {
        setLoading(true);
        let model_type = '';
        if (newModelType) {
            setSelectedModelType(newModelType);
            model_type = newModelType;
        } else {
            model_type = championModel?.model_type || '';
        }
        const models_outputs = JSON.stringify(modelsOutputs);
        const queryParams = new URLSearchParams({
          models_outputs: models_outputs,
          champion_model_type: model_type
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/model/display_model_statistics?${queryParams}`;
        try {
          const response = await axios.post(
            fullUrl, {},
            {
              headers: {
                "Authorization": `Bearer ${auth.accessToken}`,
                "Accept": "application/json"
              }
            }
          );
          //console.log("response:", response);
          if (response.status === 200 && response.data.success) {
            setModelsOutputs(response.data.data);
            if (response.data.data['champion_model_data'] && Object.keys(response.data.data['champion_model_data']).length > 0) {
              setSelectedRefresh({
                ...selectedRefresh,
                championModel: response.data.data['champion_model_data'],  // Update championModel in the selectedRefresh
              });
            }
          } else {
            const errorDetail = response.data.detail || 'Unknown error';
            setErrors(prevState => ({ ...prevState, general: errorDetail }));
          }
        } catch (error) {
          const errorDetail = error.response ? error.response.data.detail : error.message;
          setErrors(prevState => ({ ...prevState, general: errorDetail }));
        } finally {
          setLoading(false);
        }
    }, [refresh]);

    useEffect(() => {
        if (refresh && refresh['Models_results']) {
          const modelsOutputs = {};
          refresh['Models_results'].forEach(file => {
            modelsOutputs[file.model_type] = file.file_url;
          });

          const model_type = championModel?.model_type || '';
          setSelectedModelType(model_type);
          fetchModelsOutputs(modelsOutputs);
        }
    }, [refresh, fetchModelsOutputs]);

    // Dynamically determine prediction column structure based on data
    const getPredictionColumns = useCallback(() => {
        if (!modelsOutputs?.models) return [];

        const firstModel = modelsOutputs.models[Object.keys(modelsOutputs.models)[0]];
        const firstPrediction = firstModel?.predictions?.[0] || {};

        return Object.keys(firstPrediction).map((key) => ({
            accessorKey: key,
            header: key.replace(/_/g, ' '), // Format header text
            Cell: ({ cell }) => {
                const value = cell.getValue();
                return typeof value === 'number' ? formatCurrency(value) : value;
            },
        }));
    }, [modelsOutputs]);

    const memoizedPlotData = useMemo(() => {
      if (!modelsOutputs?.models) {
        return [];
      }
      return Object.keys(modelsOutputs.models).map((subModelName) => {
        const firstPrediction = modelsOutputs.models[subModelName].predictions?.[0] || {};

        // Find the key for the actual values dynamically
        const actualKey = Object.keys(firstPrediction).find(key => key !== 'Date' && key !== 'Predicted');

        return {
          subModelName,
          plotData: [
            {
              x: modelsOutputs.models[subModelName].predictions.map(row => row.Date),
              y: modelsOutputs.models[subModelName].predictions.map(row => row[actualKey]),
              mode: 'lines',
              name: 'Actual',
              line: { color: 'blue' },
            },
            {
              x: modelsOutputs.models[subModelName].predictions.map(row => row.Date),
              y: modelsOutputs.models[subModelName].predictions.map(row => row.Predicted),
              mode: 'lines',
              name: 'Predicted',
              line: { color: 'red' },
            },
          ],
          layout: {
            title: `Actual vs Predicted for ${subModelName}`,
            xaxis: { title: 'Date' },
            yaxis: { title: 'Revenue' },
            height: 600,
            width: 1000,
            showlegend: true,
          },
          config: {
            responsive: true,
          },
        };
      });
    }, [modelsOutputs]);

  const { models } = modelsOutputs;

  if (loading && refresh?.selectedFile) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: blue[500] }} />
      </MDBox>
    );
  }

 const textFieldStyles = {
    margin: 'dense',
    variant: 'outlined',
    fullWidth: true,
    sx: {
        '& .MuiInputBase-root': {
            height: '44px',
        },
    },
 };

 if (!refresh) return null;

  return (
    <MDBox p={3} pb={3} pr={5}>
     <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h3">Model Statistics</MDTypography>
         <MDBox sx={{ width: '200px', ml: 2, mb:2 }}>
            <FormControl variant="outlined" margin="dense"  {...textFieldStyles} >
              <InputLabel>Select Model Type</InputLabel>
              <Select
                name="Model Type"
                value={selectedModelType}
                onChange={handleModelTypeChange}
                label="Select Model Type"
                IconComponent={ArrowDropDownIcon}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiSelect-icon': {
                    display: 'block',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  },
                }}
              >
             {[...new Set(refresh?.['Models_results']?.map((file) => file.model_type))].map((modelType) => (
                <MenuItem key={modelType} value={modelType}>
                  {modelType.toUpperCase()}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>
      <Card>
        <MDBox p={3} pb={3} pr={5}>
          {memoizedPlotData.map(({ subModelName, plotData }) => (
            <React.Fragment key={subModelName}>
              <MDTypography variant="h4">{subModelName} - Actual vs Predicted</MDTypography>
              {models[subModelName].predictions && models[subModelName].predictions.length > 0 && (
                <Card sx={{ mb: 3, p: 3 }}>
                  <Plot
                    data={plotData}
                    layout={plotData.layout}
                    config={plotData.config}
                  />
                </Card>
              )}
              <MDTypography variant="h4">Predictions Table</MDTypography>
              <Card sx={{ mb: 3, p: 3 }}>
                <ThemeProvider theme={theme}>
                  <MaterialReactTable
                    columns={getPredictionColumns()}
                    data={modelsOutputs.models[subModelName].predictions || []}
                  />
                </ThemeProvider>
              </Card>
            </React.Fragment>
          ))}
        </MDBox>
      </Card>
    </MDBox>
  );
});

export default ModelStatisticsTab;

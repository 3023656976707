import React, { createContext, useState, useContext, useCallback } from 'react';
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios';

const BrandContext = createContext();

const defaultUserSelection = {
  adsChannels: '',
  adsModeledMetric: '',
  adsSpendMetric: '',
  adsVariable: '',
  date: '',
  organicVariables: '',
  salesChannels: '',
  salesMetric: '',
  salesOthers: '',
  salesPrice: '',
  salesVariable: '',
  uncontrollableVariables: '',
};

export const BrandProvider = ({ children }) => {
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedRefresh, setSelectedRefresh] = useState(null);
    const [user_email, setUserEmail] = useState(null);
    const [user_company, setUserCompany] = useState(null);
    const [user_first_last_name, setUserFirstLastName] = useState(null);
    const [user_role, setUserRole] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);

    const [models, setModels] = useState({
        pymc: { logs: [], downloadLink: '', isRunning: false},
        robyn: { logs: [], downloadLink: '', isRunning: false },
        lightweight: { logs: [], downloadLink: '', isRunning: false },
        ols: { logs: [], downloadLink: '', isRunning: false },
    });

    const auth = useAuthInfo();

    const loadLastSelectedBrandAndRefresh = useCallback(async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.get(`${baseUrl}/brands/load_last_selected`, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            });
            if (response.data) {
				const brand = response.data.brandName || '';
                const refresh = response.data.refreshName || '';
                const step = response.data.currentStep || 0;
                return { brand, refresh, step };
            }
        } catch (error) {
            console.error("Error loading last selected brand and refresh:", error);
        }
	    return { brand: '', refresh: '', step: 0 };
    }, [auth.accessToken]);

	const updateIsRunning = (modelType, isRunning) => {
		console.log('Update isRunning: ', modelType, isRunning);
	    setModels(prevModels => {
	        const updatedModels = {
	            ...prevModels,
	            [modelType]: {
	                ...prevModels[modelType],
	                isRunning: isRunning,
	            },
	        };
	        setSelectedRefresh(prevRefresh => {
	            const updatedRefreshModels = {
	                ...prevRefresh.models,
	                [modelType]: {
	                    ...prevRefresh.models[modelType],
	                    isRunning: isRunning,
	                },
	            };
	            const updatedRefresh = {
	                ...prevRefresh,
	                models: updatedRefreshModels,
	            };

	            saveContext({ selectedRefresh: updatedRefresh });
	            return updatedRefresh;
	        });
	        return updatedModels;
	    });
	};

	const updateModelDownloadLink = (modelType, downloadLink) => {
	    setModels(prevModels => {
	        const updatedModels = {
	            ...prevModels,
	            [modelType]: {
	                ...prevModels[modelType],
	                downloadLink,
	                isRunning: false,
	            },
	        };
	        setSelectedRefresh(prevRefresh => {
	            const updatedRefreshModels = {
	                ...prevRefresh.models,
	                [modelType]: {
	                    ...prevRefresh.models[modelType],
	                    downloadLink,
	                    isRunning: false,
	                },
	            };
	            const updatedRefresh = {
	                ...prevRefresh,
	                models: updatedRefreshModels,
	            };

	            saveContext({ selectedRefresh: updatedRefresh, currentStep: 4 });
	            return updatedRefresh;
	        });
	        return updatedModels;
	    });
	};

    const [configurationData, setConfigurationData] = useState({
        UserSelection: { ...defaultUserSelection },
    });

    const updateStep = (step) => {
        setCurrentStep(step);
    };

	const updateModelLogs = (modelType, newLog) => {
	    setModels(prevModels => {
	        const updatedLogs = newLog === 'CLEAR_LOGS' ? [] : [...prevModels[modelType].logs, newLog];
	        const updatedModels = {
	            ...prevModels,
	            [modelType]: {
	                ...prevModels[modelType],
	                logs: updatedLogs,
	            },
	        };
	        setSelectedRefresh(prevRefresh => {
	            const updatedRefreshModels = {
	                ...prevRefresh.models,
	                [modelType]: {
	                    ...prevRefresh.models[modelType],
	                    logs: updatedLogs,
	                },
	            };
	            const updatedRefresh = {
	                ...prevRefresh,
	                models: updatedRefreshModels,
	            };
	            saveContext({ selectedRefresh: updatedRefresh });
	            return updatedRefresh;
	        });

	        return updatedModels;
	    });
	};

    const updateConfigurationDataUserSelection = (key, value) => {
        setConfigurationData((prevData) => {
          const updatedData = {
            ...prevData,
            UserSelection: {
              ...prevData?.UserSelection,
              [key]: value,
            },
          };

          if (selectedRefresh) {
            const updatedRefresh = {
              ...selectedRefresh,
              UserSelection: updatedData?.UserSelection,
            };
            setSelectedRefresh(updatedRefresh);

            if (selectedBrand) {
              const updatedRefreshes = selectedBrand.refreshes.map((refresh) =>
                refresh.created_at === updatedRefresh.created_at ? updatedRefresh : refresh
              );
              const updatedBrand = {
                ...selectedBrand,
                refreshes: updatedRefreshes,
              };
              setSelectedBrand(updatedBrand);
            }
          }
          return updatedData;
        });
    };

    const setConfigurationDataUserSelection = (newUserSelection) => {
        setConfigurationData((prevData) => ({
          ...prevData,
          UserSelection: {
            ...prevData?.UserSelection,
            ...newUserSelection,
          },
        }));
    };

    // Load context with brandName and refreshName (optional) as parameters
    const loadContext = useCallback(async (brandName, refreshName = null) => {
        //console.log("Loading context for brand: ", brandName, " and refresh: ", refreshName);
        if (user_email && user_company && brandName) {
          try {
            const queryParams = new URLSearchParams({
              email: user_email,
              company: user_company,
              brand: brandName
            }).toString();

			if (refreshName) {
			   queryParams.append('refresh', refreshName);
			}

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const fullUrl = `${baseUrl}/brands/load_context?${queryParams}`;

            const response = await axios.post(fullUrl, {}, {
              headers: {
                "Accept": "application/json",
                 "Authorization": `Bearer ${auth.accessToken}`
              },
            });
              console.log("response: ", response.data);
            if (response.data) {
              setSelectedBrand(response.data.selectedBrand || null);
              const refreshedModels = response.data.selectedRefresh?.models || {};

              const updatedModels = Object.keys(models).reduce((acc, modelType) => {
                acc[modelType] = {
                  ...models[modelType],
                  logs: refreshedModels[modelType]?.logs || [],
                  downloadLink: refreshedModels[modelType]?.downloadLink || '',
                  isRunning: refreshedModels[modelType]?.isRunning || false,
                };
                return acc;
              }, {});

              setModels(updatedModels);

              const updatedRefresh = {
                ...response.data.selectedRefresh,
                selectedFile: response.data.selectedRefresh?.selectedFile || null,
                championModel: response.data.selectedRefresh?.championModel || null,
                models: updatedModels,
              };
              setSelectedRefresh(updatedRefresh);
              setConfigurationData(response.data.configurationData || {
                UserSelection: { ...defaultUserSelection },
              });

              if (response.data.currentStep !== undefined) {
                 setCurrentStep(response.data.currentStep);
              }
            }
          } catch (error) {
            console.error("Error loading context with refresh:", error);
          }
        }
    }, [user_email, user_company, auth.accessToken, selectedRefresh, selectedBrand, configurationData, currentStep]);


    // Set championModel for the selected refresh
    const setChampionModel = (newChampionModel) => {
        if (selectedRefresh) {
          const updatedRefresh = {
            ...selectedRefresh,
            championModel: newChampionModel,
          };
          setSelectedRefresh(updatedRefresh);

          if (selectedBrand) {
            const updatedRefreshes = selectedBrand.refreshes.map((r) =>
              r.created_at === updatedRefresh.created_at ? updatedRefresh : r
            );
            const updatedBrand = {
              ...selectedBrand,
              refreshes: updatedRefreshes,
            };
            setSelectedBrand(updatedBrand);
          }
        }
    };

    const saveContext = useCallback(async (contextData) => {
        //console.log("BrandContext>>saveContext - contextData.selectedRefresh", JSON.stringify(contextData.selectedRefresh, null, 2));
        if (user_email && user_company && selectedBrand && contextData) {
          try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const fullUrl = `${baseUrl}/brands/save_context`;
            const context = {
                  selectedBrand: selectedBrand,
				  selectedRefresh: contextData.selectedRefresh
                    ? {
                        ...contextData.selectedRefresh,
                        championModel: contextData.selectedRefresh.championModel || selectedRefresh?.championModel || null,
                        models: {
                            robyn: {
                                logs: models.robyn.logs,
                                downloadLink: models.robyn.downloadLink,
                                isRunning: models.robyn.isRunning,
                                ...(contextData.selectedRefresh.models?.robyn || {}),
                            },
                            pymc: {
                                logs: models.pymc.logs,
                                downloadLink: models.pymc.downloadLink,
                                isRunning: models.pymc.isRunning,
                                ...(contextData.selectedRefresh.models?.pymc || {}),
                            },
                            lightweight: {
                                logs: models.lightweight.logs,
                                downloadLink: models.lightweight.downloadLink,
                                isRunning: models.lightweight.isRunning,
                                ...(contextData.selectedRefresh.models?.lightweight || {}),
                            },
                            ols: {
                                logs: models.ols.logs,
                                downloadLink: models.ols.downloadLink,
                                isRunning: models.ols.isRunning,
                                ...(contextData.selectedRefresh.models?.ols || {}),
                            },
                        },
                    }
                  : null,
                  configurationData: configurationData,
                  currentStep: contextData.currentStep,
            }
            await axios.post(fullUrl, {
              email: user_email,
              company: user_company,
              brand: selectedBrand.brand_name,
              context: context
            }, {
              headers: {
                "Accept": "application/json",
                 "Authorization": `Bearer ${auth.accessToken}`
               },
            });
          } catch (error) {
            console.error("Error saving context:", error);
          }
        }
    }, [user_email, user_company, selectedBrand, selectedRefresh, configurationData, auth.accessToken]);

    return (
        <BrandContext.Provider
            value={{
              selectedBrand, setSelectedBrand,
              selectedRefresh, setSelectedRefresh,
              user_email, setUserEmail,
              user_company, setUserCompany,
              user_role, setUserRole,
              user_first_last_name, setUserFirstLastName,
              configurationData, setConfigurationData,
              configurationDataUserSelection: configurationData?.UserSelection,
              setConfigurationDataUserSelection,
              championModel: selectedRefresh?.championModel, setChampionModel,
              updateConfigurationDataUserSelection,
              saveContext, loadContext,
              currentStep, setCurrentStep, updateStep,
              updateModelDownloadLink, updateModelLogs, updateIsRunning,
              loadLastSelectedBrandAndRefresh,
            }}
        >
          {children}
        </BrandContext.Provider>
    );
};

// Create a custom hook to use the BrandContext
export const useBrand = () => {
    return useContext(BrandContext);
};

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Card, CircularProgress, Icon  } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { blue } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e0e0',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#F1F1F1',
          '&.MuiTableRow-head': {
            backgroundColor: '#F1F1F1',
          },
          '&:hover': {
            backgroundColor: '#cccccc',
          },
        },
        root: {
          '&.MuiTableRow-root': {
            backgroundColor: '#ffffff',
          },
          '&:hover': {
            backgroundColor: '#cccccc',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#333',
        },
      },
    },
  },
});

const KeyRecommendationsTab = React.memo(({ refresh }) => {
  const [modelsOutputs, setModelsOutputs] = useState({});
  const [budgetData, setBudgetData] = useState([]);
  const [improvementData, setImprovementData] = useState([]);
  const [observationsData, setObservationsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const auth = useAuthInfo();

  const budgetColumns = useMemo(() => [
    { accessorKey: 'Channel', header: 'Channel' },
    { accessorKey: 'Current Spend', header: 'Current Spend' },
    { accessorKey: 'Recommended Spend', header: 'Recommended Spend' },
    { accessorKey: 'Impact', header: 'Impact' },
  ], []);

  const improvementColumns = useMemo(() => [
    { accessorKey: 'Improvement Area', header: 'Improvement Area' },
    { accessorKey: 'Current Approach', header: 'Current Approach' },
    { accessorKey: 'Recommendation', header: 'Recommendation' },
    { accessorKey: 'Expected Impact', header: 'Expected Impact' },
  ], []);

  const observationsColumns = useMemo(() => [
    { accessorKey: 'Observation', header: 'Observation' },
    { accessorKey: 'Details', header: 'Details' },
  ], []);

  const fetchRecommendations = useCallback(async (modelsOutputs) => {
    setLoading(true);
    const queryParams = new URLSearchParams({ models_outputs: JSON.stringify(modelsOutputs) }).toString();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/model/display_model_key_recommendations?${queryParams}`;
    try {
      const response = await axios.post(
        fullUrl, {},
        {
          headers: {
            "Authorization": `Bearer ${auth.accessToken}`,
            "Accept": "application/json"
          }
        }
      );

      if (response.status === 200 && response.data.success) {
        const { budget_recommendations, model_improvement_recommendations, sales_advertising_observations } = response.data.data;

        setBudgetData(budget_recommendations);
        setImprovementData(model_improvement_recommendations);
        setObservationsData(sales_advertising_observations);
      } else {
        const errorDetail = response.data.detail || 'Unknown error';
        setErrors(prevState => ({ ...prevState, general: errorDetail }));
      }
    } catch (error) {
      const errorDetail = error.response ? error.response.data.detail : error.message;
      setErrors(prevState => ({ ...prevState, general: errorDetail }));
    } finally {
      setLoading(false);
    }
  }, [auth.accessToken]);

  useEffect(() => {
    if (refresh && refresh['Models_results']) {
      const modelsOutputs = {};
      refresh['Models_results'].forEach(file => {
        modelsOutputs[file.model_type] = file.file_url;
      });
      fetchRecommendations(modelsOutputs);
    }
  }, [refresh, fetchRecommendations]);

  if (!refresh) return null;

  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: blue[500] }} />
      </MDBox>
    );
  }

  return (
    <MDBox p={3} pb={3} pr={5}>
      <MDTypography variant="h3">Key Recommendations</MDTypography>
      <br />

      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5" gutterBottom> <Icon fontSize="small" sx={{ mb: 0, mr: 1 }}>recommend</Icon>
            Recommended Budget Adjustments Across Channels
          </MDTypography>
          <ThemeProvider theme={theme}>
            <MaterialReactTable columns={budgetColumns} data={budgetData} />
          </ThemeProvider>
        </MDBox>
      </Card>

      <br />

      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5" gutterBottom> <Icon fontSize="small" sx={{ mb: 0, mr: 1 }}>key</Icon>
            Key Areas to Improve Model Accuracy and Precision
          </MDTypography>
          <ThemeProvider theme={theme}>
            <MaterialReactTable columns={improvementColumns} data={improvementData} />
          </ThemeProvider>
        </MDBox>
      </Card>

      <br />

      <Card>
        <MDBox p={3}>
          <MDTypography variant="h5" gutterBottom> <Icon fontSize="small" sx={{ mb: 0, mr: 1 }}>real_estate_agent</Icon>
           Sales & Advertising Observations
          </MDTypography>
          <ThemeProvider theme={theme}>
            <MaterialReactTable columns={observationsColumns} data={observationsData} />
          </ThemeProvider>
        </MDBox>
      </Card>
    </MDBox>
  );
});

export default KeyRecommendationsTab;

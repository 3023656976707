import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from 'layouts/utils/BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, salesPrice, theme) {
  return {
    fontWeight:
      salesPrice.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SalesPrice({ price_variable = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(price_variable)) {
    price_variable = [];
  }

  const filterValidPrices = (prices) => Array.isArray(prices)
    ? prices.filter(price => price_variable.includes(price))
    : [];

  const [salesPrice, setSalesPrice] = useState(() => {
    const initialSalesPrice = filterValidPrices(configurationDataUserSelection?.salesPrice || []);
    return initialSalesPrice.length ? initialSalesPrice : [];
  });

  useEffect(() => {
    const initialSalesPrice = filterValidPrices(configurationDataUserSelection?.salesPrice || []);
    setSalesPrice(initialSalesPrice);
    updateConfigurationDataUserSelection('salesPrice', initialSalesPrice);
  }, [price_variable, configurationDataUserSelection?.salesPrice, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let newValue = [];

    if (value.includes('Select All')) {
      newValue = price_variable;
    } else if (value.includes('Deselect All')) {
      newValue = [];
    } else {
      newValue = Array.isArray(value) ? value : value.split(',');
      const validValues = filterValidPrices(newValue);
      newValue = validValues;
    }

    setSalesPrice(newValue);
    updateConfigurationDataUserSelection('salesPrice', newValue);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="price-variable-label">Price/Discount</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="price-variable-label"
        id="multiple-price-variable"
        multiple
        value={salesPrice.length > 0 ? salesPrice : []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-price-variable" label="Price/Discount" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        <MenuItem
          sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
          value="Select All"
        >
          Select All
        </MenuItem>
        <MenuItem
          sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
          value="Deselect All"
        >
          Deselect All
        </MenuItem>
        {price_variable.length > 0 ? (
          price_variable.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, salesPrice, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

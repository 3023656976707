import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Divider from "@mui/material/Divider";
import { Card, Alert, RadioGroup, FormControlLabel, Radio, Button, Link, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { blue } from '@mui/material/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { useBrand } from 'layouts/utils/BrandContext';
import { fetchSignedUrl } from 'layouts/utils/FetchSignedUrl';
import { useDataConfiguration } from 'hooks/useDataConfiguration';
import useSaveBrandRefreshStepSelection from 'layouts/utils/SaveBrandRefreshStepSelection';

const RefreshDetails = forwardRef((props, ref) => {
    const { selectedBrand, selectedRefresh, setSelectedRefresh, configurationData, setConfigurationData, setConfigurationDataUserSelection, configurationDataUserSelection, saveContext, loadContext, user_company, user_email, currentStep, updateStep } = useBrand();
    const [filesWithVersions, setFilesWithVersions] = useState([]);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [loading, setLoading] = useState(false);
    const [signedUrls, setSignedUrls] = useState({});
    const { brandName, refreshName } = useParams();
    const [confirmConfigurationReset, setConfirmConfigurationReset] = useState(false);
    const [pendingFileVersion, setPendingFileVersion] = useState(null);
    const [pendingFile, setPendingFile] = useState(null);
    const navigate = useNavigate();
    const auth = useAuthInfo();

	const saveBrandRefreshStepSelection = useSaveBrandRefreshStepSelection();

    useImperativeHandle(ref, () => ({
       fetchFilesVersions,
    }));

    const { fetchDataConfigurationUserSelection, getUiConfigurationValues } = useDataConfiguration();

    const closeConfirmDialog = () => {
        setConfirmConfigurationReset(false);
        setPendingFileVersion(null);
        setPendingFile(null);
    };

    const confirmVersionChange = async () => {
        if (pendingFile && pendingFileVersion) {
            closeConfirmDialog();
            setLoading(true);
            await handleVersionConfirm(pendingFile, pendingFileVersion);
            setLoading(false);
        }
    };

    const getSignedUrlForFileVersion = async (fileUrl, version) => {
        if (signedUrls[fileUrl]?.[version]) {
            return signedUrls[fileUrl][version];
        }

        const signedUrl = await fetchSignedUrl(fileUrl, auth.accessToken, version);
        setSignedUrls((prevUrls) => ({
            ...prevUrls,
            [fileUrl]: {
                ...prevUrls[fileUrl],
                [version]: signedUrl,
            },
        }));
        return signedUrl;
    };

    const getDisplayNameForVersion = (selectedFile, targetVersion) => {
        const targetVersionStr = targetVersion.toString();
        const versionInfo = selectedFile.versions.find(
            (version) => version.version.toString() === targetVersionStr
        );
        return versionInfo ? versionInfo.display_name : '';
    };

    useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
    }, [brandName, refreshName, loadContext]);

    useEffect(() => {
         if (selectedRefresh?.selectedFile && filesWithVersions.length > 0) {
            // Find the file that matches the selected file name
            const matchingFile = filesWithVersions.find(file => file.file_name === selectedRefresh.selectedFile.file.file_name);

            // If there's a matching file, select the last version
            if (matchingFile && matchingFile.versions.length > 0) {
                const lastVersion = matchingFile.versions[matchingFile.versions.length - 1].version;
                setSelectedVersion(lastVersion);
            }
        }
    }, [selectedRefresh, filesWithVersions]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const fetchFilesVersions = useCallback(async () => {
        setLoading(true);
        const queryParams = new URLSearchParams({
          brand_name: selectedBrand.brand_name,
          refresh_created_at: selectedRefresh.created_at,
          user_company: user_company,
          user_email: user_email,
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/get_files_versions?${queryParams}`;
        try {
            const response = await axios.get(fullUrl, {
                headers: {
                     "Accept": "application/json",
                     "Authorization": `Bearer ${auth.accessToken}`
                }
            });
            if (!response.data.Files.validation_error) {
                setFilesWithVersions(response.data.Files);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
            setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    }, [auth.accessToken, selectedBrand, selectedRefresh]);


    useEffect(() => {
        if (!selectedRefresh || !selectedBrand) {
            return;
        }
        fetchFilesVersions();
    }, [fetchFilesVersions]);

    const handleVersionSelect = (file, version) => {
		console.log("RefreshDetails>>handleVersionSelect:", file, version);
        if (selectedRefresh?.selectedFile) {
            // If there is a selection, show the confirmation dialog
            setPendingFile(file);
            setPendingFileVersion(version);
            setConfirmConfigurationReset(true);
        } else {
            // If there is no selection, directly apply the new selection
            handleVersionConfirm(file, version);
        }
    };

    const handleVersionConfirm = async(file, version) => {
		console.log("RefreshDetails>>handleVersionConfirm:", file, version);
        localStorage.removeItem('storedUserSelection');
        const selectedFile = { file, version, display_name: getDisplayNameForVersion(file, version) };
        const updatedRefresh = {
            ...selectedRefresh,
            selectedFile: selectedFile
        };
        setSelectedRefresh(updatedRefresh);
        setSelectedVersion(version);
        updateStep(2); // Move to 'Configure variables' step

		saveBrandRefreshStepSelection(selectedBrand.brand_name, updatedRefresh.refresh_name, 2);
        console.log("RefreshDetails>>handleVersionConfirm - updatedRefresh: ", updatedRefresh);

        const formData = new FormData();
        formData.append('brand_name', selectedBrand.brand_name);
        formData.append('refresh_created_at', selectedRefresh.created_at);
        formData.append('refresh_name', selectedRefresh.refresh_name);
        formData.append('file', file.file_url);
        formData.append('version', version);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            const fullSalesUrl = `${baseUrl}/data_configuration/update_user_file_selection`;
            const response = await axios.post(fullSalesUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`,
                }
            })
            if (response.data.success) {
                setConfigurationData(null);
                setConfigurationDataUserSelection(null)

                saveContext({
		            selectedBrand,
		            selectedRefresh: updatedRefresh,
		            configurationData: null,
		            currentStep: 2
		        });
			    console.log("RefreshDetails>>handleVersionConfirm - selectedRefresh: ", selectedRefresh);
            }
        } catch (error) {
            let errorMessage = 'Error removing refresh user selection';
            if (error.response && error.response.data && error.response.data.detail) {
                errorMessage = `Error: ${error.response.data.detail}`;
            } else if (error.message) {
                errorMessage = `Error: ${error.message}`;
            }
            console.error('Error removing refresh user selection - ', error);
            setAlert({ show: true, message: errorMessage, color: 'error' });
            setOpenDialog(true);
        }
    };

    const handleConfigureData = async () =>  {
		// TODO Load user selection if present
        const configurationDataUserSelection = await fetchDataConfigurationUserSelection();
        const isUserSelectionEmpty = configurationDataUserSelection &&
            Object.values(configurationDataUserSelection.UserSelection).every(value => Array.isArray(value) && value.length === 0);

       if (!configurationDataUserSelection || isUserSelectionEmpty) {
            setLoading(true);
            const formData = new FormData();
            formData.append('brand', selectedBrand.brand_name);
            formData.append('refresh_name', selectedRefresh.refresh_name);
            formData.append('file_url', selectedRefresh.selectedFile.file.file_url);
            formData.append('version', selectedRefresh.selectedFile.version);
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            try {
                const fullSalesUrl = `${baseUrl}/data_configuration/generate_data_configuration_file`;
                const response = await axios.post(fullSalesUrl, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${auth.accessToken}`,
                    }
                })
                if (response.data.validation_error) {
                    setAlert({ show: true, message: response.data.validation_error, color: 'error' });
                    setOpenDialog(true);
                } else {
                   const data = response.data
                   setConfigurationData(data)
                   const contextData = {
                      selectedBrand,
                      selectedRefresh: selectedRefresh,
                      configurationData: data,
                    };
                    navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-configuration`);
                }
            } catch (error) {
                let errorMessage = 'Error generating the configuration_ui.json file';
                if (error.response && error.response.data && error.response.data.detail) {
                    errorMessage = `Error generating the configuration_ui.json file: ${error.response.data.detail}`;
                } else if (error.message) {
                    errorMessage = `Error generating the configuration_ui.json file: ${error.message}`;
                }
                console.error('Error generating the configuration_ui.json file - ', error);
                setAlert({ show: true, message: errorMessage, color: 'error' });
                setOpenDialog(true);
            } finally {
                setLoading(false);
            }
        } else {
            getUiConfigurationValues(configurationDataUserSelection);
            navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-configuration`);
        }
    };

    const handleFileUpload = async (file, file_url) => {
        if (!file) return;

        const fileExtension = file.name.split('.').pop().toLowerCase();
        const urlFileExtension = file_url.split('.').pop().toLowerCase();

        if (fileExtension !== urlFileExtension) {
            setAlert({ show: true, message: 'File extension does not match the existing file.', color: 'error' });
            setOpenDialog(true);
            return;
        }

        const display_name = file.name;
        const original_filename = file_url.split('/').pop();
        const renamedFile = new File([file], original_filename, { type: file.type });

        const formData = new FormData();
        formData.append('file', renamedFile);
        formData.append('file_url', file_url);
        formData.append('display_name', display_name);

        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/upload_file_version`;

        try {
            const response = await axios.post(fullUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            if (response.data) {
                setAlert({ show: true, message: 'File uploaded successfully!', color: 'success' });
                setOpenDialog(true);
                fetchFilesVersions();
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
            setOpenDialog(true);
        }
    };

    const handleFileDelete = async (file, brand_name, refresh_name) => {
       if (selectedVersion === null) {
            setAlert({ show: true, message: 'No version selected for deletion.', color: 'error' });
            setOpenDialog(true);
            return;
        }
        //console.log('File version to delete:',  file, selectedVersion);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/delete_file_version`;

        const formData = new FormData();
        formData.append('file_url', file.file_url);
        formData.append('version', selectedVersion);
        formData.append('user_company', user_company);
        formData.append('user_email', user_email);
        formData.append('brand_name', brand_name);
        formData.append('refresh_created_at', selectedRefresh.created_at);
        try {
            const response = await axios.post(fullUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                   "Authorization": `Bearer ${auth.accessToken}`
                }
            });
            if (response.data) {
                setAlert({ show: true, message: 'File version deleted successfully!', color: 'success' });
                setOpenDialog(true);
                setSelectedVersion(null);
                // Reload the user context - The breadcrumb will update accordingly
                await loadContext(brandName, refreshName);
            }
        } catch (error) {
            console.error('Error deleting file version:', error);
            setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
            setOpenDialog(true);
        }
    };

    const handleBack = () => {
        if (selectedBrand && selectedBrand?.brand_name !== 'undefined') {
            navigate(`/brand-manager/${selectedBrand.brand_name}`);
        } else {
            navigate(`/brand-manager`);
        }
    };
    return (
        <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
             <Dialog open={confirmConfigurationReset} onClose={closeConfirmDialog}>
                <DialogTitle>Data Configuration Reset</DialogTitle>
                <DialogContent>
                  Selecting a new file or another file version will reset the current data configuration.
                  Are you sure you want to proceed?
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={closeConfirmDialog} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>Cancel</MDButton>
                  <MDButton onClick={confirmVersionChange} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>Ok</MDButton>
                </DialogActions>
            </Dialog>
            {loading ? (
                <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress sx={{ color: blue[500] }} />
                    <MDTypography variant="normal" sx={{ mt: 2 }}>
                        Loading data for <strong>{selectedRefresh?.refresh_name}</strong>...
                    </MDTypography>
                </MDBox>
            ) : (
                <>
                <Card sx={{ mt: 2, p: 1 }}>
                    <MDBox p={2} >
                        { (selectedBrand && selectedRefresh) ? (
                            <>
                             <MDTypography variant="body2" sx={{ mt: 1 }}>
                                     Refresh <strong>{selectedRefresh ? selectedRefresh.refresh_name : "None"}</strong> for brand <strong>{selectedBrand?.brand_name}</strong>
                            </MDTypography>
                            <MDTypography variant="normal" sx={{ mt: 1 }}>
                                 {selectedRefresh?.created_at ? format(new Date(selectedRefresh.created_at), 'MMMM do, yyyy h:mm a') : 'Invalid date'} (UTC)
                            </MDTypography>
                            </>
                            ) : (
                                <MDTypography variant="normal">Please select a <b><a href="/brand-manager" style={{ textDecoration: 'none', color: 'blue' }}>Brand</a></b> first and a Refresh</MDTypography>
                        )}
                    </MDBox>
                    <ul style={{ paddingLeft: '30px' }}>
                      {filesWithVersions.map((file, index) => (
                        <li key={index}>
                          {file.versions && file.versions.length > 0 ? (
                            <>
                              <MDTypography variant="normal" sx={{ fontWeight: 'bold' }}>
                                 {
                                    selectedRefresh.selectedFile &&
                                    selectedRefresh.selectedFile.file.file_url === file.file_url &&
                                    selectedRefresh.selectedFile.version
                                      ? getDisplayNameForVersion(file, selectedRefresh.selectedFile.version)
                                      : 'No selection'
                                  }
                              </MDTypography>
                              <RadioGroup
                                name={`file-version-${index}`}
                                value={
                                  selectedRefresh?.selectedFile?.file?.file_url === file.file_url
                                    ? selectedRefresh?.selectedFile?.version
                                    : ''
                                }
                                onChange={(e) => handleVersionSelect(file, e.target.value)}
                              >
                                {file.versions.map((version, idx) => (
                                  <FormControlLabel
                                    key={idx}
                                    value={version.version}
                                    control={
                                      <Radio
                                        sx={{
                                          '&.Mui-checked': {
                                            color: '#344767',
                                          },
                                          '& .MuiSvgIcon-root': {
                                            color: '#344767',
                                          },
                                        }}
                                      />
                                    }
                                    label={(
                                      <div>
                                        <span>
                                         {version.display_name} - {format(new Date(version.time_created), 'MMM do, yyyy h:mm a')} (UTC) -
                                        </span>
                                           <Link
                                                href="#"
                                                sx={{ marginLeft: 1, color: 'blue' }}
                                                onClick={async (e) => {
                                                    e.preventDefault(); // Prevents default behavior
                                                    const url = signedUrls[file.file_url]?.[version.version] || await fetchSignedUrl(file.file_url, auth.accessToken, version.version);

                                                    if (url) {
                                                        try {
                                                            // Fetch file data as a Blob
                                                            const response = await axios.get(url, { responseType: "blob" });
                                                            const blob = new Blob([response.data]);
                                                            const objectUrl = URL.createObjectURL(blob);
                                                            // Create an anchor element to handle download with a custom filename
                                                            const link = document.createElement("a");
                                                            link.href = objectUrl;
                                                            link.target = "_blank"; // Open in new tab if required
                                                            link.download = getDisplayNameForVersion(file, version.version); // Set custom filename
                                                            // Trigger download
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            // Clean up
                                                            document.body.removeChild(link);
                                                            URL.revokeObjectURL(objectUrl); // Free up memory
                                                        } catch (error) {
                                                            console.error("Error downloading the file:", error);
                                                        }
                                                    }
                                                }}
                                            >
                                          Download file
                                        </Link>
                                      </div>
                                    )}
                                    sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}
                                  />
                                ))}
                              </RadioGroup>
                            </>
                          ) : (
                            <MDTypography variant="body2" color="textSecondary">
                              No versions available for {file.file_name}.
                            </MDTypography>
                          )}

                          <MDBox sx={{ display: 'flex', justifyContent: 'left', gap: 2, mt: 3, mb: 3 }}>
                            <MDButton
                              variant="contained"
                              color="light"
                              component="label"
                              sx={{
                                marginTop: 2,
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                },
                              }}
                            >
                              Upload New Version
                              <input
                                type="file"
                                hidden
                                onChange={(e) => handleFileUpload(e.target.files[0], file.file_url)}
                              />
                            </MDButton>
                            <MDButton
                              variant="contained"
                              color="light"
                              sx={{
                                marginTop: 2,
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                },
                              }}
                              onClick={() => handleFileDelete(file, selectedBrand.brand_name, selectedRefresh.refresh_name)}
                              disabled={!selectedVersion || selectedRefresh?.selectedFile?.file?.file_url !== file.file_url}
                            >
                              Delete Version
                            </MDButton>
                          </MDBox>
                        </li>
                      ))}
                    </ul>
                    <Divider />
                        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
                            <MDBox flexGrow={1} display="flex" justifyContent="center">
                                <MDButton component={Link} color="dark" onClick={handleConfigureData}
                                                    disabled={
															!selectedRefresh?.selectedFile ||   // Check if a file is selected
															parseInt(selectedRefresh?.selectedFile.version, 10) !== parseInt(selectedVersion, 10) // Ensure selected version matches current version
													}
								>
                                  Configure data
                                </MDButton>
                            </MDBox>
                        </MDBox>
                 </Card>
                </>
            )}
        </>
    );
});

export default RefreshDetails;

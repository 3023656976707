import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Select, MenuItem, InputLabel, FormControl, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSignedUrl } from 'hooks/useSignedUrl';
import { useDropzone } from 'react-dropzone';

const EditBrandDialog = ({ open, onClose, brand, onSave }) => {
  const [originalBrandName] = useState(brand.brand_name);
  const [brandNameError, setBrandNameError] = useState('');

  const [editedBrand, setEditedBrand] = useState({
    ...brand,
    sub_name: brand.sub_name === 'null' ? '' : brand.sub_name
  });
  const [file_logo, setFileLogo] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });

  const signedUrl = useSignedUrl(brand.logo_url);

  useEffect(() => {
    setEditedBrand({
          ...brand,
          sub_name: brand.sub_name === 'null' ? '' : brand.sub_name
    });
    setFileLogo(null); // Reset file_logo when the dialog is opened
  }, [brand]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedBrand({ ...editedBrand, [name]: value });
  };

  const handleIndustryChange = (event) => {
    const { value } = event.target;
    setEditedBrand({ ...editedBrand, industry: value });
  };

  const handleSubmit = () => {
    if (!editedBrand.brand_name) {
        setBrandNameError('Brand name is required');
        return;
    }
    setBrandNameError('');
    onSave(originalBrandName, editedBrand, file_logo);
  };

  const onDropLogo = acceptedFiles => {
    const logoFile = acceptedFiles[0];
    if (logoFile) {
      setFileLogo(logoFile);
      const url = URL.createObjectURL(logoFile);
      setEditedBrand(prev => ({ ...prev, logo_url: url }));
    }
  };

  const { getRootProps: getLogoProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: onDropLogo,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif']
    },
    onDropRejected: () => {
      setAlert({ show: true, message: 'Only image files are allowed.', color: 'warning' });
    }
  });

  const logo_preview = file_logo ? URL.createObjectURL(file_logo) : signedUrl;

  const sanitizeName = (name) => {
      return name.replace(/[^a-zA-Z0-9\s-]/g, '');
  };

  const textFieldStyles = {
        margin: 'dense',
        variant: 'outlined',
        fullWidth: true,
        sx: {
            '& .MuiInputBase-root': {
                height: '44px',
            },
        },
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Brand</DialogTitle>
         <DialogContent sx={{ mt: -2 }}>
            {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
        </DialogContent>
      <DialogContent>
        <TextField
          margin="dense"
          label="Brand Name"
          fullWidth
          variant="outlined"
          name="brand_name"
          value={sanitizeName(editedBrand.brand_name)}
          onChange={handleChange}
          error={!!brandNameError}
          helperText={brandNameError}
          required
        />
        <TextField
          margin="dense"
          label="Subname"
          fullWidth
          variant="outlined"
          name="sub_name"
          value={editedBrand.sub_name}
          onChange={handleChange}
        />
        <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                required
                {...textFieldStyles}
        >
          <InputLabel id="industry-label">Industry</InputLabel>
          <Select
            labelId="industry-label"
            id="industry"
            value={editedBrand.industry}
            label="Industry"
            onChange={handleIndustryChange}
            IconComponent={ArrowDropDownIcon}
            sx={{
                '& .MuiSelect-icon': {
                    display: 'block',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                },
            }}
          >
            <MenuItem value=""><em>Please select...</em></MenuItem>
            <MenuItem value="Consulting">Consulting</MenuItem>
            <MenuItem value="Education">Education</MenuItem>
            <MenuItem value="Entertainment">Entertainment</MenuItem>
            <MenuItem value="Financial Services">Financial Services</MenuItem>
            <MenuItem value="Government">Government</MenuItem>
            <MenuItem value="Healthcare">Healthcare</MenuItem>
            <MenuItem value="Insurance">Insurance</MenuItem>
            <MenuItem value="Manufacturing">Manufacturing</MenuItem>
            <MenuItem value="Media and Communications">Media and Communications</MenuItem>
            <MenuItem value="Retail">Retail</MenuItem>
            <MenuItem value="Technology">Technology</MenuItem>
            <MenuItem value="Transportation">Transportation</MenuItem>
            <MenuItem value="Utilities">Utilities</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <div {...getLogoProps()} style={{ border: '2px dashed #ccc', borderRadius: '4px', padding: '10px', textAlign: 'center', cursor: 'pointer', marginTop: '10px' }}>
          <input {...getLogoInputProps()} />
          {logo_preview ? (
            <img src={logo_preview} alt="Logo Preview" style={{ height: '80px' }} />
          ) : (
            <p>Drop your logo here or click to upload</p>
          )}
          {file_logo && (
            <IconButton
              size="small"
              onClick={() => {
                setFileLogo(null);
                setEditedBrand(prev => ({ ...prev, logo_url: signedUrl }));
              }}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBrandDialog;

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { BrandProvider } from 'layouts/utils/BrandContext';
import { AuthProvider } from "@propelauth/react";
import { MaterialUIControllerProvider } from "context";
import { SignedUrlProvider } from './hooks/useSignedUrl';
import getAccountUrl from "layouts/utils/Config";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);


const container = document.getElementById("app");
const root = createRoot(container);
const accountUrl = getAccountUrl();

root.render(
  <React.StrictMode>
    <AuthProvider authUrl={accountUrl}>
      <BrandProvider>
        <BrowserRouter>
          <MaterialUIControllerProvider>
              <SignedUrlProvider>
                 <App />
              </SignedUrlProvider>
          </MaterialUIControllerProvider>
        </BrowserRouter>
      </BrandProvider>
    </AuthProvider>
  </React.StrictMode>
);

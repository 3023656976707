// src/layouts/scenario-planner/components/NameAndSettings.jsx

import React from 'react';
import { Grid, Typography, TextField, MenuItem, Button, Divider, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { differenceInDays, subDays, subWeeks } from 'date-fns'; // Import subWeeks
import PropTypes from 'prop-types';
import getTextDecoration from '@mui/material/Link/getTextDecoration';

function NameAndSettings({
    scenarioName,
    scenarioDescription,
    scenarioObjective,
    optimizationGoal,
    refreshName,
    scenarioModelType,
    budgetTimingStart,
    budgetTimingEnd,
    setBudgetTimingStart,
    setBudgetTimingEnd,
    handleEntireDataset,
    minDate,
    maxDate,
    dates,
}) {
    const textFieldStyles = {
        margin: 'dense',
        variant: 'outlined',
        fullWidth: true,
        sx: {
            '& .MuiInputBase-root': {
                height: '44px'
            },
        },
    };

    // Function to detect frequency
    const detectFrequency = () => {
        if (!dates || dates.length < 2) return 'Unknown';

        const sortedDates = [...dates].sort((a, b) => a - b);
        const diffs = sortedDates.slice(1).map((date, idx) => differenceInDays(date, sortedDates[idx]));

        const sortedDiffs = diffs.sort((a, b) => a - b);
        const mid = Math.floor(sortedDiffs.length / 2);
        const median_diff = sortedDiffs.length % 2 !== 0 ? sortedDiffs[mid] : (sortedDiffs[mid - 1] + sortedDiffs[mid]) / 2;

        if (median_diff <= 1.5) return 'Daily';
        if (median_diff >= 6 && median_diff <= 8) return 'Weekly';
        return 'Unknown';
    };

    const frequency = detectFrequency();

    // Handler for quick-select buttons
    const handleQuickSelect = (option) => {
        if (option === 'Latest Year') {
            if (!dates || dates.length === 0) {
                console.warn('Dates array is empty. Cannot perform Latest Year selection.');
                return;
            }

            // Ensure dates are sorted ascending
            const sortedDates = [...dates].sort((a, b) => a - b);

            // Calculate differences between consecutive dates
            const diffs = [];
            for (let i = 1; i < sortedDates.length; i++) {
                const diffDays = differenceInDays(sortedDates[i], sortedDates[i - 1]);
                diffs.push(diffDays);
            }

            if (diffs.length === 0) {
                console.warn('Not enough dates to determine frequency.');
                return;
            }

            diffs.sort((a, b) => a - b);
            const mid = Math.floor(diffs.length / 2);
            let median_diff = diffs.length % 2 !== 0 ? diffs[mid] : (diffs[mid - 1] + diffs[mid]) / 2;

            let detectedFrequency = 'unknown';
            let newStartDate = null;
            const latest_date = sortedDates[sortedDates.length - 1];

            if (median_diff <= 1.5) {
                detectedFrequency = 'Daily';
                // Last 364 days from the latest date (inclusive makes it 365 days)
                newStartDate = subDays(latest_date, 364);
                console.log(`Detected frequency: ${detectedFrequency}`);
                console.log(`Setting date range from ${newStartDate.toISOString().split('T')[0]} to ${latest_date.toISOString().split('T')[0]} (Daily)`);
            } else if (median_diff >= 6 && median_diff <= 8) {
                detectedFrequency = 'Weekly';
                // Last 52 weeks from the latest date
                newStartDate = subWeeks(latest_date, 52);
                console.log(`Detected frequency: ${detectedFrequency}`);
                console.log(`Setting date range from ${newStartDate.toISOString().split('T')[0]} to ${latest_date.toISOString().split('T')[0]} (Weekly)`);
            } else {
                detectedFrequency = 'Unknown';
                console.log(`Detected frequency: ${detectedFrequency}`);
                console.log("Unknown frequency. Using the entire dataset without filtering.");
            }

            // Set the new date range based on frequency
            if (detectedFrequency === 'Daily' || detectedFrequency === 'Weekly') {
                setBudgetTimingStart(newStartDate);
                setBudgetTimingEnd(latest_date);
            } else {
                // If frequency is unknown, set to the entire dataset
                setBudgetTimingStart(minDate);
                setBudgetTimingEnd(maxDate);
            }

        } else {
            // Handle other quick-select options (e.g., Q1, Q2, etc.)
            const today = new Date();
            let newStart, newEnd;

            switch(option) {
                case 'Q1':
                    newStart = new Date(today.getFullYear(), 0, 1); // Jan 1
                    newEnd = new Date(today.getFullYear(), 2, 31); // Mar 31
                    break;
                case 'Q2':
                    newStart = new Date(today.getFullYear(), 3, 1); // Apr 1
                    newEnd = new Date(today.getFullYear(), 5, 30); // Jun 30
                    break;
                case 'Q3':
                    newStart = new Date(today.getFullYear(), 6, 1); // Jul 1
                    newEnd = new Date(today.getFullYear(), 8, 30); // Sep 30
                    break;
                case 'Q4':
                    newStart = new Date(today.getFullYear(), 9, 1); // Oct 1
                    newEnd = new Date(today.getFullYear(), 11, 31); // Dec 31
                    break;
                default:
                    return;
            }

            console.log(`Quick Select: ${option} - Start: ${newStart.toISOString().split('T')[0]}, End: ${newEnd.toISOString().split('T')[0]}`);
            setBudgetTimingStart(newStart);
            setBudgetTimingEnd(newEnd);
        }
    };

    // Handler for "Entire Dataset" button
    const handleEntireDatasetClick = () => {
        if (minDate && maxDate) {
            console.log('Entire Dataset button clicked');
            setBudgetTimingStart(minDate);
            setBudgetTimingEnd(maxDate);
        } else {
            console.warn('MinDate or MaxDate is not set. Cannot select Entire Dataset.');
        }
    };

    return (
        <Grid container spacing={3}>

            {/* Scenario Name */}
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Scenario Name</Typography>
                <TextField
                    {...textFieldStyles}
                    value={scenarioName}
                    disabled
                    aria-label="Scenario Name"
                />
            </Grid>

            {/* Description */}
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Description</Typography>
                <TextField
                    {...textFieldStyles}
                    value={scenarioDescription}
                    disabled
                    aria-label="Scenario Description"
                />
            </Grid>

            {/* Scenario Objective */}
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Scenario Objective</Typography>
                <TextField
                    {...textFieldStyles}
                    select
                    value={scenarioObjective}
                    disabled
                    aria-label="Scenario Objective"
                >
                    <MenuItem value="Optimize a Fixed Budget">Optimize a Fixed Budget</MenuItem>
                    <MenuItem value="Recommend a New Budget">Recommend a New Budget</MenuItem>
                    <MenuItem value="Meet Revenue Goal">Meet Revenue Goal</MenuItem>
                </TextField>
            </Grid>

            {/* Optimization Goal */}
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Optimization Goal</Typography>
                <TextField
                    {...textFieldStyles}
                    select
                    value={optimizationGoal}
                    disabled
                    aria-label="Optimization Goal"
                >
                    <MenuItem value="Revenue">Revenue</MenuItem>
                    <MenuItem value="ROI">ROI</MenuItem>
                    <MenuItem value="Profits">Profits</MenuItem>
                </TextField>
            </Grid>

            {/* Refresh */}
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Refresh</Typography>
                <TextField
                    {...textFieldStyles}
                    value={refreshName || "No Refresh Selected"} // Updated display
                    disabled
                    aria-label="Refresh Name"
                />
            </Grid>

            {/* Model Type */}
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Model Type</Typography>
                <TextField
                    {...textFieldStyles}
                    select
                    value={scenarioModelType}
                    disabled
                    aria-label="Model Type"
                >
                   <MenuItem value={scenarioModelType}>{scenarioModelType || "No Model Type Selected"}</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Time Selection</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={budgetTimingStart}
                        onChange={(newValue) => {
                            if (newValue) {
                                console.log(`Start Date changed to: ${newValue.toISOString().split('T')[0]}`);
                                setBudgetTimingStart(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} {...textFieldStyles} />}
                        disableFuture
                        aria-label="Start Date"
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="End Date"
                        value={budgetTimingEnd}
                        onChange={(newValue) => {
                            if (newValue) {
                                console.log(`End Date changed to: ${newValue.toISOString().split('T')[0]}`);
                                setBudgetTimingEnd(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} {...textFieldStyles} />}
                        disableFuture
                        aria-label="End Date"
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography
                    variant="body2"
                    sx={{
                        color: '#000',
                    }}
                >
                    Dataset Frequency: <b>{frequency}</b>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6">Quick Select Date Ranges</Typography>
                <Box sx={{ display: 'flex', gap: 2, mt: 1, flexWrap: 'wrap' }}>
                    {/* Entire Dataset Button */}
                    <Button 
                        variant="outlined" 
                        onClick={handleEntireDatasetClick}
                        disabled={!minDate || !maxDate}
                        sx={{
                            textTransform: 'none',
                            borderColor: '#f84a4e', // Updated border color
                            color: '#f84a4e', // Updated text color
                            '&:hover': {
                                backgroundColor: '#f84a4e', // Hover background color
                                color: '#000', // Hover text color
                                borderColor: '#f84a4e', // Maintain border color on hover
                            }
                        }}
                        aria-label="Entire Dataset"
                    >
                        Entire Dataset
                    </Button>

                    {/* Latest Year Button */}
                    <Button 
                        variant="outlined" 
                        onClick={() => handleQuickSelect('Latest Year')}
                        sx={{
                            textTransform: 'none',
                            borderColor: '#f84a4e', // Updated border color
                            color: '#f84a4e', // Updated text color
                            '&:hover': {
                                backgroundColor: '#f84a4e', // Hover background color
                                color: '#000', // Hover text color
                                borderColor: '#f84a4e', // Maintain border color on hover
                            }
                        }}
                        aria-label="Latest Year"
                    >
                        Latest Year
                    </Button>

                    {/* Other Quick Select Buttons */}
                    <Button 
                        variant="outlined" 
                        onClick={() => handleQuickSelect('Q1')}
                        sx={{
                            textTransform: 'none',
                            borderColor: '#f84a4e', // Updated border color
                            color: '#f84a4e', // Updated text color
                            '&:hover': {
                                backgroundColor: '#f84a4e', // Hover background color
                                color: '#000', // Hover text color
                                borderColor: '#f84a4e', // Maintain border color on hover
                            }
                        }}
                        aria-label="Q1"
                    >
                        Q1
                    </Button>
                    <Button 
                        variant="outlined" 
                        onClick={() => handleQuickSelect('Q2')}
                        sx={{
                            textTransform: 'none',
                            borderColor: '#f84a4e', // Updated border color
                            color: '#f84a4e', // Updated text color
                            '&:hover': {
                                backgroundColor: '#f84a4e', // Hover background color
                                color: '#000', // Hover text color
                                borderColor: '#f84a4e', // Maintain border color on hover
                            }
                        }}
                        aria-label="Q2"
                    >
                        Q2
                    </Button>
                    <Button 
                        variant="outlined" 
                        onClick={() => handleQuickSelect('Q3')}
                        sx={{
                            textTransform: 'none',
                            borderColor: '#f84a4e', // Updated border color
                            color: '#f84a4e', // Updated text color
                            '&:hover': {
                                backgroundColor: '#f84a4e', // Hover background color
                                color: '#000', // Hover text color
                                borderColor: '#f84a4e', // Maintain border color on hover
                            }
                        }}
                        aria-label="Q3"
                    >
                        Q3
                    </Button>
                    <Button 
                        variant="outlined" 
                        onClick={() => handleQuickSelect('Q4')}
                        sx={{
                            textTransform: 'none',
                            borderColor: '#f84a4e', // Updated border color
                            color: '#f84a4e', // Updated text color
                            '&:hover': {
                                backgroundColor: '#f84a4e', // Hover background color
                                color: '#000', // Hover text color
                                borderColor: '#f84a4e', // Maintain border color on hover
                            }
                        }}
                        aria-label="Q4"
                    >
                        Q4
                    </Button>
                </Box>
            </Grid>

            

        </Grid>
    );
}

NameAndSettings.propTypes = {
    scenarioName: PropTypes.string.isRequired,
    scenarioDescription: PropTypes.string.isRequired,
    scenarioObjective: PropTypes.string.isRequired,
    optimizationGoal: PropTypes.string.isRequired,
    refreshName: PropTypes.string, // Made optional
    scenarioModelType: PropTypes.string.isRequired,
    budgetTimingStart: PropTypes.instanceOf(Date),
    budgetTimingEnd: PropTypes.instanceOf(Date),
    setBudgetTimingStart: PropTypes.func.isRequired,
    setBudgetTimingEnd: PropTypes.func.isRequired,
    handleEntireDataset: PropTypes.func.isRequired,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};

NameAndSettings.defaultProps = {
    refreshName: "No Refresh Selected",
};

export default NameAndSettings;

function collapseItem(theme, ownerState) {
  const { palette, borders, functions } = theme;
  const { active, isSubmenu, parentActive } = ownerState; // Track parentActive for parent menus

  const { blue, grey, common } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;
  //console.log("active, parentActive, isSubmenu: ", active, parentActive, isSubmenu);
  return {
    background: active || parentActive ? "#E3F2FD" : "transparent",
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(2)} ${pxToRem(10)}`, // Smaller padding for submenus
    margin: `${pxToRem(2)} ${pxToRem(8)}`, // Minimal margin for submenus
    borderRadius: '15px',
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    listStyle: "none",

    "&:hover, &:focus": {
      backgroundColor: active && isSubmenu ? "#E3F2FD" : "#F0F0F0", // Light hover effect for inactive items
    },
  };
}

function collapseIconBox(theme, ownerState) {
  const { palette, functions } = theme;
  const { grey, blue } = palette;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color: ownerState.active || ownerState.parentActive ? "#1E90FF" : grey[600], // Blue for active or parentActive icon
    display: "grid",
    placeItems: "center",
  };
}

const collapseIcon = (theme, ownerState) => {
  const { palette } = theme;
  const { grey } = palette;

  return {
    color: ownerState.active || ownerState.parentActive ? "#1E90FF" : grey[600], // Blue for active or parentActive icon
  };
};

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav } = ownerState;

  const { size } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(10),
    [breakpoints.up("xl")]: {
      opacity: miniSidenav ? 0 : 1,
      maxWidth: miniSidenav ? 0 : "100%",
      marginLeft: miniSidenav ? 0 : pxToRem(10),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
    "& span": {
      fontWeight: ownerState.active || ownerState.parentActive ? "bold" : "normal",
      fontSize: size.sm,
      color: ownerState.active || ownerState.parentActive ? "#1E90FF" : "black", // Blue for active or parentActive text
    },
  };
}

export { collapseItem, collapseIconBox, collapseIcon, collapseText };

import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Tooltip, Card, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress, IconButton, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { blue } from '@mui/material/colors';
import axios from 'axios';
import MDTypography from 'components/MDTypography';
import { useAuthInfo } from "@propelauth/react";
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { useSignedUrl } from 'hooks/useSignedUrl';
import { useBrand } from 'layouts/utils/BrandContext';
import { useSignedThumbnailUrls } from 'hooks/useSignedThumbnailUrls';
import useSaveBrandRefreshStepSelection from 'layouts/utils/SaveBrandRefreshStepSelection';

const DeckBuilderTab = React.memo(({ refresh }) => {
    const [errors, setErrors] = useState({});
    const { loadContext, saveContext, selectedBrand, selectedRefresh, user_email, user_company, setSelectedRefresh, currentStep, updateStep } = useBrand();
    const championModel = selectedRefresh?.championModel || {};
    const { brandName, refreshName, tab } = useParams();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [generatedFileName, setGeneratedFileName] = useState(null);
    const [slideThumbnails, setSlideThumbnails] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [thumbnailsLoading, setThumbnailsLoading] = useState(false);
    const [selectedModelType, setSelectedModelType] = useState('');
    const [modelsOutputs, setModelsOutputs] = useState({});
    const navigate = useNavigate();
    const auth = useAuthInfo();
    const saveBrandRefreshStepSelection = useSaveBrandRefreshStepSelection();
    const mmm_labs_bucket = process.env.REACT_APP_MMM_LABS_BUCKET;

    const signedThumbnailUrls = useSignedThumbnailUrls(slideThumbnails);

    const showAlert = (message, color = "info") => {
        setAlert({ show: true, message, color });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleModelTypeChange = (event) => {
      const newModelType = event.target.value;
      setSelectedModelType(newModelType);
      handleGenerateDeck(newModelType);
    };

    useEffect(() => {
        const fetchContext = async () => {
          if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
            await loadContext(brandName, refreshName);
          }
        };
        fetchContext();
    }, [brandName, refreshName, loadContext]);

    useEffect(() => {
        if (selectedBrand && selectedRefresh && championModel?.slide_deck_url) {
            // Set the slide deck for the champion model if already defined.
            setGeneratedFileName(championModel.slide_deck_url);

            const storageKey = `${selectedBrand.brand_name}_${selectedRefresh.refresh_name}_slideThumbnails`;
            const storedThumbnails = localStorage.getItem(storageKey);
            if (storedThumbnails) {
                console.log("Stored thumbnails found, loading from localStorage.");
                setSlideThumbnails(JSON.parse(storedThumbnails));
                return;
            }

            const fetchThumbnails = async () => {
                setThumbnailsLoading(true);
                const baseUrl = process.env.REACT_APP_API_BASE_URL;
                const fullUrl = `${baseUrl}/deck/get_slide_thumbnails/`; // Adjusted URL

                try {
                    const thumbnailsResponse = await axios.get(fullUrl, {
                        headers: {
                            "Accept": "application/json",
                            "Authorization": `Bearer ${auth.accessToken}`
                        },
                        params: {
                            file_path: `${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/${championModel.slide_deck_url}`,
                            brand_name: selectedBrand.brand_name,
                            refresh_name: selectedRefresh.refresh_name,
                        }
                    });
                    if (thumbnailsResponse.status === 200) {
                        setSlideThumbnails(thumbnailsResponse.data.thumbnails);
                        localStorage.setItem(storageKey, JSON.stringify(thumbnailsResponse.data.thumbnails));
                    }
                } catch (error) {
                    console.error('Error fetching thumbnails:', error);
                    setGeneratedFileName(null); // Forces a new deck file to be generated
                } finally {
                    setThumbnailsLoading(false); // Stop loading thumbnails
                }
            };
            fetchThumbnails();
        }
    }, [selectedBrand, selectedRefresh, championModel]);

    const downloadLink = generatedFileName
        ? `https://storage.cloud.google.com/${mmm_labs_bucket}/${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/${generatedFileName}`
        : null;

    const signedDownloadLink = useSignedUrl(downloadLink);

    const handleDownloadSlideDeck = async () => {
        if (signedDownloadLink) {
            try {
                const response = await axios.get(signedDownloadLink, {
                    responseType: 'blob',
                });
                saveAs(response.data, generatedFileName);
            } catch (error) {
                console.error('Error downloading the file:', error);
                showAlert('Error downloading the file', 'error');
            }
        } else {
            showAlert('Error: Signed URL not available', 'error');
        }
    };

    const handleModelsResultsNavigation = () => {
         navigate(`/modeling/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/modelsResults`);
    };

    const handleGenerateDeck = async (model_type) => {
        setLoading(true);
        setLoadingMessage(`Generating Slide Deck for model ${model_type.toUpperCase()}...`);

        // Clear localStorage for old thumbnails
        console.log('Clearing local storage for old thumbnails');
        const storageKey = `${selectedBrand.brand_name}_${selectedRefresh.refresh_name}_slideThumbnails`;
        localStorage.removeItem(storageKey);

        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/deck/generate_slide_deck/`;
        try {
            const response = await axios.post(
                fullUrl,
                {
                    user_email: user_email,
                    brand_name: selectedBrand.brand_name,
                    refresh_name: selectedRefresh.refresh_name,
                    model: model_type,
                    user_company: user_company
                },
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
            if (response.status === 200) {
                 const log_message = `Slide deck generated: ${response.data.data.filename}`;
                 console.log(log_message);
                 showAlert(log_message, "info");
                 setGeneratedFileName(response.data.data.filename);
                 setSelectedRefresh((prevRefresh) => ({
                      ...prevRefresh,
                      championModel: {
                        ...prevRefresh.championModel,
                        slide_deck_url: response.data.data.filename
                      }
                 }));

                 // Save the context
                 const updatedChampionModel = {
                    model_type: championModel.model_type,
                    highest_accuracy: championModel.highest_accuracy,
                    best_roi: championModel.best_roi,
                    slide_deck_url: response.data.data.filename
                 };
                 setSelectedRefresh({
                  ...selectedRefresh,
                  championModel: updatedChampionModel,
              });
              updateStep(7); // Move to 'Generate Deck' step
              saveBrandRefreshStepSelection(selectedBrand.brand_name, selectedRefresh.refresh_name, 7);
              const contextData = {
                  selectedRefresh: selectedRefresh,
                  championModel: updatedChampionModel,
                  currentStep: 7
              };
              saveContext(contextData);
          } else {
              showAlert(`Error generating deck: ${response.statusText || 'Unknown error'}`, 'error');
          }
      } catch (error) {
          let errorMessage = 'Error generating deck';
          if (error.response && error.response.data && error.response.data.detail) {
              errorMessage = `Error: ${error.response.data.detail}`;
          } else if (error.message) {
              errorMessage = `Error: ${error.message}`;
          }
          showAlert(errorMessage, 'error');
      } finally {
          setLoading(false);
          setLoadingMessage('');
      }
  };

    const textFieldStyles = {
        margin: 'dense',
        variant: 'outlined',
        fullWidth: true,
        sx: {
            '& .MuiInputBase-root': {
                height: '44px',
            },
        },
    };

   if (!refresh) return null;

return (
  <>
    <Dialog open={openDialog} aria-labelledby="Modeling" aria-describedby="run-models" onClose={handleCloseDialog}>
      <DialogTitle>Deck Builder</DialogTitle>
      <DialogContent>
        {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

    <MDBox pt={3} pb={3}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
      <MDTypography variant="h3">
        Insight Deck Builder
        <Tooltip title="Download Slide Deck">
          <span>
            <IconButton onClick={handleDownloadSlideDeck} disabled={!generatedFileName}>
              <DownloadIcon />
            </IconButton>
          </span>
        </Tooltip>
      </MDTypography>

      <MDBox sx={{ width: '200px', ml: 2 }}>
        <FormControl variant="outlined" margin="dense" {...textFieldStyles}>
          <InputLabel>Select Model Type</InputLabel>
          <Select
            name="Model Type"
            value={selectedModelType}
            onChange={handleModelTypeChange}
            label="Select Model Type"
            IconComponent={ArrowDropDownIcon}
            disabled={!refresh['Models_results'] || refresh['Models_results'].length === 0}
            sx={{
              backgroundColor: 'white',
              '& .MuiSelect-icon': {
                display: 'block',
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
              },
            }}
          >
			{[...new Set(refresh?.['Models_results']?.map((file) => file.model_type))].map((modelType) => (
			    <MenuItem key={modelType} value={modelType}>
			      {modelType.toUpperCase()}
			    </MenuItem>
			))}
          </Select>
        </FormControl>
      </MDBox>
     </MDBox>

      <Card sx={{ mt: 2 }}>
        <MDTypography variant="body2" p={2}>
          {championModel?.model_type || selectedModelType ? (
            <>
              <MDTypography variant="h4" align="left" gutterBottom>
                 Selected Model: {selectedModelType ? selectedModelType.toUpperCase() : championModel?.model_type.toUpperCase() || 'N/A'}
              </MDTypography>
               {championModel?.model_type === selectedModelType && championModel?.best_roi !== undefined && championModel?.highest_accuracy !== undefined && (
		        <MDBox display="flex" justifyContent="space-evenly" alignItems="center">
		          <MDBox textAlign="center">
		            <MDTypography variant="h6">Best ROI</MDTypography>
		            <MDTypography variant="h4">{championModel.best_roi || 'N/A'}x</MDTypography>
		          </MDBox>
		          <MDBox textAlign="center">
		            <MDTypography variant="h6">Highest Accuracy</MDTypography>
		            <MDTypography variant="h4">{championModel.highest_accuracy || 'N/A'}%</MDTypography>
		          </MDBox>
		        </MDBox>
		      )}
            </>
          ) : (
            <>
              <MDTypography variant="h6" color="warning" align="center">
                No best model has been found. Make sure a brand and a refresh are selected and then go <a href={`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelsResults`}>Models Results</a> to see if a the best model results.<br/>
                If no model results is displayed, go to <a href={`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelingSetup`}>Modeling</a> to run a model type.<br/>
                You may also select a model type from the dropdown list if any are available.
              </MDTypography>
              <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                <MDButton variant="contained" color="dark" onClick={handleModelsResultsNavigation}>
                  See Models Results
                </MDButton>
              </MDBox>
            </>
          )}

          <MDBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 2, mb: 2 }}>
            {loading && (
              <MDBox display="flex" flexDirection="column" alignItems="center" mb={2}>
                <CircularProgress sx={{ color: blue[500], marginBottom: '1rem' }} size={24} color="inherit" />
                <MDTypography variant="body2">{loadingMessage}</MDTypography>
              </MDBox>
            )}
            <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => handleGenerateDeck(championModel.model_type)}
                disabled={!!generatedFileName || !championModel?.model_type || loading}
              >
                Generate Slide Deck
              </MDButton>
              <MDButton variant="contained" color="dark" onClick={handleDownloadSlideDeck} disabled={!generatedFileName}>
                Download Slide Deck
              </MDButton>
            </MDBox>
          </MDBox>
        </MDTypography>
      </Card>

      {thumbnailsLoading ? (
        <MDBox display="flex" justifyContent="center" mt={3} mb={3}>
          <CircularProgress sx={{ color: blue[500] }} size={24} color="inherit" />
          <MDTypography variant="body2" sx={{ ml: 2 }}>Fetching Thumbnails...</MDTypography>
        </MDBox>
      ) : (
        generatedFileName && slideThumbnails.length > 0 && (
          <Card sx={{ mt: 3, mb: 3, p: 2 }}>
            <MDBox>
              <MDTypography variant="h5">Slide Deck Thumbnails</MDTypography>
             <MDBox sx={{ mt: 2, mb: 2 }} display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
                {signedThumbnailUrls.map((signedThumbnailUrl, index) => (
                    <Tooltip key={index} title={`Slide ${index + 1}`} arrow>
                        <img
                            src={signedThumbnailUrl}
                            alt={`Slide ${index + 1}`}
                            style={{
                                width: '150px',
                                height: 'auto',
                                borderRadius: '4px',
                                border: '1px solid #000',
                                padding: '4px',
                            }}
                        />
                    </Tooltip>
                ))}
              </MDBox>
            </MDBox>
          </Card>
        )
      )}
    </MDBox>
  </>
  );
});

export default DeckBuilderTab;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MDBox from "components/MDBox";
import FlagIcon from '@mui/icons-material/Flag';
import AddTaskIcon from '@mui/icons-material/AddTask';

const BreadcrumbsComponent = ({ selectedBrand, selectedRefresh }) => {
	const navigate = useNavigate();

    // Automatically construct the breadcrumb based on selectedBrand and selectedRefresh
	const breadcrumb = [
	  ...(selectedBrand
	    ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }]
	    : [{ label: <span>Brand: <i style={{ color: '#AAAAAA' }}>None</i></span>, link: null }]),
	  ...(selectedRefresh && selectedRefresh.refresh_name
	    ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand?.brand_name}/${selectedRefresh.refresh_name}/data-connection` }]
	    : [{ label: <span>Refresh: <i style={{ color: '#AAAAAA' }}>None</i></span>, link: null }]),
	  ...(selectedRefresh && selectedRefresh.selectedFile
	    ? [{ label: `File: ${selectedRefresh.selectedFile.display_name || selectedRefresh.selectedFile.file.file_name}`, link: null }]
	    : [{ label: <span>File: <i style={{ color: '#AAAAAA' }}>None</i></span>, link: null }]),
	];

  return (
    <MDBox sx={{ pt: 2, pl: 0, pb: 1 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Typography color="inherit" variant="normal">
          <Link
            color="inherit"
            onClick={() => {
              if (selectedBrand && selectedRefresh) {
                navigate(`/starter-guide/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/step`);
              } else {
                navigate("/starter-guide");
              }
            }}
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', cursor: 'pointer' }}
          >
            <FlagIcon sx={{ mr: 0.5 }} fontSize="small" />
            Starter Guide
          </Link>
        </Typography>
        <Typography color="inherit" variant="normal">
          <Link
            color="inherit"
            onClick={() => navigate("/brand-manager")}
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
          >
            <AddTaskIcon sx={{ mr: 0.5, verticalAlign: 'middle' }} fontSize="small" />
            Brand Manager
          </Link>
        </Typography>
        {breadcrumb.map((item, index) => (
          <Typography key={index} color="inherit" variant="normal">
            {item.link ? (
              <Link
                color="inherit"
                onClick={() => navigate(item.link)}
                sx={{ fontWeight: 'bold', cursor: 'pointer' }}
              >
                {item.label}
              </Link>
            ) : (
              <span style={{ fontWeight: 'bold' }}>
                {item.label}
              </span>
            )}
          </Typography>
        ))}
      </Breadcrumbs>
    </MDBox>
  );
};

export default BreadcrumbsComponent;

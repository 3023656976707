import React, { useState, useEffect, useMemo } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import SidenavCollapse from "assets/Sidenav/SidenavCollapse";
import SidenavRoot from "assets/Sidenav/SidenavRoot";
import logo from 'assets/images/mmmlabs-logo.png';
import { useMaterialUIController, setMiniSidenav } from "context";
import { useBrand } from 'layouts/utils/BrandContext';
import { useAuthInfo } from "@propelauth/react";
import getAccountUrl from "layouts/utils/Config";

const accountUrl = getAccountUrl();

function Sidenav({ color = 'info', brand = "", brandName, routes, props = { user: { email: "" } }, ...rest }) {
  const authInfo = useAuthInfo();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { setUserEmail, setUserCompany, setUserFirstLastName, setUserRole } = useBrand();
  const orgId = Object.keys(authInfo.userClass.orgIdToUserOrgInfo)[0];
  const org = authInfo.userClass.orgIdToUserOrgInfo[orgId].orgName;
  const userRole = authInfo.orgHelper.getOrgs()[0].userAssignedRole;
  const { selectedBrand, selectedRefresh } = useBrand();

  useEffect(() => {
    setUserEmail(props.user.email);
    setUserCompany(org);
    setUserFirstLastName(`${props.user.firstName} ${props.user.lastName}`);
    setUserRole(userRole);

    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, setUserEmail, setUserCompany, org, props.user.email]);

  const dynamicRoutes = useMemo(() => {
    const baseRoutes = [
      {
        key: "starter-guide",
        type: "collapse",
        name: "Starter Guide",
        icon: <Icon fontSize="small">flag</Icon>,
        route: selectedBrand && selectedRefresh
          ? `/starter-guide/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/step`
          : "/starter-guide",
      },
      {
        key: "brand-manager",
        type: "collapse",
        name: "Brand Manager",
        icon: <Icon fontSize="small">add_task</Icon>,
        route: "/brand-manager",
      },
      {
        key: "data-hub",
        type: "collapse",
        name: "Data Hub",
        icon: <Icon fontSize="small">device_hub</Icon>,
        route: "#",
        subMenu: [
         {
           key: "data-connection",
           name: "Data Connection",
           icon: (
               <MDBox sx={{ fontSize: '1rem' }}>
                    <Icon fontSize="inherit">schema</Icon>
               </MDBox>
           ),
           route: `/data-hub/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/data-connection`,
         },
//          {
//            key: "data-source",
//            name: "Data Source",
//            icon: (
//                <MDBox sx={{ fontSize: '1rem' }}>
//                     <Icon fontSize="inherit">schema</Icon>
//                </MDBox>
//            ),
//            route: `/data-hub/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/dataSource`,
//          },
        ],
      },
      {
        key: "data-validation",
        type: "collapse",
        name: "Data Validation",
        icon: <Icon fontSize="small">checklist</Icon>,
        route: "#",
        subMenu: [
          {
            key: "sales",
            name: "Sales",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">leaderboard</Icon>
                </MDBox>
            ),
            route: `/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/sales`,
          },
          {
            key: "marketing",
            name: "Marketing",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">stacked_line_chart</Icon>
                </MDBox>
            ),
            route: `/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/marketing`,
          },
          {
            key: "data-readiness",
            name: "Data Readiness",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">query_builder</Icon>
                </MDBox>
            ),
            route: `/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/dataReadiness`,
          },
        ],
      },
      {
        key: "modeling",
        type: "collapse",
        name: "Modeling",
        icon: <Icon fontSize="small">model_training</Icon>,
        route: "#",
        subMenu: [
          {
            key: "modelingSetup",
            name: "Modeling Setup",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">model_training</Icon>
                </MDBox>
            ),
            route: `/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelingSetup`,
          },
          {
            key: "modelsResults",
            name: "Model Results",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">assessment</Icon>
                </MDBox>
            ),
            route: `/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelsResults`,
          },
        ],
      },
      {
        key: "ai-insights",
        type: "collapse",
        name: "Insights",
        icon: <Icon fontSize="small">insights</Icon>,
        route: "#",
        subMenu: [
          {
            key: "exec",
            name: "Exec Summary",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">summarize</Icon>
                </MDBox>
            ),
            route: `/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/exec`,
          },
          {
            key: "roi",
            name: "ROI Results",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">monetization_on</Icon>
                </MDBox>
            ),
            route: `/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/roi`,
          },
          {
            key: "stats",
            name: "Model Statistics",
            icon: (
                <MDBox sx={{ fontSize: '1rem' }}>
                     <Icon fontSize="inherit">show_chart</Icon>
                </MDBox>
            ),
            route: `/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/stats`,
          },
        ],
      },
      {
        key: "scenario-planner",
        type: "collapse",
        name: "Scenario Planner",
        icon: <Icon fontSize="small">edit_calendar</Icon>,
        route: selectedBrand && selectedRefresh
          ? `/scenario-planner/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}`
          : "/scenario-planner",
      },
    ];

    // Add extra route for non-trial users
    if (userRole !== "Trial") {
      baseRoutes.push({
        key: "insight-deck-builder",
        type: "collapse",
        name: "Insight Deck Builder",
        icon: <Icon fontSize="small">present_to_all</Icon>,
        route: selectedBrand && selectedRefresh
          ? `/insight-deck-builder/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/deckBuilder`
          : "/insight-deck-builder",
      });
    }

    // Add fixed routes
    return [
      ...baseRoutes,
      {
        key: "ai-agent",
        type: "collapse",
        name: "AI Agent",
        icon: <Icon fontSize="small">support_agent</Icon>,
        route: selectedBrand ? `/ai-agent/${selectedBrand.brand_name}` : "/ai-agent",
      },
      {
        key: "account",
        type: "collapse",
        name: "Account",
        icon: <Icon fontSize="small">manage_accounts</Icon>,
        route: `${accountUrl}/account`,
      },
      {
        key: "logout",
        type: "collapse",
        name: "Log Out",
        icon: <Icon fontSize="small">logout</Icon>,
        route: "/authentication/logout",
      },
    ];
  }, [selectedBrand, selectedRefresh, userRole]);

  const [expandedMenus, setExpandedMenus] = useState(() => {
    const savedMenus = JSON.parse(localStorage.getItem('expandedMenus')) || {};
    dynamicRoutes.forEach(route => {
      if (route.subMenu) {
        route.subMenu.forEach(subItem => {
          if (location.pathname.startsWith(subItem.route)) {
            savedMenus[route.key] = true;
          }
        });
      }
    });
    localStorage.setItem('expandedMenus', JSON.stringify(savedMenus));
    return savedMenus;
  });

  const toggleSubMenu = (key) => {
    const updatedMenus = { ...expandedMenus, [key]: !expandedMenus[key] };
    setExpandedMenus(updatedMenus);
    localStorage.setItem('expandedMenus', JSON.stringify(updatedMenus));
  };

  useEffect(() => {
    const updatedMenus = { ...expandedMenus };
    dynamicRoutes.forEach((route) => {
      if (route.subMenu) {
        route.subMenu.forEach((subItem) => {
          if (location.pathname.startsWith(subItem.route)) {
            updatedMenus[route.key] = true;
          }
        });
      }
    });

    if (JSON.stringify(updatedMenus) !== JSON.stringify(expandedMenus)) {
      setExpandedMenus(updatedMenus);
      localStorage.setItem('expandedMenus', JSON.stringify(updatedMenus));
    }
  }, [location.pathname, dynamicRoutes]);

  const renderRoutes = dynamicRoutes.map(({ type, name, icon, key, route, subMenu }) => {
    // Decode both the pathname and route for accurate matching
    const isActive = decodeURIComponent(location.pathname).startsWith(decodeURIComponent(route));
    const isParentActive = subMenu?.some(subItem => decodeURIComponent(location.pathname).startsWith(decodeURIComponent(subItem.route)));

    return (
      <React.Fragment key={key}>
        <MDBox display="flex" alignItems="center" justifyContent="space-between">
          <NavLink
            to={route}
            onClick={() => subMenu && toggleSubMenu(key)}
            style={{ display: 'flex', alignItems: 'center', width: '100%', textDecoration: 'none' }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={isActive || isParentActive}
              parentActive={isParentActive}
            />
          </NavLink>
          {subMenu && (
            <Icon
              fontSize="medium"
              sx={{
                transition: 'transform 0.3s',
                transform: expandedMenus[key] ? 'rotate(90deg)' : 'rotate(0deg)',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault(); // Prevent NavLink trigger
                toggleSubMenu(key);
              }}
            >
              chevron_right
            </Icon>
          )}
        </MDBox>

        {subMenu && (
          <Collapse in={expandedMenus[key]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ pl: 2 }}>
              {subMenu.map((subItem) => {
                const isSubActive = location.pathname.startsWith(subItem.route);
                return (
                  <NavLink
                    key={subItem.key}
                    to={subItem.route}
                    style={{ textDecoration: 'none' }}
                  >
                    <SidenavCollapse
                      name={subItem.name}
                      icon={subItem.icon}
                      active={isSubActive}
                      sx={{
                        paddingLeft: '16px',
                        fontWeight: isSubActive ? 'bold' : 'normal',
                        color: isSubActive ? color : 'inherit',
                      }}
                    />
                  </NavLink>
                );
              })}
            </List>
          </Collapse>
        )}
        {key === "starter-guide" && <Divider sx={{ my: 1 }} />}
      </React.Fragment>
    );
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ whiteSidenav, miniSidenav, darkMode }}
      sx={{
        width: 260,
        p: 0,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 260,
          boxSizing: 'border-box',
        },
      }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox component={NavLink} to="/brand-manager" display="flex" alignItems="center">
          <img src={logo} alt="MMM Labs" className="logo" style={{ maxWidth: '150px', height: 'auto' }} />
          {brand && <MDBox width="2rem" />}
        </MDBox>
        <MDTypography fontSize="1.4rem" fontWeight="medium" color="dark">
          {brandName}
        </MDTypography>
      </MDBox>
      <Divider light={true} />
      <List>{renderRoutes}</List>
      <Divider light={false} />
      <MDTypography variant="caption" textAlign="center">
        <b>{props.user.firstName} {props.user.lastName}</b><br />
        is logged in with username<br />
        <b>{props.user.email}</b><br />from<br />
        <b>{org}</b>
      </MDTypography>
    </SidenavRoot>
  );
}

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;

import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import DeckBuilderTab from 'layouts/insight-deck-builder/components/DeckBuilderTab';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { useBrand } from 'layouts/utils/BrandContext';

function InsightDeckBuilder() {
  const navigate = useNavigate();
  const { selectedBrand, selectedRefresh } = useBrand();
  const { brandName, refreshName, tab } = useParams();

  // Define tab indexes based on the tab names in the URL
  const tabMapping = {
    deckBuilder: 0,
  };

  const [tabValue, setTabValue] = useState(tabMapping[tab] || 0);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    // Get the key from the tabMapping and navigate to the correct URL
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/insight-deck-builder/${brandName}/${refreshName}/${selectedTab}`);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });


 return (
    <DashboardLayout>
      <DashboardNavbar />

            <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Insight Deck Builder"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>present_to_all</Icon> }
                  style={tabStyle(0)}
                  sx={{
                        backgroundColor: tabValue === 0 ? '#E3F2FD' : 'transparent',
                        border: tabValue === 0 ? '2px solid #247EEA' : 'none'
                 }}
                />
              </Tabs>
                  <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
            </AppBar>
            {tabValue === 0 && <DeckBuilderTab refresh={selectedRefresh}/>}
      <Footer />
    </DashboardLayout>
  );
}

export default InsightDeckBuilder;

import { useState, useEffect, useContext, createContext, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";

export const SignedUrlContext = createContext();

export const SignedUrlProvider = ({ children }) => {
  const [signedUrls, setSignedUrls] = useState({});
  const auth = useAuthInfo();

  const getSignedUrl = useCallback(async (fileUrl) => {
    // Check if the URL already has a signed URL
    if (signedUrls[fileUrl]) {
      return signedUrls[fileUrl];
    }

    const token = auth.accessToken;
    if (!token) {
      console.error('Access token is missing');
      return null;
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/utils/generate_signed_url`;

    try {
      const response = await axios.get(fullUrl, {
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`
        },
        params: {
          file_url: fileUrl
        }
      });

      const { signed_url } = response.data;
      setSignedUrls((prevUrls) => ({ ...prevUrls, [fileUrl]: signed_url }));
      return signed_url;

    } catch (error) {
      console.error('Error fetching signed URL:', error);
      return null;
    }
  }, [auth.accessToken, signedUrls]);

  const value = useMemo(() => ({ getSignedUrl }), [getSignedUrl]);

  return (
    <SignedUrlContext.Provider value={value}>
      {children}
    </SignedUrlContext.Provider>
  );
};

export const useSignedUrl = (filePath) => {
  const { getSignedUrl } = useContext(SignedUrlContext);
  const [signedUrl, setSignedUrl] = useState('');
  const mmm_labs_bucket = process.env.REACT_APP_MMM_LABS_BUCKET;

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (filePath) {
        const relativePath = filePath.split(`${mmm_labs_bucket}/`).pop();  // Extract relative path
        const url = await getSignedUrl(relativePath);  // Fetch the signed URL using the context method
        setSignedUrl(url);
      }
    };

    fetchSignedUrl();
  }, [filePath, getSignedUrl, mmm_labs_bucket]);  // Add dependencies to ensure re-fetch on filePath or bucket change

  return signedUrl;  // Return the signed URL
};
import React, { useContext, useMemo, useState } from 'react';
import 'assets/css/table.css';
import MDTypography from 'components/MDTypography';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import DataTable from "assets/Tables/DataTable";
import {useBrand } from 'layouts/utils/BrandContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/css/custom_date_picker.css';

function MarketingSummaryTable({ data = [] }) {
  const { configurationDataUserSelection, configurationData } = useBrand();
  const date_column = configurationDataUserSelection?.date[0] || '';
  const channels = configurationDataUserSelection?.adsChannels || [];
  //const metrics = configurationDataUserSelection?.adsSpendMetric || [];
  const metrics = ['spend', 'impressions', 'clicks'];
  const adsVariable = configurationDataUserSelection?.adsVariable[0] || '';
  const [selectedRange, setSelectedRange] = useState([null, null]);
  const [startDate, endDate] = selectedRange;

  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const parseDate = (dateString) => {
        if (!dateString || typeof dateString !== 'string') return null;
        const parsedDate = new Date(dateString.split('T')[0]);
        return isNaN(parsedDate) ? null : parsedDate;
  };

  const handleDateRangeChange = (range) => {
      if (!data.length) return; // If there's no data, exit early

      // Extract all dates from the data, parsed to Date objects
      const dataDates = data.map(item => parseDate(item[date_column])).filter(date => date);

      if (!dataDates.length) return; // If there are no valid dates, exit early

      // Sort dates in ascending order
      dataDates.sort((a, b) => a - b);

      // Set the earliest and latest dates from the data
      const dataStartDate = dataDates[0];
      const dataEndDate = dataDates[dataDates.length - 1];

      let start = null;
      let end = dataEndDate;

      switch (range) {
        case '1M':
          start = new Date(dataEndDate.getFullYear(), dataEndDate.getMonth() - 1, dataEndDate.getDate());
          break;
        case '3M':
          start = new Date(dataEndDate.getFullYear(), dataEndDate.getMonth() - 3, dataEndDate.getDate());
          break;
        case '6M':
          start = new Date(dataEndDate.getFullYear(), dataEndDate.getMonth() - 6, dataEndDate.getDate());
          break;
        case 'YTD':
          start = new Date(dataEndDate.getFullYear(), 0, 1);
          break;
        case '1Y':
          start = new Date(dataEndDate.getFullYear() - 1, dataEndDate.getMonth(), dataEndDate.getDate());
          break;
        case '2Y':
          start = new Date(dataEndDate.getFullYear() - 2, dataEndDate.getMonth(), dataEndDate.getDate());
          break;
        case 'All':
          start = dataStartDate;
          end = dataEndDate;
          break;
        default:
          break;
      }

      // Make sure the start date does not precede the earliest data date
      if (start && start < dataStartDate) {
        start = dataStartDate;
      }
      setSelectedRange([start, end]);
  };

  // Ensure data is always an array and filter data by date range
  const safeData = useMemo(() => {
    const filteredData = Array.isArray(data) ? data.filter((item) => {
      const recordDate = parseDate(item[date_column]);
      //console.log('Record date:', recordDate, 'Start date:', startDate, 'End date:', endDate);
      if (startDate && endDate) {
        return recordDate >= startDate && recordDate <= endDate;
      }
      return true;
    }) : [];
    //console.log('Filtered data:', filteredData);
    return filteredData;
  }, [data, startDate, endDate]);

  // Aggregate marketing data by channel
  const summary = useMemo(() => safeData.reduce((acc, curr) => {
    const Marketing_Channel = curr[adsVariable];

    if (!channels.includes(Marketing_Channel)) {
      return acc;
    }

    if (!acc[Marketing_Channel]) {
      acc[Marketing_Channel] = {};
      metrics.forEach(metric => {
        acc[Marketing_Channel][metric] = 0;
      });
    }

    metrics.forEach(metric => {
      acc[Marketing_Channel][metric] += curr[metric] || 0;
    });

    return acc;
  }, {}), [safeData, channels, metrics, adsVariable]);

  // Convert the aggregated data into rows
  const rows = useMemo(() =>
      Object.entries(summary).map(([channel, metricData]) => ({
        Marketing_Channel: channel,
        ...Object.fromEntries(
          metrics.map(metric => {
            let formattedValue;

            // Check if metric contains 'spend' and format as currency
            if (metric.toLowerCase().includes('spend')) {
              formattedValue = `$${parseFloat(metricData[metric] || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            }
            // Check if metric contains 'impressions' and format as an integer
            else if (metric.toLowerCase().includes('impressions') || metric.toLowerCase().includes('clicks')) {
              formattedValue = metricData[metric] !== null
                ? Number(metricData[metric]).toLocaleString(undefined, { maximumFractionDigits: 0 })
                : 'N/A';
            }
            else {
              formattedValue = Number(metricData[metric]).toLocaleString();
            }
            return [metric, formattedValue];
          })
        )
      }))
  , [summary, metrics]);

  // Define the columns dynamically based on metrics
  const columns = useMemo(() => [
    { Header: <MDTypography fontWeight="bold" variant="h6">Marketing Channel</MDTypography>, accessor: "Marketing_Channel" },
    ...metrics.map(metric => ({
      Header: <MDTypography fontWeight="bold" variant="h6">{capitalizeFirstLetter(metric)}</MDTypography>,
      accessor: metric,
    })),
  ], [metrics]);

  return (
    <Card>
      <MDBox m={2} display="flex" alignItems="center">
        <DatePicker
          selected={startDate}
          onChange={(update) => {
            setSelectedRange(update);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          isClearable
          placeholderText="Select date range"
          className="date-picker"
        />
        <MDButton onClick={() => handleDateRangeChange('1M')}>1M</MDButton>
        <MDButton onClick={() => handleDateRangeChange('3M')}>3M</MDButton>
        <MDButton onClick={() => handleDateRangeChange('6M')}>6M</MDButton>
        <MDButton onClick={() => handleDateRangeChange('YTD')}>YTD</MDButton>
        <MDButton onClick={() => handleDateRangeChange('1Y')}>1Y</MDButton>
        <MDButton onClick={() => handleDateRangeChange('2Y')}>2Y</MDButton>
        <MDButton onClick={() => handleDateRangeChange('All')}>All</MDButton>
      </MDBox>
      {rows.length === 0 ? (
        <MDTypography variant="normal" mx={2} mt={2} mb={1}>
           No Marketing summary data available for the selected date range from <strong>{startDate ? startDate.toDateString() : 'N/A'}</strong> to <strong>{endDate ? endDate.toDateString(): 'N/A'}</strong>.
        </MDTypography>
      ) : (
        <MDBox m={2}>
           <div className="data-table-wrapper">
            <DataTable
              className="summary-table"
              table={{ columns, rows }}
              isSorted={true}
              entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
              showTotalEntries={true}
              noEndBorder
              canSearch
            />
          </div>
      </MDBox>
      )}
    </Card>
  );
}

export default MarketingSummaryTable;

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from 'layouts/utils/BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, variables, theme) {
  return {
    fontWeight:
      variables.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function OrganicVariables({ organic_variables = [], organic_ads_channels = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(organic_variables)) {
    organic_variables = [];
  }

  const filterValidVariables = (variables) => Array.isArray(variables)
    ? variables.filter(variable => organic_variables.includes(variable) && !organic_ads_channels.includes(variable))
    : [];

  const [organicVariables, setOrganicVariables] = useState(() => {
    const initialOrganicVariables = filterValidVariables(configurationDataUserSelection?.organicVariables || []);
    return initialOrganicVariables.length ? initialOrganicVariables : [];
  });

  useEffect(() => {
    const initialOrganicVariables = filterValidVariables(configurationDataUserSelection?.organicVariables || []);
    setOrganicVariables(initialOrganicVariables);
    updateConfigurationDataUserSelection('organicVariables', initialOrganicVariables);
  }, [organic_variables, configurationDataUserSelection?.organicVariables, updateConfigurationDataUserSelection, organic_ads_channels]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let newValue = [];

    if (value.includes('Select All')) {
      newValue = organic_variables.filter(variable => !organic_ads_channels.includes(variable));
    } else if (value.includes('Deselect All')) {
      newValue = [];
    } else {
      newValue = Array.isArray(value) ? value : value.split(',');
      const validValues = filterValidVariables(newValue);
      newValue = validValues;
    }

    setOrganicVariables(newValue);
    updateConfigurationDataUserSelection('organicVariables', newValue);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="organic-variable-label">Variables</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="organic-variable-label"
        id="multiple-organic-variable"
        multiple
        value={organicVariables.length > 0 ? organicVariables : []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-organic-variable" label="Variables" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        <MenuItem
          sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
          value="Select All"
        >
          Select All
        </MenuItem>
        <MenuItem
          sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
          value="Deselect All"
        >
          Deselect All
        </MenuItem>
        {organic_variables.length > 0 ? (
          organic_variables
            .filter(variable => !organic_ads_channels.includes(variable))
            .map((name) => (
              <MenuItem
                sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
                key={name}
                value={name}
                style={getStyles(name, organicVariables, theme)}
              >
                {name}
              </MenuItem>
            ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

import React, { useState } from 'react';
import { Box, Typography, Card, CardActionArea, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MDButton from "components/MDButton";
import { useSignedUrl } from 'hooks/useSignedUrl';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditBrandDialog from './EditBrandDialog';

const BrandItem = ({ brand, onClick, onUpdate, onDelete }) => {
  const signedUrl = useSignedUrl(brand.logo_url);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleSave = (brand, updatedBrand, file_logo) => {
    onUpdate(brand, updatedBrand, file_logo);
    setIsEditing(false);
  };

  const handleDelete = () => {
    onDelete(brand.brand_name);
    setIsDeleting(false);
  };

  return (
    <Card sx={{ width: 120, m: 1 }}>
      <CardActionArea onClick={() => onClick(brand)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
          <img src={signedUrl} alt={brand.brand_name} style={{ height: '50px' }} />
          <Typography variant="h6" sx={{ mt: 1 }}>{brand.brand_name}</Typography>
        </Box>
      </CardActionArea>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
        <IconButton onClick={() => setIsEditing(true)}  sx={{ color: '#006400' }}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => setIsDeleting(true)} sx={{ color: '#C31328' }}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <EditBrandDialog
        open={isEditing}
        onClose={() => setIsEditing(false)}
        brand={brand}
        onSave={handleSave}
      />

      <Dialog
        open={isDeleting}
        onClose={() => setIsDeleting(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Brand with all the Refreshes and its associated Model metadata?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setIsDeleting(false)} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}} >
            No
          </MDButton>
          <MDButton onClick={handleDelete} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}}>
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default BrandItem;

import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { Icon, Tooltip, Toolbar, AppBar } from "@mui/material";
import MDBox from "components/MDBox";
import Breadcrumbs from "assets/Breadcrumbs";
import { useBrand } from 'layouts/utils/BrandContext';
import { useAuthInfo } from "@propelauth/react";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "assets/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";

function DashboardNavbar({ absolute = false, light = false, isMini = false }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  const { selectedBrand, selectedRefresh } = useBrand();
  const userRole = useAuthInfo().orgHelper.getOrgs()[0].userAssignedRole;

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const getLogoutUrl = "/authentication/logout";
  const getAccountUrl = process.env.REACT_APP_API_URL;

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}></MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>

            <MDBox color={light ? "white" : "inherit"}>
              {selectedBrand && selectedRefresh ? (
              <Tooltip title="Starter Guide">
                <Link to={`/starter-guide/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/step`}>
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>flag</Icon>
                  </IconButton>
                </Link>
                </Tooltip>
              ) : null}
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
             <Tooltip title="Brand Manager">
              <Link to="/brand-manager">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>add_task</Icon>
                </IconButton>
              </Link>
              </Tooltip>
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              {selectedBrand && selectedRefresh ? (
              <Tooltip title="Data Hub">
                <Link to={`/data-hub/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-connection`}>
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>device_hub</Icon>
                  </IconButton>
                </Link>
                </Tooltip>
              ) : null}
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              {selectedBrand && selectedRefresh ? (
              <Tooltip title="Data Validation">
                <Link to={`/data-validation/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/sales`}>
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>checklist</Icon>
                  </IconButton>
                </Link>
                </Tooltip>
              ) : null}
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              {selectedBrand && selectedRefresh ? (
                <Tooltip title="Modeling">
                <Link to={`/modeling/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/modelingSetup`}>
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>model_training</Icon>
                  </IconButton>
                </Link>
                </Tooltip>
              ) : null}
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              {selectedBrand && selectedRefresh ? (
               <Tooltip title="Insights">
                <Link to={`/ai-insights/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/exec`}>
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>insights</Icon>
                  </IconButton>
                </Link>
               </Tooltip>
              ) : null}
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
              {selectedBrand && selectedRefresh ? (
               <Tooltip title="Scenario Planner">
                <Link to={`/scenario-planner/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}`}>
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>edit_calendar</Icon>
                  </IconButton>
                </Link>
               </Tooltip>
              ) : null}
            </MDBox>

            {userRole !== "Trial" && (
              <MDBox color={light ? "white" : "inherit"}>
                {selectedBrand && selectedRefresh ? (
                 <Tooltip title="Insight Deck Builder">
                  <Link to={`/insight-deck-builder/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/deckBuilder`}>
                    <IconButton sx={navbarIconButton} size="small" disableRipple>
                      <Icon sx={iconsStyle}>present_to_all</Icon>
                    </IconButton>
                  </Link>
                  </Tooltip>
                ) : null}
              </MDBox>
            )}

         <MDBox color={light ? "white" : "inherit"}>
            <Tooltip title="AI Agent">
              <Link to={`/ai-agent/${selectedBrand?.brand_name}`}>
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>support_agent</Icon>
                </IconButton>
              </Link>
              </Tooltip>
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
           <Tooltip title="Account">
              <Link to={`${getAccountUrl}/account`}>
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>manage_accountsIco</Icon>
                </IconButton>
              </Link>
           </Tooltip>
            </MDBox>

            <MDBox color={light ? "white" : "inherit"}>
            <Tooltip title="Logout">
              <Link to={getLogoutUrl}>
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>logout</Icon>
                </IconButton>
              </Link>
              </Tooltip>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Tabs, Tab, Link, Icon, Typography, Card, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Button, CircularProgress } from '@mui/material';
import { useAuthInfo } from "@propelauth/react";
import NameAndSettings from './NameAndSettings';
import Constraints from './Constraints';
import Results from './Results';
import axios from 'axios';
import ScenarioTabs from './ScenarioTabs';
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { v4 as uuidv4 } from 'uuid';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import { useBrand } from 'layouts/utils/BrandContext';
import { parseISO } from 'date-fns';

function ScenarioDetails({ onUpdateScenario }) {
  const { selectedBrand, selectedRefresh, user_email, loadContext } = useBrand();
  const location = useLocation();
  const navigate = useNavigate();
  const brandName = location.state?.brandName || '';
  const refreshName = location.state?.refreshName || '';
  const scenario = location.state?.scenario || {};

  // State variables
  const [selectedTab, setSelectedTab] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [constraints, setConstraints] = useState(scenario.constraints || []);
  const [totalBudget, setTotalBudget] = useState(scenario.budget || 100000);

  // Active budget timings
  const [activeBudgetTimingStart, setActiveBudgetTimingStart] = useState(scenario.budgetTimingStart ? parseISO(scenario.budgetTimingStart) : null);
  const [activeBudgetTimingEnd, setActiveBudgetTimingEnd] = useState(scenario.budgetTimingEnd ? parseISO(scenario.budgetTimingEnd) : null);

  const [scenarioName, setScenarioName] = useState(scenario.scenario || '');
  const [scenarioDescription, setScenarioDescription] = useState(scenario.description || '');
  const [scenarioModelType, setScenarioModelType] = useState(scenario.modelType || '');
  const [scenarioObjective, setScenarioObjective] = useState(scenario.scenarioGoal || '');
  const [optimizationGoal, setOptimizationGoal] = useState(scenario.optimizationGoal || '');
  const [tabValue, setTabValue] = useState(0);
  const [modelResults, setModelResults] = useState(null);
  const auth = useAuthInfo();

  // Additional state to hold Ad Spend data and dates
  const [adSpendData, setAdSpendData] = useState({});
  const [paidMediaColumns, setPaidMediaColumns] = useState([]);
  const [dates, setDates] = useState([]); // To store dates from wide_data
  const [dateVar, setDateVar] = useState(''); // To store the date variable key
  const [minDate, setMinDate] = useState(null); // Earliest date
  const [maxDate, setMaxDate] = useState(null); // Latest date
  const [numPeriods, setNumPeriods] = useState(0); // Number of periods in selected date range


  // Loading state for Optimization
  const [isOptimizing, setIsOptimizing] = useState(false);
  const [optimizationResult, setOptimizationResult] = useState(null);

  // Function to parse date strings as local dates
  const parseDate = (dateString) => {
      return parseISO(dateString);
  };

  // Handler to navigate back to Scenarios list
  const handleBackToScenarios = () => {
    navigate(`/scenario-planner/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}`);
  };

  // Handler to close alert dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handler to set tab value from AppBar Tabs
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  // Dynamic styling for AppBar Tabs
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  // Handler to change selected tab
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const fetchContext = async () => {
      if (brandName && refreshName) {
        await loadContext(brandName, refreshName);
      }
    };
    fetchContext();
  }, [brandName, refreshName]);

  // Handler for "Entire Dataset" button
  const handleEntireDataset = () => {
    if (minDate && maxDate) {
      setActiveBudgetTimingStart(minDate);
      setActiveBudgetTimingEnd(maxDate);
      setAlert({ show: true, message: 'Entire dataset selected. Recalculating spend...', color: 'success' });
      setOpenDialog(true);
    } else {
      console.warn('Min and Max dates are not set. Cannot select Entire Dataset.');
      setAlert({ show: true, message: 'Data not loaded yet. Please wait.', color: 'error' });
      setOpenDialog(true);
    }
  };

  // Function to calculate adSpendData based on date range
  const calculateAdSpendData = (wideData, datesArray, startDate, endDate) => {
    if (!wideData || Object.keys(wideData).length === 0) {
      console.warn('wideData is empty. Cannot calculate adSpendData.');
      return;
    }

    if (!datesArray || datesArray.length === 0) {
      console.warn('datesArray is empty. Cannot calculate adSpendData.');
      return;
    }

    // Determine the indices that fall within the date range
    let relevantIndices = [];
    datesArray.forEach((date, idx) => {
      if ((!startDate || date >= startDate) && (!endDate || date <= endDate)) {
        relevantIndices.push(idx);
      }
    });

    if (relevantIndices.length === 0) {
      console.warn('No data points found within the selected date range.');
    }

    // Calculate adSpendData by summing the values for each paid media variable within the date range
    const newAdSpendData = {};
    paidMediaColumns.forEach(variable => {
      if (wideData[variable] && Array.isArray(wideData[variable])) {
        const sum = relevantIndices.reduce((acc, idx) => acc + (parseFloat(wideData[variable][idx]) || 0), 0);
        newAdSpendData[variable] = parseFloat(sum.toFixed(2));
      } else {
        console.warn(`Variable ${variable} not found in wide_data or is not an array.`);
        newAdSpendData[variable] = 0;
      }
    });

    setAdSpendData(newAdSpendData);

    // Calculate numPeriods
    const numPeriods = relevantIndices.length;
    setNumPeriods(numPeriods);
  };

  // Function to handle Run Optimization
  const handleRunOptimization = async () => {
    // Validate that constraints and adSpendData are present
    if (constraints.length === 0) {
      setAlert({ show: true, message: 'No constraints defined. Please add constraints before running optimization.', color: 'error' });
      setOpenDialog(true);
      return;
    }

    if (Object.keys(adSpendData).length === 0) {
      setAlert({ show: true, message: 'Ad Spend Data is missing. Please ensure the time frame is selected and data is loaded.', color: 'error' });
      setOpenDialog(true);
      return;
    }

    if (!modelResults || !modelResults.inputs) {
      setAlert({ show: true, message: 'Model results data is missing. Please ensure the model results are loaded.', color: 'error' });
      setOpenDialog(true);
      return;
    }

    setIsOptimizing(true);
    setAlert({ show: false, message: '', color: '' });

    // Prepare the optimization_percentages from constraints
    const optimization_percentages = {};
    constraints.forEach(c => {
      let avgPercent = 0;
      if (c.latestSpend !== 0) {
        const minPercent = ((c.minAmount - c.latestSpend) / c.latestSpend) * 100;
        const maxPercent = ((c.maxAmount - c.latestSpend) / c.latestSpend) * 100;
        avgPercent = (minPercent + maxPercent) / 2;
      }
      if (!isFinite(avgPercent)) {
        avgPercent = 0;
      }
      optimization_percentages[c.name] = avgPercent;
    });

    const { inputs, ...resultsData } = modelResults || {};

    const payload = {
      brand_name: selectedBrand.brand_name,
      payload: {
          scenario_id: scenario.id || scenario.scenario || uuidv4(),
          optimization_percentages: optimization_percentages,
          constraints: constraints,  // Include constraints here
          start_week: 1,
          end_week: numPeriods, // Use numPeriods
          num_periods: numPeriods, // Include num_periods
      },
      results_data: resultsData || {},
      inputs_data: inputs || {},
    };

    // Define API URL
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/scenarios/run_optimization/`;

    try {
      const response = await axios.post(fullUrl, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.accessToken}`,
        },
      });

      if (response.status === 200 && response.data) {
        setOptimizationResult(response.data);
        setAlert({ show: true, message: 'Optimization completed successfully!', color: 'success' });
      } else {
        throw new Error('Optimization failed. Please check your inputs.');
      }
    } catch (error) {
      console.error('Error during optimization:', error);
      const errorMessage = error.response?.data?.detail || 'An unexpected error occurred during optimization.';
      setAlert({ show: true, message: errorMessage, color: 'error' });
    } finally {
      setIsOptimizing(false);
    }
  };

  useEffect(() => {
    const fetchModelResults = async () => {
      if (!scenarioModelType) {
        return;
      }

      // Construct query parameters
      const queryParams = new URLSearchParams({
        brand_name: selectedBrand.brand_name,
        refresh_name: selectedRefresh.refresh_name,
        model_type: scenarioModelType,
        user_email: user_email,
        window_start: activeBudgetTimingStart ? activeBudgetTimingStart.toISOString().split('T')[0] : '',
        window_end: activeBudgetTimingEnd ? activeBudgetTimingEnd.toISOString().split('T')[0] : '',
      }).toString();

      // Define API URL
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const fullUrl = `${baseUrl}/scenarios/get_model_results/?${queryParams}`;

      try {
        const response = await axios.get(fullUrl, {
          headers: {
            "Authorization": `Bearer ${auth.accessToken}`
          },
        });

        const data = response.data.data_results;
        const dataInputs = response.data.data_inputs;

        setModelResults({ ...data, inputs: dataInputs });

        // Aggregate data from all models
        const modelKeys = Object.keys(dataInputs.models);
        if (modelKeys.length === 0) {
          console.warn('No models found in data_inputs.');
          setPaidMediaColumns([]);
          setDateVar('');
          setDates([]);
          setMinDate(null);
          setMaxDate(null);
          return;
        }

        // Initialize aggregates
        let aggregatedPaidMediaSpends = new Set();
        let aggregatedDateVars = new Set();

        modelKeys.forEach(modelKey => {
          const modelRobyn = dataInputs.models[modelKey]?.robyn;
          if (modelRobyn) {
            // Aggregate paid_media_spends
            if (modelRobyn.paid_media_spends) {
              modelRobyn.paid_media_spends.forEach(spend => aggregatedPaidMediaSpends.add(spend));
            } else {
              console.warn(`'paid_media_spends' not found in model ${modelKey}.`);
            }

            // Aggregate date_var
            if (modelRobyn.date_var) {
              aggregatedDateVars.add(modelRobyn.date_var);
            } else {
              console.warn(`'date_var' not found in model ${modelKey}.`);
            }
          } else {
            console.warn(`'robyn' key not found in model ${modelKey}.`);
          }
        });

        // Convert Sets to Arrays
        const aggregatedPaidMediaSpendsArray = Array.from(aggregatedPaidMediaSpends);
        const aggregatedDateVarsArray = Array.from(aggregatedDateVars);

        // Ensure consistency across models
        if (aggregatedDateVarsArray.length !== 1) {
          console.warn('Multiple or no date_var found across models. Expected exactly one date_var.');
          setDateVar('');
          setDates([]);
          setMinDate(null);
          setMaxDate(null);
        } else {
          const currentDateVar = aggregatedDateVarsArray[0];
          setDateVar(currentDateVar);

          // Extract dates from wide_data using date_var
          if (dataInputs.wide_data[currentDateVar]) {
            const parsedDates = dataInputs.wide_data[currentDateVar].map(dateStr => parseDate(dateStr));
            setDates(parsedDates);

            // Set min and max dates
            setMinDate(parsedDates[0]);
            setMaxDate(parsedDates[parsedDates.length - 1]);
          } else {
            console.warn(`Date variable ${currentDateVar} not found in wide_data.`);
            setDates([]);
            setMinDate(null);
            setMaxDate(null);
          }
        }

        // Set paid media columns
        setPaidMediaColumns(aggregatedPaidMediaSpendsArray);

      } catch (error) {
        console.error('Error getting model results:', error);
        const errorMessage = error.response?.data?.detail || 'An error occurred while fetching model results.';
        setAlert({ show: true, message: errorMessage, color: 'error' });
        setOpenDialog(true);
      }
    };

    // Fetch model results if brand, refresh, and model type are selected
    if (selectedBrand && selectedRefresh && scenarioModelType) {
      fetchModelResults();
    }

    // Notify parent component of scenario updates
    if (onUpdateScenario) {
      onUpdateScenario({
        ...scenario,
        scenario: scenarioName,
        description: scenarioDescription,
        scenarioGoal: scenarioObjective,
        optimizationGoal: optimizationGoal,
        budgetTimingStart: activeBudgetTimingStart,
        budgetTimingEnd: activeBudgetTimingEnd,
      });
    }
  }, [
    selectedBrand,
    selectedRefresh,
    scenarioModelType,
    activeBudgetTimingStart,
    activeBudgetTimingEnd,
    scenarioName,
    scenarioDescription,
    scenarioObjective,
    optimizationGoal,
    user_email,
    auth.accessToken,
    scenario,
    onUpdateScenario,
  ]);

  // Effect to recalculate adSpendData when relevant data changes
  useEffect(() => {
    if (modelResults && modelResults.inputs && modelResults.inputs.wide_data && dateVar) {
      const wideData = modelResults.inputs.wide_data;
      if (wideData[dateVar]) {
        const dateArray = wideData[dateVar].map(dateStr => parseDate(dateStr));
        calculateAdSpendData(wideData, dateArray, activeBudgetTimingStart, activeBudgetTimingEnd);
      } else {
        console.warn(`Date variable ${dateVar} not found in wide_data.`);
      }
    }
  }, [modelResults, dateVar, activeBudgetTimingStart, activeBudgetTimingEnd, paidMediaColumns]);

  return (
    <>
      {/* Alert Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{alert.color === 'success' ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
          {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary" sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)', } }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main Layout */}
      <DashboardLayout>
        <DashboardNavbar />
        <AppBar position="static">
          <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
            <Tab
              label={`${scenario.scenario} Details`}
              icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>edit_calendar</Icon>}
              sx={tabStyle(0)}
            />
          </Tabs>
        </AppBar>

        {/* Breadcrumbs */}
        <MDBox sx={{ p: 2, pl: 1, mt: 1 }}>
          <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
        </MDBox>

        <Card>
          <Box>
            {/* Inner Tabs for Name & Settings, Constraints, Results */}
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Scenario Detail Tabs">
              <Tab
                label={<Typography variant="h5">Name & Settings</Typography>}
              />
              <Tab
                label={<Typography variant="h5">Constraints</Typography>}
              />
              <Tab
                label={<Typography variant="h5">Results</Typography>}
              />
            </Tabs>

            {/* Tab Content */}
            {selectedTab === 0 && (
              <Box padding={2}>
                <NameAndSettings
                  scenarioName={scenarioName}
                  scenarioDescription={scenarioDescription}
                  scenarioObjective={scenarioObjective}
                  optimizationGoal={optimizationGoal}
                  refreshName={selectedRefresh?.refresh_name || "No Refresh Selected"} // Safe access with fallback
                  scenarioModelType={scenarioModelType} // Pass scenarioModelType
                  budgetTimingStart={activeBudgetTimingStart}
                  budgetTimingEnd={activeBudgetTimingEnd}
                  setBudgetTimingStart={setActiveBudgetTimingStart}
                  setBudgetTimingEnd={setActiveBudgetTimingEnd}
                  handleEntireDataset={handleEntireDataset} // Pass the handler
                  minDate={minDate} // Pass minDate
                  maxDate={maxDate} // Pass maxDate
                  dates={dates} // Pass dates array
                />
              </Box>
            )}

            {selectedTab === 1 && (
              <Box padding={2}>
                <Constraints
                  constraints={constraints}
                  setConstraints={setConstraints}
                  totalBudget={totalBudget}
                  setTotalBudget={setTotalBudget}
                  budgetTimingStart={activeBudgetTimingStart}
                  budgetTimingEnd={activeBudgetTimingEnd}
                  setBudgetTimingStart={setActiveBudgetTimingStart}
                  setBudgetTimingEnd={setActiveBudgetTimingEnd}
                  modelType={scenarioModelType}
                  adSpendData={adSpendData} // Pass Ad Spend data
                  paidMediaColumns={paidMediaColumns} // Pass paidMediaColumns
                  onRunOptimization={handleRunOptimization} // Pass the handler
                  isOptimizing={isOptimizing} // Pass optimizing state
                />
              </Box>
            )}

            {selectedTab === 2 && (
              <Box padding={2}>
                <Results
                  optimizationResult={optimizationResult}
                />
              </Box>
            )}

            {/* Scenario Tabs Navigation */}
            <ScenarioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              handleBackToScenarios={handleBackToScenarios}

            />
          </Box>
        </Card>
        <br />
        <Footer />
      </DashboardLayout>
    </>
  )
}

export default ScenarioDetails;

import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppBar,Tabs, Tab, Icon } from '@mui/material';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import { useBrand } from 'layouts/utils/BrandContext';
import Footer from "assets/Footer";
import Brands from './components/Brands';
import BrandDetails from './components/BrandDetails';
import RefreshDetails from './components/RefreshDetails';
import DataConnectionTab from 'layouts/data-hub/components/DataConnectionTab';
import MDBox from "components/MDBox";
import DataConfiguration from 'layouts/data-configuration/components/DataConfiguration';

function BrandManager() {
    const { selectedBrand, selectedRefresh } = useBrand();
    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    };
    const tabStyle = (index) => ({
        border: tabValue === index ? '2px solid #247EEA' : 'none'
    });

    const [activeStep, setActiveStep] = useState(0);



    return (
        <DashboardLayout>
            <MDBox pb={2}>
            </MDBox>
             <DashboardNavbar />
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                    <Tab
                        label="Brands Setup"
                        icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>add_task</Icon>}
                        style={tabStyle(0)}
                        sx={{
                                backgroundColor: tabValue === 0 ? '#E3F2FD' : 'transparent',
                                border: tabValue === 0 ? '2px solid #247EEA' : 'none'
                         }}
                    />
                </Tabs>
              <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
            </AppBar>
            {tabValue === 0 && (
                <Routes>
                    <Route path="/" element={<Brands />} />
                    <Route path="/:brandName" element={<BrandDetails />} />
                    <Route path="/:brandName/:refreshName/data-connection" element={<DataConnectionTab />} />
                    <Route path="/:brandName/:refreshName/data-configuration" element={<DataConfiguration />} />
                </Routes>
            )}
            <br/>
            <Footer />
        </DashboardLayout>
    );
}

export default BrandManager;

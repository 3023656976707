import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import { useBrand } from 'layouts/utils/BrandContext';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import Chat from 'layouts/ai-agent/components/Chat';

function AIAgent() {
  const { loadContext, selectedBrand, selectedRefresh } = useBrand();
  const { brandName, refreshName } = useParams();
  const location = useLocation();
  const initialState = location.state ? location.state.state : {};
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  useEffect(() => {
      const fetchContext = async () => {
        if (brandName && !selectedBrand) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
  }, [brandName, refreshName, loadContext]);

  const navigate = useNavigate();

 return (
  <DashboardLayout>
      <DashboardNavbar />
      <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
        <Chat />
      <Footer />
    </DashboardLayout>
  );
}

export default AIAgent;

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DefaultNavbarLink from "assets/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "assets/Navbars/DefaultNavbar/DefaultNavbarMobile";
import breakpoints from "assets/theme/base/breakpoints";
import { useMaterialUIController } from "context";
import { useAuthInfo } from "@propelauth/react";
import { useBrand } from 'layouts/utils/BrandContext';

function DefaultNavbar({ transparent, light, action }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);
  const userRole = useAuthInfo().orgHelper.getOrgs()[0].userAssignedRole;
  const { selectedBrand, selectedRefresh } = useBrand();

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={3}
        mx={2}
        width="calc(103%)"
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MDBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          <DefaultNavbarLink icon="flag" name= "Starter&nbsp;Guide" route={`/starter-guide/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/step`} light={light} />
          <DefaultNavbarLink icon="category" name= "Brand&nbsp;Manager" route="/brand-manager" light={light} />
          <DefaultNavbarLink icon="device_hub" name= "Data&nbsp;Hub" route={`/data-hub/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/data-connection`} light={light} />
          <DefaultNavbarLink icon="checklist" name= "Data&nbsp;Validation" route={`/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/sales`} light={light} />
          <DefaultNavbarLink icon="model_training" name="Modeling" route={`/modeling/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelingSetup`} light={light} />
          <DefaultNavbarLink icon="insights" name={`Insights`} route={`/ai-insights/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/exec`} light={light} />
          <DefaultNavbarLink icon="edit_calendar" name="Scenario&nbsp;Planner" route={`/scenario-planner/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}`} light={light} />
          {userRole !== "Trial"  && (
             <>
                  <DefaultNavbarLink icon="present_to_all" name="Deck&nbsp;Builder" route={`/insight-deck-builder/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/deckBuilder`} light={light} />
             </>
          )}
          <DefaultNavbarLink icon="support_agent" name="AI&nbsp;Agent" route="/ai-agent" light={light} />
          <DefaultNavbarLink icon="person" name="Account" route="/account" light={light} />
        </MDBox>
        {action &&
          (action.type === "internal" ? (
            <MDBox display={{ xs: "none", lg: "inline-block" }}>
              <MDButton
                component={Link}
                to={action.route}
                variant="gradient"
                color={action.color ? action.color : "info"}
                size="small"
              >
                {action.label}
              </MDButton>
            </MDBox>
          ) : (
            <MDBox display={{ xs: "none", lg: "inline-block" }}>
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={action.color ? action.color : "info"}
                size="small"
                sx={{ mt: -0.3 }}
              >
                {action.label}
              </MDButton>
            </MDBox>
          ))}
        <MDBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </MDBox>
      </MDBox>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from 'layouts/utils/BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, adsChannels, theme) {
  return {
    fontWeight:
      adsChannels.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AdsChannels({ ads_channels = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(ads_channels)) {
    ads_channels = [];
  }
  const filterValidChannels = (channels) => Array.isArray(channels) ? channels.filter(channel => ads_channels.includes(channel)) : [];

  const [adsChannels, setAdsChannels] = useState(() => {
    const initialChannels = configurationDataUserSelection?.adsChannels && configurationDataUserSelection.adsChannels.length > 0 ? configurationDataUserSelection.adsChannels : [];
    return filterValidChannels(initialChannels.length ? initialChannels : ads_channels.length ? ads_channels : []);
  });

  useEffect(() => {
    const initialAdsChannels = configurationDataUserSelection?.adsChannels && configurationDataUserSelection.adsChannels.length > 0 ? configurationDataUserSelection.adsChannels : ads_channels.length ? ads_channels : [];
    const validInitialAdsChannels = filterValidChannels(initialAdsChannels);
    setAdsChannels(validInitialAdsChannels);
    updateConfigurationDataUserSelection('adsChannels', validInitialAdsChannels);
  }, [ads_channels, configurationDataUserSelection?.adsChannels, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = Array.isArray(value) ? value : value.split(',');
    const validValues = filterValidChannels(newValue);
    setAdsChannels(validValues);
    updateConfigurationDataUserSelection('adsChannels', validValues);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="ads-channels-label">Advertising Variables</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="ads-channels-label"
        id="multiple-ads-channels"
        multiple
        value={adsChannels.length > 0 ? adsChannels : []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-ads-channels" label="Advertising Variables" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {ads_channels.length > 0 ? (
          ads_channels.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, adsChannels, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

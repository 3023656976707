import { useState } from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';

import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';

const SignUp = () => {
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    const displayAlert = (type, message) => {
        setAlert({ show: true, type: type, message: message });
    };

    // Function to close the alert
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ ...alert, show: false });
    };

    const renderAlert = () => (
        <Snackbar
            open={alert.show}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: '100%' }}>
                {alert.message}
            </Alert>
        </Snackbar>
    );

    const [country, setCountry] = useState('');
    const handleCountryChange = (event) => { setCountry(event.target.value); };

    const [industry, setIndustry] = useState('');
    const handleIndustryChange = (event) => { setIndustry(event.target.value); };

    const [state, setState] = useState('');
    const handleStateChange = (event) => { setState(event.target.value); };

    // Set the initial state of the subscribe checkbox to true
    const [subscribe, setSubscribe] = useState(true);
    const handleSubscribeChange = (event) => {
        setSubscribe(event.target.checked);
    };

    // Email validation and Tooltip state
    const [setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState('');

    const validateEmail = (email) => {
        const emailRegex = /^(([^<>()\[\],;:\s@"]+(\.[^<>()\[\],;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        //setEmail(inputEmail);
        if (!validateEmail(inputEmail) && inputEmail.length > 0) {
            setEmailError(true);
            setEmailHelperText('Please enter a valid email');
        } else {
            setEmailError(false);
            setEmailHelperText('');
        }
    };

    const handleRegister = async (event) => {
        event.preventDefault(); // Prevents the default form submission behavior

        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/registration/send-email`;
        try {
            const name    = document.getElementById("name").value
            const email   = document.getElementById("email").value
            const company = document.getElementById("company").value
            const title   = document.getElementById("title").value

            // Check if any of the required fields are empty
            if (!name.trim() || !email.trim()  || !company.trim() || !title.trim() || !country.trim()) {
                displayAlert("warning","Please fill in all required fields.");
                return;
            }

            const formData = {
                name: name,
                email: email,
                company: company,
                title: title,
                country: country,
                state: state,
                industry: industry,
                subscribe: subscribe,
            };

            console.log("Backend API url: ", fullUrl);

            const response = await axios.post(fullUrl, formData, {
                headers: {
                    'Content-Type': 'application/json',

                }
            });
            console.log("response: ", response.data);
            displayAlert("success", "Registration Successful. An email has been sent.");

            // Clear the form fields after successful submission
            document.getElementById("name").value = '';
            document.getElementById("email").value = '';
            document.getElementById("company").value = '';
            document.getElementById("title").value = '';
            setCountry('');
            setIndustry('');
            setState('');
            setSubscribe(true); // Reset to default checked state
        } catch (error) {
            console.error("There was an error sending the email", error);
            displayAlert("error","There was an error sending the email", error);
        }
    };

    return (
      <div>
        <div className="container-signup">
         <Box component="form" sx={{'& .MuiTextField-root': { m:1, width:'35ch' },}}>
            <h1>New Registration</h1>
            <TextField id="name" required label="Name" variant="outlined" size="small"/>
            <Tooltip title={emailHelperText} placement="right" open={emailError} >
                <TextField
                    id="email"
                    required
                    label="Email"
                    variant="outlined"
                    size="small"
                    onChange={handleEmailChange}
                    error={emailError}
                />
            </Tooltip>
            <TextField id="company" required label="Company" variant="outlined" size="small"/>
            <TextField id="title" required label="Job Tile" variant="outlined" size="small"/>
            <FormControl  sx={{ m:1, width:'35ch' }} required variant="outlined" size="small">
                <InputLabel id="country-label">Country</InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      value={country}
                      label="Country"
                      onChange={handleCountryChange}
                    >
                        <MenuItem value={"United States"}>United States</MenuItem>
                        <MenuItem value={"Canada"}>Canada</MenuItem>
                        <MenuItem value={"United Kingdom"}>United Kingdom</MenuItem>
                        <MenuItem value={"None"}>----</MenuItem>
                        <MenuItem value={"Afghanistan"}>Afghanistan</MenuItem>
                        <MenuItem value={"Åland Islands"}>Åland Islands</MenuItem>
                        <MenuItem value={"Albania"}>Albania</MenuItem>
                        <MenuItem value={"Algeria"}>Algeria</MenuItem>
                        <MenuItem value={"American Samoa"}>American Samoa</MenuItem>
                        <MenuItem value={"Andorra"}>Andorra</MenuItem>
                        <MenuItem value={"Angola"}>Angola</MenuItem>
                        <MenuItem value={"Anguilla"}>Anguilla</MenuItem>
                        <MenuItem value={"Antarctica"}>Antarctica</MenuItem>
                        <MenuItem value={"Antigua and Barbuda"}>Antigua and Barbuda</MenuItem>
                        <MenuItem value={"Argentina"}>Argentina</MenuItem>
                        <MenuItem value={"Armenia"}>Armenia</MenuItem>
                        <MenuItem value={"Aruba"}>Aruba</MenuItem>
                        <MenuItem value={"Australia"}>Australia</MenuItem>
                        <MenuItem value={"Austria"}>Austria</MenuItem>
                        <MenuItem value={"Azerbaijan"}>Azerbaijan</MenuItem>
                        <MenuItem value={"Bahrain"}>Bahrain</MenuItem>
                        <MenuItem value={"Bahamas"}>Bahamas</MenuItem>
                        <MenuItem value={"Bangladesh"}>Bangladesh</MenuItem>
                        <MenuItem value={"Barbados"}>Barbados</MenuItem>
                        <MenuItem value={"Belarus"}>Belarus</MenuItem>
                        <MenuItem value={"Belgium"}>Belgium</MenuItem>
                        <MenuItem value={"Belize"}>Belize</MenuItem>
                        <MenuItem value={"Benin"}>Benin</MenuItem>
                        <MenuItem value={"Bermuda"}>Bermuda</MenuItem>
                        <MenuItem value={"Bhutan"}>Bhutan</MenuItem>
                        <MenuItem value={"Bolivia"}>Bolivia</MenuItem>
                        <MenuItem value={"Bonaire, Sint Eustatius and Saba"}>Bonaire, Sint Eustatius and Saba</MenuItem>
                        <MenuItem value={"Bosnia and Herzegovina"}>Bosnia and Herzegovina</MenuItem>
                        <MenuItem value={"Botswana"}>Botswana</MenuItem>
                        <MenuItem value={"Bouvet Island"}>Bouvet Island</MenuItem>
                        <MenuItem value={"Brazil"}>Brazil</MenuItem>
                        <MenuItem value={"British Indian Ocean Territory"}>British Indian Ocean Territory</MenuItem>
                        <MenuItem value={"Brunei Darussalam"}>Brunei Darussalam</MenuItem>
                        <MenuItem value={"Bulgaria"}>Bulgaria</MenuItem>
                        <MenuItem value={"Burkina Faso"}>Burkina Faso</MenuItem>
                        <MenuItem value={"Burundi"}>Burundi</MenuItem>
                        <MenuItem value={"Cambodia"}>Cambodia</MenuItem>
                        <MenuItem value={"Cameroon"}>Cameroon</MenuItem>
                        <MenuItem value={"Cape Verde"}>Cape Verde</MenuItem>
                        <MenuItem value={"Cayman Islands"}>Cayman Islands</MenuItem>
                        <MenuItem value={"Central African Republic"}>Central African Republic</MenuItem>
                        <MenuItem value={"Chad"}>Chad</MenuItem>
                        <MenuItem value={"Chile"}>Chile</MenuItem>
                        <MenuItem value={"China"}>China</MenuItem>
                        <MenuItem value={"Christmas Island"}>Christmas Island</MenuItem>
                        <MenuItem value={"Cocos (Keeling) Islands"}>Cocos (Keeling) Islands</MenuItem>
                        <MenuItem value={"Colombia"}>Colombia</MenuItem>
                        <MenuItem value={"Comoros"}>Comoros</MenuItem>
                        <MenuItem value={"Congo"}>Congo</MenuItem>
                        <MenuItem value={"Cook Islands"}>Cook Islands</MenuItem>
                        <MenuItem value={"Costa Rica"}>Costa Rica</MenuItem>
                        <MenuItem value={"Côte d'Ivoire"}>Côte d'Ivoire</MenuItem>
                        <MenuItem value={"Croatia"}>Croatia</MenuItem>
                        <MenuItem value={"Cuba"}>Cuba</MenuItem>
                        <MenuItem value={"Curaçao"}>Curaçao</MenuItem>
                        <MenuItem value={"Cyprus"}>Cyprus</MenuItem>
                        <MenuItem value={"Czech Republic"}>Czech Republic</MenuItem>
                        <MenuItem value={"Denmark"}>Denmark</MenuItem>
                        <MenuItem value={"Djibouti"}>Djibouti</MenuItem>
                        <MenuItem value={"Dominica"}>Dominica</MenuItem>
                        <MenuItem value={"Dominican Republic"}>Dominican Republic</MenuItem>
                        <MenuItem value={"Ecuador"}>Ecuador</MenuItem>
                        <MenuItem value={"Egypt"}>Egypt</MenuItem>
                        <MenuItem value={"El Salvador"}>El Salvador</MenuItem>
                        <MenuItem value={"Equatorial Guinea"}>Equatorial Guinea</MenuItem>
                        <MenuItem value={"Eritrea"}>Eritrea</MenuItem>
                        <MenuItem value={"Estonia"}>Estonia</MenuItem>
                        <MenuItem value={"Ethiopia"}>Ethiopia</MenuItem>
                        <MenuItem value={"Falkland Islands (Malvinas)"}>Falkland Islands (Malvinas)</MenuItem>
                        <MenuItem value={"Faroe Islands"}>Faroe Islands</MenuItem>
                        <MenuItem value={"Fiji"}>Fiji</MenuItem>
                        <MenuItem value={"Finland"}>Finland</MenuItem>
                        <MenuItem value={"France"}>France</MenuItem>
                        <MenuItem value={"French Guiana"}>French Guiana</MenuItem>
                        <MenuItem value={"French Polynesia"}>French Polynesia</MenuItem>
                        <MenuItem value={"French Southern Territories"}>French Southern Territories</MenuItem>
                        <MenuItem value={"Gabon"}>Gabon</MenuItem>
                        <MenuItem value={"Gambia"}>Gambia</MenuItem>
                        <MenuItem value={"Georgia"}>Georgia</MenuItem>
                        <MenuItem value={"Germany"}>Germany</MenuItem>
                        <MenuItem value={"Ghana"}>Ghana</MenuItem>
                        <MenuItem value={"Gibraltar"}>Gibraltar</MenuItem>
                        <MenuItem value={"Greece"}>Greece</MenuItem>
                        <MenuItem value={"Greenland"}>Greenland</MenuItem>
                        <MenuItem value={"Grenada"}>Grenada</MenuItem>
                        <MenuItem value={"Guadeloupe"}>Guadeloupe</MenuItem>
                        <MenuItem value={"Guam"}>Guam</MenuItem>
                        <MenuItem value={"Guatemala"}>Guatemala</MenuItem>
                        <MenuItem value={"Guernsey"}>Guernsey</MenuItem>
                        <MenuItem value={"Guinea"}>Guinea</MenuItem>
                        <MenuItem value={"Guinea-Bissau"}>Guinea-Bissau</MenuItem>
                        <MenuItem value={"Guyana"}>Guyana</MenuItem>
                        <MenuItem value={"Haiti"}>Haiti</MenuItem>
                        <MenuItem value={"Heard Island and McDonald Islands"}>Heard Island and McDonald Islands</MenuItem>
                        <MenuItem value={"Holy See (Vatican City State)"}>Holy See (Vatican City State)</MenuItem>
                        <MenuItem value={"Honduras"}>Honduras</MenuItem>
                        <MenuItem value={"Hong Kong"}>Hong Kong</MenuItem>
                        <MenuItem value={"Hungary"}>Hungary</MenuItem>
                        <MenuItem value={"Iceland"}>Iceland</MenuItem>
                        <MenuItem value={"India"}>India</MenuItem>
                        <MenuItem value={"Indonesia"}>Indonesia</MenuItem>
                        <MenuItem value={"Iran"}>Iran</MenuItem>
                        <MenuItem value={"Iraq"}>Iraq</MenuItem>
                        <MenuItem value={"Ireland"}>Ireland</MenuItem>
                        <MenuItem value={"Isle of Man"}>Isle of Man</MenuItem>
                        <MenuItem value={"Israel"}>Israel</MenuItem>
                        <MenuItem value={"Italy"}>Italy</MenuItem>
                        <MenuItem value={"Jamaica"}>Jamaica</MenuItem>
                        <MenuItem value={"Japan"}>Japan</MenuItem>
                        <MenuItem value={"Jersey"}>Jersey</MenuItem>
                        <MenuItem value={"Jordan"}>Jordan</MenuItem>
                        <MenuItem value={"Kazakhstan"}>Kazakhstan</MenuItem>
                        <MenuItem value={"Kenya"}>Kenya</MenuItem>
                        <MenuItem value={"Kiribati"}>Kiribati</MenuItem>
                        <MenuItem value={"North Korea"}>North Korea</MenuItem>
                        <MenuItem value={"South Korea"}>South Korea</MenuItem>
                        <MenuItem value={"Kuwait"}>Kuwait</MenuItem>
                        <MenuItem value={"Kyrgyzstan"}>Kyrgyzstan</MenuItem>
                        <MenuItem value={"Lao People's Democratic Republic"}>Lao People's Democratic Republic</MenuItem>
                        <MenuItem value={"Latvia"}>Latvia</MenuItem>
                        <MenuItem value={"Lebanon"}>Lebanon</MenuItem>
                        <MenuItem value={"Lesotho"}>Lesotho</MenuItem>
                        <MenuItem value={"Liberia"}>Liberia</MenuItem>
                        <MenuItem value={"Libya"}>Libya</MenuItem>
                        <MenuItem value={"Liechtenstein"}>Liechtenstein</MenuItem>
                        <MenuItem value={"Lithuania"}>Lithuania</MenuItem>
                        <MenuItem value={"Luxembourg"}>Luxembourg</MenuItem>
                        <MenuItem value={"Macao"}>Macao</MenuItem>
                        <MenuItem value={">Macedonia, the Former Yugoslav Republic of"}>Macedonia, the Former Yugoslav Republic of</MenuItem>
                        <MenuItem value={"Madagascar"}>Madagascar</MenuItem>
                        <MenuItem value={"Malawi"}>Malawi</MenuItem>
                        <MenuItem value={"Malaysia"}>Malaysia</MenuItem>
                        <MenuItem value={"Maldives"}>Maldives</MenuItem>
                        <MenuItem value={"Mali"}>Mali</MenuItem>
                        <MenuItem value={"Malta"}>Malta</MenuItem>
                        <MenuItem value={"Marshall Islands"}>Marshall Islands</MenuItem>
                        <MenuItem value={"Martinique"}>Martinique</MenuItem>
                        <MenuItem value={"Mauritania"}>Mauritania</MenuItem>
                        <MenuItem value={"Mauritius"}>Mauritius</MenuItem>
                        <MenuItem value={"Mayotte"}>Mayotte</MenuItem>
                        <MenuItem value={"Mexico"}>Mexico</MenuItem>
                        <MenuItem value={"Micronesia, Federated States of"}>Micronesia, Federated States of</MenuItem>
                        <MenuItem value={"Moldova, Republic of"}>Moldova, Republic of</MenuItem>
                        <MenuItem value={"Monaco"}>Monaco</MenuItem>
                        <MenuItem value={"Mongolia"}>Mongolia</MenuItem>
                        <MenuItem value={"Montenegro"}>Montenegro</MenuItem>
                        <MenuItem value={"Montserrat"}>Montserrat</MenuItem>
                        <MenuItem value={"Morocco"}>Morocco</MenuItem>
                        <MenuItem value={"Mozambique"}>Mozambique</MenuItem>
                        <MenuItem value={"Myanmar"}>Myanmar</MenuItem>
                        <MenuItem value={"Namibia"}>Namibia</MenuItem>
                        <MenuItem value={"Nauru"}>Nauru</MenuItem>
                        <MenuItem value={"Nepal"}>Nepal</MenuItem>
                        <MenuItem value={"Netherlands"}>Netherlands</MenuItem>
                        <MenuItem value={"ew Caledonia"}>New Caledonia</MenuItem>
                        <MenuItem value={"New Zealand"}>New Zealand</MenuItem>
                        <MenuItem value={"Nicaragua"}>Nicaragua</MenuItem>
                        <MenuItem value={"Niger"}>Niger</MenuItem>
                        <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
                        <MenuItem value={"Niue"}>Niue</MenuItem>
                        <MenuItem value={"Norfolk Island"}>Norfolk Island</MenuItem>
                        <MenuItem value={"Northern Mariana Islands"}>Northern Mariana Islands</MenuItem>
                        <MenuItem value={"Norway"}>Norway</MenuItem>
                        <MenuItem value={"Oman"}>Oman</MenuItem>
                        <MenuItem value={"Pakistan"}>Pakistan</MenuItem>
                        <MenuItem value={"Palau"}>Palau</MenuItem>
                        <MenuItem value={"Palestine"}>Palestine</MenuItem>
                        <MenuItem value={"Panama"}>Panama</MenuItem>
                        <MenuItem value={"Papua New Guinea"}>Papua New Guinea</MenuItem>
                        <MenuItem value={"Paraguay"}>Paraguay</MenuItem>
                        <MenuItem value={"Peru"}>Peru</MenuItem>
                        <MenuItem value={"Philippines"}>Philippines</MenuItem>
                        <MenuItem value={"Pitcairn"}>Pitcairn</MenuItem>
                        <MenuItem value={"Poland"}>Poland</MenuItem>
                        <MenuItem value={"Portugal"}>Portugal</MenuItem>
                        <MenuItem value={"Puerto Rico"}>Puerto Rico</MenuItem>
                        <MenuItem value={"Qatar"}>Qatar</MenuItem>
                        <MenuItem value={"Réunion"}>Réunion</MenuItem>
                        <MenuItem value={"Romania"}>Romania</MenuItem>
                        <MenuItem value={"Russian Federation"}>Russian Federation</MenuItem>
                        <MenuItem value={"Rwanda"}>Rwanda</MenuItem>
                        <MenuItem value={"Saint Barthélemy"}>Saint Barthélemy</MenuItem>
                        <MenuItem value={"Saint Helena, Ascension and Tristan da Cunha"}>Saint Helena, Ascension and Tristan da Cunha</MenuItem>
                        <MenuItem value={"Saint Kitts and Nevis"}>Saint Kitts and Nevis</MenuItem>
                        <MenuItem value={"Saint Lucia"}>Saint Lucia</MenuItem>
                        <MenuItem value={"Saint Martin (French part)"}>Saint Martin (French part)</MenuItem>
                        <MenuItem value={"Saint Pierre and Miquelon"}>Saint Pierre and Miquelon</MenuItem>
                        <MenuItem value={"Saint Vincent and the Grenadines"}>Saint Vincent and the Grenadines</MenuItem>
                        <MenuItem value={"Samoa"}>Samoa</MenuItem>
                        <MenuItem value={"San Marino"}>San Marino</MenuItem>
                        <MenuItem value={"Sao Tome and Principe"}>Sao Tome and Principe</MenuItem>
                        <MenuItem value={"Saudi Arabia"}>Saudi Arabia</MenuItem>
                        <MenuItem value={"Senegal"}>Senegal</MenuItem>
                        <MenuItem value={"Serbia"}>Serbia</MenuItem>
                        <MenuItem value={"Seychelles"}>Seychelles</MenuItem>
                        <MenuItem value={"Sierra Leone"}>Sierra Leone</MenuItem>
                        <MenuItem value={"Singapore"}>Singapore</MenuItem>
                        <MenuItem value={">Sint Maarten (Dutch part"}>Sint Maarten (Dutch part)</MenuItem>
                        <MenuItem value={"Slovakia"}>Slovakia</MenuItem>
                        <MenuItem value={"Slovenia"}>Slovenia</MenuItem>
                        <MenuItem value={"Solomon Islands"}>Solomon Islands</MenuItem>
                        <MenuItem value={"Somalia"}>Somalia</MenuItem>
                        <MenuItem value={"South Africa"}>South Africa</MenuItem>
                        <MenuItem value={"South Georgia and the South Sandwich Islands"}>South Georgia and the South Sandwich Islands</MenuItem>
                        <MenuItem value={"South Sudan"}>South Sudan</MenuItem>
                        <MenuItem value={"Spain"}>Spain</MenuItem>
                        <MenuItem value={"Sri Lanka"}>Sri Lanka</MenuItem>
                        <MenuItem value={"Sudan"}>Sudan</MenuItem>
                        <MenuItem value={"Suriname"}>Suriname</MenuItem>
                        <MenuItem value={"Svalbard and Jan Mayen"}>Svalbard and Jan Mayen</MenuItem>
                        <MenuItem value={"Swaziland"}>Swaziland</MenuItem>
                        <MenuItem value={"Sweden"}>Sweden</MenuItem>
                        <MenuItem value={"Switzerland"}>Switzerland</MenuItem>
                        <MenuItem value={"Syrian Arab Republic"}>Syrian Arab Republic</MenuItem>
                        <MenuItem value={"Taiwan"}>Taiwan</MenuItem>
                        <MenuItem value={"Tajikistan"}>Tajikistan</MenuItem>
                        <MenuItem value={"Tanzania, United Republic of"}>Tanzania, United Republic of</MenuItem>
                        <MenuItem value={"Thailand"}>Thailand</MenuItem>
                        <MenuItem value={"Timor-Leste"}>Timor-Leste</MenuItem>
                        <MenuItem value={"Togo"}>Togo</MenuItem>
                        <MenuItem value={"Tokelau"}>Tokelau</MenuItem>
                        <MenuItem value={"Tonga"}>Tonga</MenuItem>
                        <MenuItem value={"Trinidad and Tobago"}>Trinidad and Tobago</MenuItem>
                        <MenuItem value={"Tunisia"}>Tunisia</MenuItem>
                        <MenuItem value={"Turkey"}>Turkey</MenuItem>
                        <MenuItem value={"Turkmenistan"}>Turkmenistan</MenuItem>
                        <MenuItem value={"Turks and Caicos Islands"}>Turks and Caicos Islands</MenuItem>
                        <MenuItem value={"Tuvalu"}>Tuvalu</MenuItem>
                        <MenuItem value={"Uganda"}>Uganda</MenuItem>
                        <MenuItem value={"Ukraine"}>Ukraine</MenuItem>
                        <MenuItem value={"United Arab Emirates"}>United Arab Emirates</MenuItem>
                        <MenuItem value={"United States Minor Outlying Islands"}>United States Minor Outlying Islands</MenuItem>
                        <MenuItem value={"Uruguay"}>Uruguay</MenuItem>
                        <MenuItem value={"Uzbekistan"}>Uzbekistan</MenuItem>

                        <MenuItem value={"Vanuatu"}>Vanuatu</MenuItem>
                        <MenuItem value={"Venezuela"}>Venezuela</MenuItem>
                        <MenuItem value={"Viet Nam<"}>Viet Nam</MenuItem>
                        <MenuItem value={"Virgin Islands, British"}>Virgin Islands, British</MenuItem>
                        <MenuItem value={"Virgin Islands, U.S."}>Virgin Islands, U.S.</MenuItem>
                        <MenuItem value={"Wallis and Futuna"}>Wallis and Futuna</MenuItem>
                        <MenuItem value={"Western Sahara"}>Western Sahara</MenuItem>
                        <MenuItem value={"Yemen"}>Yemen</MenuItem>
                        <MenuItem value={"Zambia"}>Zambia</MenuItem>
                        <MenuItem value={"Zimbabwe"}>Zimbabwe</MenuItem>
                    </Select>
            </FormControl>
            <FormControl  sx={{ m:1, width:'35ch' }} variant="outlined" size="small">
                <InputLabel id="state-label">State</InputLabel>
                    <Select
                      labelId="state-label"
                      id="state"
                      value={state}
                      label="State"
                      onChange={handleStateChange}
                    >
                     <ListSubheader>- United States -</ListSubheader>
                        <MenuItem value={"Alaska"}>Alaska</MenuItem>
                        <MenuItem value={"Alabama"}>Alabama</MenuItem>
                        <MenuItem value={"Arkansas"}>Arkansas</MenuItem>
                        <MenuItem value={"Arizona"}>Arizona</MenuItem>
                        <MenuItem value={"California"}>California</MenuItem>
                        <MenuItem value={"Colorado"}>Colorado</MenuItem>
                        <MenuItem value={"Connecticut"}>Connecticut</MenuItem>
                        <MenuItem value={"strict of Columbia"}>District of Columbia</MenuItem>
                        <MenuItem value={"Delaware"}>Delaware</MenuItem>
                        <MenuItem value={"Florida"}>Florida</MenuItem>
                        <MenuItem value={"Georgia"}>Georgia</MenuItem>
                        <MenuItem value={"Hawaii"}>Hawaii</MenuItem>
                        <MenuItem value={"Iowa"}>Iowa</MenuItem>
                        <MenuItem value={"Idaho"}>Idaho</MenuItem>
                        <MenuItem value={"Illinois"}>Illinois</MenuItem>
                        <MenuItem value={"Indiana"}>Indiana</MenuItem>
                        <MenuItem value={"Kansas"}>Kansas</MenuItem>
                        <MenuItem value={"Kentucky"}>Kentucky</MenuItem>
                        <MenuItem value={"Louisiana"}>Louisiana</MenuItem>
                        <MenuItem value={"Massachusetts"}>Massachusetts</MenuItem>
                        <MenuItem value={"Maryland"}>Maryland</MenuItem>
                        <MenuItem value={"Maine"}>Maine</MenuItem>
                        <MenuItem value={"Michigan"}>Michigan</MenuItem>
                        <MenuItem value={"Minnesota"}>Minnesota</MenuItem>
                        <MenuItem value={"Missouri"}>Missouri</MenuItem>
                        <MenuItem value={"Mississippi"}>Mississippi</MenuItem>
                        <MenuItem value={"Montana"}>Montana</MenuItem>
                        <MenuItem value={"North Carolina"}>North Carolina</MenuItem>
                        <MenuItem value={"North Dakota"}>North Dakota</MenuItem>
                        <MenuItem value={"Nebraska"}>Nebraska</MenuItem>
                        <MenuItem value={"New Hampshire"}>New Hampshire</MenuItem>
                        <MenuItem value={"New Jersey"}>New Jersey</MenuItem>
                        <MenuItem value={"New Mexico"}>New Mexico</MenuItem>
                        <MenuItem value={"Nevada"}>Nevada</MenuItem>
                        <MenuItem value={"New York<"}>New York</MenuItem>
                        <MenuItem value={"Ohio"}>Ohio</MenuItem>
                        <MenuItem value={"Oklahoma"}>Oklahoma</MenuItem>
                        <MenuItem value={"Oregon"}>Oregon</MenuItem>
                        <MenuItem value={"Pennsylvania"}>Pennsylvania</MenuItem>
                        <MenuItem value={"Rhode Island"}>Rhode Island</MenuItem>
                        <MenuItem value={"South Carolina"}>South Carolina</MenuItem>
                        <MenuItem value={"South Dakota"}>South Dakota</MenuItem>
                        <MenuItem value={"Tennessee"}>Tennessee</MenuItem>
                        <MenuItem value={"Texas"}>Texas</MenuItem>
                        <MenuItem value={"Utah"}>Utah</MenuItem>
                        <MenuItem value={"Virginia"}>Virginia</MenuItem>
                        <MenuItem value={"Vermont"}>Vermont</MenuItem>
                        <MenuItem value={"Washington"}>Washington</MenuItem>
                        <MenuItem value={"Wisconsin"}>Wisconsin</MenuItem>
                        <MenuItem value={">West Virginia"}>West Virginia</MenuItem>
                        <MenuItem value={"Wyoming"}>Wyoming</MenuItem>
                     <ListSubheader>- Canada -</ListSubheader>
                        <MenuItem value={"Alberta"}>Alberta</MenuItem>
                        <MenuItem value={"British Columbia"}>British Columbia</MenuItem>
                        <MenuItem value={"Manitoba"}>Manitoba</MenuItem>
                        <MenuItem value={"New Brunswick"}>New Brunswick</MenuItem>
                        <MenuItem value={"Newfoundland and Labrador"}>Newfoundland and Labrador</MenuItem>
                        <MenuItem value={"Nova Scotia"}>Nova Scotia</MenuItem>
                        <MenuItem value={"Northwest Territories"}>Northwest Territories</MenuItem>
                        <MenuItem value={"Nunavut"}>Nunavut</MenuItem>
                        <MenuItem value={"Ontario"}>Ontario</MenuItem>
                        <MenuItem value={"Prince Edward Island"}>Prince Edward Island</MenuItem>
                        <MenuItem value={"Quebec"}>Quebec</MenuItem>
                        <MenuItem value={"Saskatchewan"}>Saskatchewan</MenuItem>
                        <MenuItem value={"Yukon Territories"}>Yukon Territories</MenuItem>
                     <ListSubheader>- Australia -</ListSubheader>
                        <MenuItem value={"Australian Capital Territory"}>Australian Capital Territory</MenuItem>
                        <MenuItem value={"New South Wales<"}>New South Wales</MenuItem>
                        <MenuItem value={"Northern Territory"}>Northern Territory</MenuItem>
                        <MenuItem value={"Queensland"}>Queensland</MenuItem>
                        <MenuItem value={"South Australia"}>South Australia</MenuItem>
                        <MenuItem value={"Tasmania"}>Tasmania</MenuItem>
                        <MenuItem value={"Victoria"}>Victoria</MenuItem>
                        <MenuItem value={"Western Australia<"}>Western Australia</MenuItem>
                    </Select>
            </FormControl>
            <FormControl  sx={{ m:1, width:'35ch' }}  variant="outlined" size="small">
                <InputLabel id="industry-label">Industry</InputLabel>
                    <Select
                      labelId="industry-label"
                      id="industry"
                      value={industry}
                      label="Industry"
                      onChange={handleIndustryChange}
                    >
                      <MenuItem value=""><em>Please select...</em></MenuItem>
                      <MenuItem value={"Consulting"}>Consulting</MenuItem>
                      <MenuItem value={"Education"}>Education</MenuItem>
                      <MenuItem value={"Entertainment"}>Entertainment</MenuItem>
                      <MenuItem value={"Financial Services"}>Financial Services</MenuItem>
                      <MenuItem value={"Government"}>Government</MenuItem>
                      <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                      <MenuItem value={"Insurance"}>Insurance</MenuItem>
                      <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
                      <MenuItem value={"Media and Communications"}>Media and Communications</MenuItem>
                      <MenuItem value={"Retail"}>Retail</MenuItem>
                      <MenuItem value={"Technology"}>Technology</MenuItem>
                      <MenuItem value={"Transportation"}>Transportation</MenuItem>
                      <MenuItem value={"Utilities"}>Utilities</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
            </FormControl>
            <FormControlLabel className="sign-up-checkbox-text" control={<Checkbox checked={subscribe} onChange={handleSubscribeChange} />} label="Yes! Please email me news and offers for MMM Labs products and services." />
            <div className="signup-text">
               MMM Labs is committed to protecting your privacy. You can find full details in our <a href="tos.txt" target="_blank">Terms of Service</a>.
            </div>
            <div className="register-now">
                <button onClick={handleRegister}>Register Now</button>
            </div>
          </Box>
     </div>
     {renderAlert()}
   </div>
    );
};

export default SignUp;
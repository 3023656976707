import { useState, useEffect } from "react";
import { useAuthInfo } from "@propelauth/react";
import { Tooltip, Card, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, CircularProgress, TextField, IconButton, Icon } from '@mui/material';
import { blue } from '@mui/material/colors';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { lightBlue } from '@mui/material/colors';
import { useBrand } from 'layouts/utils/BrandContext';
import { useParams, useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import axios from 'axios';

function DataReadinessTab({ apiPath = '/data_configuration/check_data_readiness', chartType = 'radialBar', chartHeight = 200, modelPath = '/modeling' }) {
    const { selectedBrand, selectedRefresh, user_email, loadContext } = useBrand();
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [readinessResults, setReadinessResults] = useState(null);
    const { brandName, refreshName } = useParams();
    const navigate = useNavigate();
    const auth = useAuthInfo();

    useEffect(() => {
        const fetchContext = async () => {
            if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
                await loadContext(brandName, refreshName);
            }
        };
        fetchContext();
    }, [brandName, refreshName, loadContext]);

    useEffect(() => {
        if (selectedBrand && selectedRefresh) {
            handleDataReadiness();
        }
    }, [selectedBrand, selectedRefresh]);

    const handleCloseDialog = () => setOpenDialog(false);

    const handleDataReadiness = async () => {
        setLoading(true);
        const inputs_path = `${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/model_inputs.json`;
        const formData = new FormData();
        formData.append('inputs_path', inputs_path);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${apiPath}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            });
            const data = response.data;
            if (!data.validation_error) {
                setReadinessResults(data);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.validation_error || 'An unexpected error occurred';
            setAlert({ show: true, message: errorMessage, color: 'error' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const chartOptions = {
        chart: { type: chartType, offsetY: -20 },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                hollow: { size: '70%' },
                dataLabels: {
                    value: {
                        fontSize: '22px',
                        formatter: (val) => `${val}%`,
                        color: readinessResults?.final_score >= 75 ? '#00e396' :
                            readinessResults?.final_score >= 50 ? '#FFC107' : '#ff4560'
                    }
                }
            }
        },
        fill: {
            colors: readinessResults?.final_score >= 75 ? ['#00e396'] :
                    readinessResults?.final_score >= 50 ? ['#FFC107'] : ['#ff4560']
        }
    };

    const handleModelingSetup = () => {
        if (selectedRefresh.selectedFile) {
            navigate(`${modelPath}/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/modelingSetup`);
        } else {
            setAlert({ show: true, message: 'No file selected for modeling', color: 'error' });
            setOpenDialog(true);
        }
    };

    const handleDataValidation = () => {
        if (selectedRefresh.selectedFile) {
            navigate(`/data-validation/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/sales`);
        } else {
            setAlert({ show: true, message: 'No file selected for modeling', color: 'error' });
            setOpenDialog(true);
        }
    };

    const chartSeries = readinessResults ? [readinessResults.final_score.toFixed(2)] : [0];

    return (
        <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{alert.color === 'success' ? 'Success' : 'Warning'}</DialogTitle>
                <DialogContent>{alert.show && <div dangerouslySetInnerHTML={{ __html: alert.message }} />}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            <MDBox p={3}>
                <MDTypography variant="h3">Data Readiness</MDTypography>
                <Card sx={{ mt: 2, p: 3 }}>
                    {!selectedBrand || !selectedRefresh ? (
                        <MDTypography variant="h6" sx={{ color: 'orange', mt: 1, mb: 1, textAlign: 'center' }}>Please select a Brand and Refresh first in order to view the data readiness.</MDTypography>
                    ) : loading ? (
                        <MDBox sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', minHeight: '200px' }}>
                            <CircularProgress sx={{ mt: 10, color: blue[500] }} />
                        </MDBox>
                    ) : alert.show && alert.color === 'error' ? (
                        <MDTypography variant="h6" sx={{ color: 'orange', mt: 3 }}><div dangerouslySetInnerHTML={{ __html: alert.message }} /></MDTypography>
                    ) : readinessResults ? (
                        <>
                            <MDTypography variant="h5" sx={{ color: blue[700], textAlign: 'center', fontWeight: 'bold', mt: 2 }}>
                                Final Score: {readinessResults.final_score.toFixed(2)}%
                            </MDTypography>
                            <MDBox sx={{ mt: 3 }}>
                                <Chart options={chartOptions} series={chartSeries} type={chartType} height={chartHeight} />
                            </MDBox>
                            <MDBox sx={{ mt: 0 }}>
                                {readinessResults.readiness_results.map(([category, details], index) => (
                                    <Card key={index} sx={{ p: 2, mb: 2 }}>
                                        <MDTypography variant="h5">
                                            {category}
                                            <Tooltip title={details.importance}>
                                                <IconButton size="small"><Icon sx={{ fontSize: 20 }}>help_outline</Icon></IconButton>
                                            </Tooltip>
                                        </MDTypography>
                                        <MDBox sx={{ mt: 1 }}>
                                            {details.messages.map((message, idx) => (
                                                <MDTypography key={idx} variant='normal' sx={{ color: message.type === 'success' ? 'green' : message.type === 'warning' ? 'orange' : 'red' }}>
                                                     {message.text.split('\n').map((line, i) => {
                                                            // If the line contains '- ', render it as a bullet point
                                                            if (line.trim().startsWith('-')) {
                                                                return (
                                                                    <ul key={i} style={{ marginLeft: '20px' }}>
                                                                        <li>{line.replace('-', '').trim()}</li>
                                                                    </ul>
                                                                );
                                                            }
                                                            return <span key={i}>{line}<br /></span>;
                                                      })}
                                                </MDTypography>
                                            ))}
                                        </MDBox>
                                    </Card>
                                ))}
                            </MDBox>
                        </>
                    ) : null}
                    <MDBox sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <MDButton variant="contained" color="dark" onClick={handleDataValidation} sx={{ width: 'auto', mr:2 }}>Data Validation</MDButton>
                        <MDButton variant="contained" color="dark" onClick={handleModelingSetup} sx={{ width: 'auto' }}>Start Modeling</MDButton>
                    </MDBox>
                </Card>
            </MDBox>
        </>
    );
}

export default DataReadinessTab;

import { useCallback } from 'react';
import axios from 'axios';
import { useBrand } from 'layouts/utils/BrandContext';

const useSaveBrandRefreshStepSelection = () => {
	const {user_company, user_email} = useBrand();

    const saveBrandRefreshStepSelection = useCallback(async (brand, refresh, step) => {
        const formData = new FormData();
        formData.append('email', user_email);
        formData.append('company', user_company);
        formData.append('brand_name', brand);
        formData.append('refresh_name', refresh);
        formData.append('current_step', step);

        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/save_last_selected`;
        try {
	        const response = await axios.post(fullUrl, formData, {
		        headers: {
					"Content-Type": "multipart/form-data",
		        }
		    });
            if (response.status === 200) {
                console.log("User context saved successfully.");
            } else {
                console.error("Error saving context:", response.statusText);
            }
        } catch (error) {
            console.error("Error in saveBrandRefreshStepSelection:", error);
        }
    }, []);

    return saveBrandRefreshStepSelection;
};

export default useSaveBrandRefreshStepSelection;

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Collapse, Divider } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useBrand } from 'layouts/utils/BrandContext';
import RefreshDetails from 'layouts/brand-manager/components/RefreshDetails';

// Import custom icons
import amazonIcon from 'assets/images/amazon.png';
import fbIcon from 'assets/images/fb.png';
import googleIcon from 'assets/images/google-mmm.png';
import kantarIcon from 'assets/images/kantar.png';
import nielsenIcon from 'assets/images/nielsen.png';
import shopifyIcon from 'assets/images/shopify.png';
import tradeDeskIcon from 'assets/images/tradedesk.png';
import tiktokIcon from 'assets/images/tiktok.png';
import youtubeIcon from 'assets/images/youtube.png';
import unemploymentRateIcon from 'assets/images/unemploymentRate.png';
import consumerConfidenceIndexIcon from 'assets/images/confidenceIndex.png';
import weatherIcon from 'assets/images/weather.png';
import dataWarehouseIcon from 'assets/images/data_warehouse.png';
import newConnectionIcon from 'assets/images/new_connection.png';
import excelIcon from 'assets/images/excel.png';

const connectionsList = [
  { name: 'MMM Labs Data Template', icon: excelIcon, component: <RefreshDetails /> },
  { name: 'Amazon Ads', icon: amazonIcon },
  { name: 'Facebook Ads', icon: fbIcon },
  { name: 'Google Ads', icon: googleIcon },
  { name: 'Kantar Ads', icon: kantarIcon },
  { name: 'Nielsen Ads', icon: nielsenIcon },
  { name: 'Shopify', icon: shopifyIcon },
  { name: 'The Trade Desk', icon: tradeDeskIcon },
  { name: 'TikTok Ads', icon: tiktokIcon },
  { name: 'YouTube', icon: youtubeIcon },
  { name: 'Unemployment Rate', icon: unemploymentRateIcon },
  { name: 'Consumer Confidence Index', icon: consumerConfidenceIndexIcon },
  { name: 'Weather', icon: weatherIcon },
  { name: 'Data Warehouse Connection', icon: dataWarehouseIcon },
  { name: 'Request New Connection', icon: newConnectionIcon },
];

const DataConnection = React.memo(() => {
  const { selectedBrand, selectedRefresh } = useBrand();
  const [openIndexes, setOpenIndexes] = useState(() => {
    const savedIndexes = localStorage.getItem('openIndexes');
    return savedIndexes ? JSON.parse(savedIndexes) : [0]; // "MMM Labs Data Template" expanded by default
  });

  // Track if a file is selected
  const selectedFile = selectedRefresh?.selectedFile;

  useEffect(() => {
    localStorage.setItem('openIndexes', JSON.stringify(openIndexes));
  }, [openIndexes]);

  const handleToggle = (index) => {
    setOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <MDBox p={2} pb={2} pr={5}>
      <MDTypography variant="h3">Data Connection</MDTypography>
      <MDBox p={3} pb={3} pr={5}>
        <Card variant="outlined" sx={{ margin: '20px auto', padding: 2 }}>
          <CardContent>
            <List>
              {connectionsList.map((connection, index) => (
                <React.Fragment key={connection.name}>
                  <ListItem sx={{ display: 'flex', justifyContent: 'space-between', mb: '15px', mt: '15px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={connection.icon} alt={`${connection.name} icon`} style={{ width: 33, marginRight: 8 }} />
                      <ListItemText primary={<Typography variant="normal">{connection.name}</Typography>} />
                    </div>
                    <MDButton
                      variant="contained"
                      color="dark"
                      size="small"
                      onClick={() => handleToggle(index)}
                      endIcon={openIndexes.includes(index) ? <ExpandLess /> : <ExpandMore />}
                      sx={{
                        backgroundColor: index === 0 && selectedFile ? 'green' : 'darkblue',
                        color: '#FFFFFF',
                        '&:hover': {
                          backgroundColor: index === 0 && selectedFile ? 'green' : 'navy',
                        },
                      }}
                    >
                      {index === 0 && selectedFile ? 'Connected' : '+ Connect'}
                    </MDButton>
                  </ListItem>
                  <Collapse in={openIndexes.includes(index)} timeout="auto" unmountOnExit>
                    <CardContent sx={{ backgroundColor: '#f5f5f5', borderRadius: 1 }}>
                      {index === 0 ? (
                        <RefreshDetails />
                      ) : (
                        <Typography variant="normal" color="textSecondary">
                          Feature in the Works – Available Soon
                        </Typography>
                      )}
                    </CardContent>
                  </Collapse>
                  <Divider sx={{ my: 1 }} />
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      </MDBox>
    </MDBox>
  );
});

export default DataConnection;

import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import DataConnectionTab from 'layouts/data-hub/components/DataConnectionTab';
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { useBrand } from 'layouts/utils/BrandContext';

function DataHub() {
  const navigate = useNavigate();
  const { selectedBrand, selectedRefresh } = useBrand();
  const { brandName, refreshName, tab } = useParams();

  const tabMapping = {
    dataConnection: 0,
  };

  const [tabValue, setTabValue] = useState(tabMapping[tab] || 0);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/data-hub/${brandName}/${refreshName}/${selectedTab}`);
  };

    useEffect(() => {
        if (tabMapping[tab] !== undefined) {
          setTabValue(tabMapping[tab]);
        }
    }, [tab]);

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });



 return (
    <DashboardLayout>
      <DashboardNavbar />
            <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Data Connection"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>schema</Icon> }
                  style={tabStyle(0)}
                  sx={{
                        backgroundColor: tabValue === 0 ? '#E3F2FD' : 'transparent',
                        border: tabValue === 0 ? '2px solid #247EEA' : 'none'
                   }}
                />
              </Tabs>
           <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
            </AppBar>
                {tabValue === 0 && <DataConnectionTab />}
      <Footer />
    </DashboardLayout>
  );
}

export default DataHub;

import { useState } from 'react';
import axios from 'axios';
import { useBrand } from 'layouts/utils/BrandContext';
import { useAuthInfo } from "@propelauth/react";

export const useDataConfiguration = () => {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const { user_email, user_company, selectedBrand, selectedRefresh, setSelectedRefresh, configurationData, setConfigurationData } = useBrand();

    const auth = useAuthInfo();

    const fetchDataConfigurationUserSelection = async () => {
        setLoading(true);
         const queryParams = new URLSearchParams({
          brand_name: selectedBrand.brand_name,
          refresh_name: selectedRefresh.refresh_name,

        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/get_refresh?${queryParams}`;
        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`,

                },
            });

            return new Promise((resolve) => {
                setConfigurationData((prevData) => {
                    const userSelection = response.data?.refresh?.UserSelection || null;
                    if (!userSelection || Object.values(userSelection).every(value => value === "")) {
                        console.warn('No UserSelection found for the specified brand and refresh.');
                        resolve(null);
                        return null;
                    }
                    const updatedData = {
                        ...prevData,
                        UserSelection: userSelection,
                    };
                    resolve(updatedData);
                    return updatedData;
                });
            });
        } catch (error) {
            console.error('Error fetching UserSelection:', error);
            setAlert({ show: true, message: 'Error fetching data from the server.', color: 'error' });
            setOpenDialog(true);
            return null;  // Return null in case of an error
        } finally {
            setLoading(false);
        }
    };

    const getUiConfigurationValues = async(updatedData) => {
        const configuration_ui_path = `${user_email}/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/configuration_ui.json`;
        const formData = new FormData();
        formData.append('configuration_ui_path', configuration_ui_path);

        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            const fullSalesUrl = `${baseUrl}/data_configuration/get_ui_configuration_values`;
            const response = await axios.post(fullSalesUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                }
            })
            //console.log("Response.data: ", JSON.stringify(response.data));
            //console.log("Response.updatedDate: ", JSON.stringify(updatedData.UserSelection));
            if (response.data) {
                // Parse the JSON string into an object
                const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
                data.UserSelection = updatedData.UserSelection
                setConfigurationData(data)
                const contextData = {
                  selectedBrand,
                  selectedRefresh: selectedRefresh,
                  configurationData: data,
                };
            }
        } catch (error) {
            let errorMessage = 'Error getting the configuration_ui.json file';
            if (error.response && error.response.data && error.response.data.detail) {
                errorMessage = `Error getting the configuration_ui.json file: ${error.response.data.detail}`;
            } else if (error.message) {
                errorMessage = `Error getting the configuration_ui.json file: ${error.message}`;
            }
            console.error('Error getting the configuration_ui.json file - ', error);
            setAlert({ show: true, message: errorMessage, color: 'error' });
            setOpenDialog(true);
        }
    };

    return {
        fetchDataConfigurationUserSelection,
        getUiConfigurationValues,
        loading,
        alert,
        setAlert
    };
};

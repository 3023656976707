import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';

function ScenarioTabs({ selectedTab, setSelectedTab, handleBackToScenarios, isSaving }) {
    const handleNext = () => {
        if (selectedTab < 2) setSelectedTab(selectedTab + 1);
    };

    const handleBack = () => {
        if (selectedTab > 0) setSelectedTab(selectedTab - 1);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}>
            <Button onClick={handleBackToScenarios}
                 sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    }
                }}
            >
                Back to Scenarios
            </Button>
            {selectedTab > 0 && (
                <Button onClick={handleBack} color="primary" style={{ marginLeft: 8 }}
                 sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    }
                }}
            >
                    Back
                </Button>
            )}
            {selectedTab < 2 && (
                <Button onClick={handleNext} color="primary" style={{ marginLeft: 8 }}
                 sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    }
                }}
            >
                    Next
                </Button>
            )}

        </Box>
    );
}

export default ScenarioTabs;

import React from 'react';
import {Box,Paper, Typography, IconButton, Menu, MenuItem, Tooltip, Alert } from '@mui/material';
import DataTable from "assets/Tables/DataTable";
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import { utils as XLSXUtils, write as XLSXWrite } from 'xlsx';
import PropTypes from 'prop-types';

function Results({optimizationResult }) {
  // State variables
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState({ show: false, message: '', severity: 'success' });

  // Extract data from optimizationResult using optional chaining and default values
  const totalPerformanceData = optimizationResult?.totalPerformanceData || optimizationResult?.total_performance || [];
  const advertisingPerformanceData = optimizationResult?.advertisingPerformanceData || optimizationResult?.advertising_performance || [];
  const plots = optimizationResult?.plots || {};

  // Utility Functions for Formatting
  const formatNumber = (num) => {
    if (typeof num !== 'number') return num;
    return num.toLocaleString();
  };

	const formatCurrency = (num) => {
		if (typeof num !== 'number') return num;
		const formattedNum = `$${Math.abs(num).toLocaleString()}`;
		return num < 0 ? <span style={{ color: 'red' }}>-{formattedNum}</span> : formattedNum;
	};

	const formatPercentage = (num) => {
	  if (typeof num !== 'number') return num;
	  const formattedNum = num.toFixed(2);
	  return `${formattedNum === '-0.00' ? '0.00' : formattedNum}%`;
	};

  // Format Total Performance Data
  const formattedTotalPerformanceData = totalPerformanceData.map((item) => {
    let initial = item.Initial;
    let optimized = item.Optimized;

    switch (item.Metric) {
      case 'ROI':
      case 'Percentage Change in Contribution':
      case 'Percentage Change in ROI':
        initial = formatPercentage(initial);
        optimized = formatPercentage(optimized);
        break;
      case 'Ad Spend':
        initial = formatCurrency(initial);
        optimized = formatCurrency(optimized);
        break;
      case 'Total Revenue':
	      initial = formatCurrency(initial);
	      optimized = formatCurrency(optimized);
      break;
      default:
        initial = formatNumber(initial);
        optimized = formatNumber(optimized);
        break;
    }

    return {
      ...item,
      Initial: initial,
      Optimized: optimized,
    };
  });

  // Format Advertising Performance Data
  const formattedAdvertisingPerformanceData = advertisingPerformanceData.map((item) => ({
    ...item,
    InitialSpend: formatCurrency(item.InitialSpend),
    OptimizedSpend: formatCurrency(item.OptimizedSpend),
  }));

  // Handle Download Menu
  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (format) => {
    setAnchorEl(null);
    if (format && (totalPerformanceData.length > 0 || advertisingPerformanceData.length > 0)) {
      downloadData(format, { totalPerformanceData, advertisingPerformanceData });
    }
  };

  // Download Data Function
  const downloadData = (format, data) => {
    try {
      if (format === 'excel') {
        const worksheet1 = XLSXUtils.json_to_sheet(data.totalPerformanceData);
        const worksheet2 = XLSXUtils.json_to_sheet(data.advertisingPerformanceData, { origin: -1 });
        const workbook = XLSXUtils.book_new();
        XLSXUtils.book_append_sheet(workbook, worksheet1, 'Total Performance');
        XLSXUtils.book_append_sheet(workbook, worksheet2, 'Advertising Performance');
        const excelBuffer = XLSXWrite(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'optimization_results.xlsx');
      } else if (format === 'csv') {
        const worksheet1 = XLSXUtils.json_to_sheet(data.totalPerformanceData);
        const worksheet2 = XLSXUtils.json_to_sheet(data.advertisingPerformanceData, { origin: -1 });
        const csv1 = XLSXUtils.sheet_to_csv(worksheet1);
        const csv2 = XLSXUtils.sheet_to_csv(worksheet2);
        const combinedCSV = `${csv1}\n${csv2}`;
        const blob = new Blob([combinedCSV], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'optimization_results.csv');
      }
      setAlert({ show: true, message: 'Download successful!', severity: 'success' });
    } catch (error) {
      console.error('Error during file download:', error);
      setAlert({ show: true, message: 'Failed to download the file. Please try again.', severity: 'error' });
    }
  };

  if (!optimizationResult) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" color="textSecondary">
          No optimization results to display. Please run the optimization.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Alert Messages */}
      {alert.show && (
        <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, show: false })} sx={{ mb: 2 }}>
          {alert.message}
        </Alert>
      )}

      {/* Total Performance Table */}
      <Paper elevation={3} sx={{
        padding: 3,
        marginBottom: 4,
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)'
      }}>
        <Typography variant="h6" sx={{
          color: '#333',
          fontWeight: 'bold',
          borderBottom: '2px solid #247EEA',
          paddingBottom: '5px',
          marginBottom: '15px'
        }}>
          Total Performance
        </Typography>
        {formattedTotalPerformanceData.length > 0 ? (
          <DataTable
            table={{ columns: [
              { Header: <b>Metric</b>, accessor: 'Metric' },
              { Header: <b>Initial</b>, accessor: 'Initial' },
              { Header: <b>Optimized</b>, accessor: 'Optimized' },
            ], rows: formattedTotalPerformanceData }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        ) : (
          <Typography>No Total Performance Data Available.</Typography>
        )}
      </Paper>

      {/* Advertising Performance Table */}
      <Paper elevation={3} sx={{
        padding: 3,
        marginBottom: 4,
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        position: 'relative'
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h6" sx={{
            color: '#333',
            fontWeight: 'bold',
            borderBottom: '2px solid #247EEA',
            paddingBottom: '5px',
            marginBottom: '5px',
            width: '100%'
          }}>
            Advertising Performance
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Download Menu */}
            <Tooltip title="Download Results">
              <IconButton onClick={handleDownloadClick} aria-label="Download Results">
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleMenuClose(null)}
            >
              <MenuItem onClick={() => handleMenuClose('excel')}>As Excel</MenuItem>
              <MenuItem onClick={() => handleMenuClose('csv')}>As CSV</MenuItem>
            </Menu>
          </Box>
        </Box>
        {formattedAdvertisingPerformanceData.length > 0 ? (
          <DataTable
            table={{ columns: [
              { Header: <b>Media Channel</b>, accessor: 'MediaChannel' },
              { Header: <b>Initial Spend</b>, accessor: 'InitialSpend' },
              { Header: <b>Optimized Spend</b>, accessor: 'OptimizedSpend' },
            ], rows: formattedAdvertisingPerformanceData }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        ) : (
          <Typography>No Advertising Performance Data Available.</Typography>
        )}

        {/* Charts Section */}
        {Object.keys(plots).length > 0 && (
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
              Optimization Charts
            </Typography>
            {Object.entries(plots).map(([plotName, plotData]) => (
              <Box key={plotName} sx={{ marginBottom: 4 }}>
                <Typography variant="subtitle1" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                  {plotName.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                </Typography>
                <img
                  src={`data:image/png;base64,${plotData}`}
                  alt={plotName}
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '600px',
                    objectFit: 'contain',
                    borderRadius: '8px',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    </Box>
  );
}

Results.propTypes = {
  optimizationResult: PropTypes.shape({
    total_performance: PropTypes.arrayOf(PropTypes.shape({
      Metric: PropTypes.string.isRequired,
      Initial: PropTypes.number.isRequired,
      Optimized: PropTypes.number.isRequired,
    })),
    advertising_performance: PropTypes.arrayOf(PropTypes.shape({
      MediaChannel: PropTypes.string.isRequired,
      InitialSpend: PropTypes.number.isRequired,
      OptimizedSpend: PropTypes.number.isRequired,
    })),
    plots: PropTypes.object, // Base64-encoded images
  }),
};

Results.defaultProps = {
  optimizationResult: null,
};

export default Results;

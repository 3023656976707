import React, { useState, useEffect, useCallback, useContext } from 'react';
import MDBox from 'components/MDBox';
import { useParams } from 'react-router-dom';
import MDTypography from 'components/MDTypography';
import SalesSummaryTable from './SalesSummaryTable';
import SalesGraph from './SalesGraph';
import GetFileData from './../GetFileData';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';
import { useBrand } from 'layouts/utils/BrandContext';


const SalesTab = () => {
    const { loadContext, selectedBrand, selectedRefresh } = useBrand();
    const [data, setData] = useState(() => {
        const savedData = localStorage.getItem('salesData');
        return savedData ? JSON.parse(savedData) : null;
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { brandName, refreshName, tab } = useParams();

    useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
    }, [brandName, refreshName, loadContext]);

    // Update localStorage when data changes
    useEffect(() => {
        if (data) {
            localStorage.setItem('salesData', JSON.stringify(data));
        }
    }, [data]);

    const onGetData = useCallback((sheetName, data) => {
        setData(data);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (selectedRefresh?.selectedFile) {
            setLoading(true);
            setData(null);
        }
    }, [selectedRefresh?.selectedFile]);

    useEffect(() => {
        if (error) {
            console.error('Error:', error);
        }
    }, [error]);

    return (
        <>
            <MDBox>
                <MDTypography variant="h3">
                    Sales
                </MDTypography>
                {selectedRefresh?.selectedFile && (
                    <GetFileData
                        sheetName="Sales"
                        onGetData={onGetData}
                        file={selectedRefresh?.selectedFile}
                    />
                )}
                {loading ? (
                    <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress sx={{ color: blue[500] }} />
                        <MDTypography variant="h6" sx={{ mt: 2 }}>
                            Loading the Sales graph for {selectedRefresh?.selectedFile ? selectedRefresh?.selectedFile.file.file_name : 'the selected file'}...
                       </MDTypography>
                    </MDBox>
                ) : (
                   selectedRefresh?.selectedFile && data && (
                        <>
                            <SalesSummaryTable data={data} />
                            <br />
                            <SalesGraph data={data} />
                        </>
                    )
                )}
            </MDBox>
        </>
    );
};

export default SalesTab;

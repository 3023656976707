// src/layouts/scenario-planner/components/Constraints.jsx

import React, { useEffect, useMemo } from 'react';
import { Box, Typography, TextField, IconButton, MenuItem } from '@mui/material';
import { Range, getTrackBackground } from 'react-range';
import { Remove as RemoveIcon } from '@mui/icons-material';
import MDButton from 'components/MDButton';
import { NumericFormat } from 'react-number-format';
import DataTable from "assets/Tables/DataTable";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const STEP = 1;
const MIN = -100;
const MAX = 100;

function Constraints({
    constraints,
    setConstraints,
    totalBudget,
    setTotalBudget,
    budgetTimingStart,
    budgetTimingEnd,
    setBudgetTimingStart,
    setBudgetTimingEnd,
    modelType,
    adSpendData = {},
    paidMediaColumns = [],
    onRunOptimization, // New prop for handling optimization
    isOptimizing, // Indicates if optimization is in progress
}) {
    // Handler to update total budget based on constraints
    useEffect(() => {
        const newTotalBudget = constraints.reduce((sum, c) => sum + (parseFloat(c.latestSpend) || 0), 0);
        setTotalBudget(newTotalBudget);
    }, [constraints, setTotalBudget]);

    // Handler to add a new constraint
    const handleAddConstraint = () => {
        if (!paidMediaColumns || paidMediaColumns.length === 0) {
            console.log('No paid media columns available to add as constraints.');
            return;
        }

        const existingNames = constraints.map(c => c.name);
        const availableColumns = paidMediaColumns.filter(col => !existingNames.includes(col));

        if (availableColumns.length === 0) {
            console.log('All paid media columns are already added as constraints.');
            return;
        }

        const newName = availableColumns[0];
        const newInitialSpend = adSpendData[newName] || 0;

        setConstraints(prevConstraints => [
            ...prevConstraints,
            {
                id: uuidv4(),
                name: newName,
                latestSpend: newInitialSpend,
                minAmount: parseFloat((newInitialSpend * 0.7).toFixed(2)),
                maxAmount: parseFloat((newInitialSpend * 1.3).toFixed(2)),
                percentLimit: [-30, 30],
            }
        ]);
    };

    // Handler to delete a constraint
    const handleDeleteConstraint = (index) => {
        setConstraints(prevConstraints => prevConstraints.filter((_, i) => i !== index));

        // Update total budget
        const updatedTotalBudget = constraints
            .filter((_, i) => i !== index)
            .reduce((sum, constraint) => sum + parseFloat(constraint.latestSpend || 0), 0);
        setTotalBudget(updatedTotalBudget);
    };

    // Handler for slider changes (percentLimit)
    const handleSliderChange = (index, values) => {
        const updatedConstraints = [...constraints];
        updatedConstraints[index].percentLimit = values;

        const initialSpend = parseFloat(updatedConstraints[index].latestSpend) || 0;
        updatedConstraints[index].minAmount = parseFloat((initialSpend * (1 + values[0] / 100)).toFixed(2));
        updatedConstraints[index].maxAmount = parseFloat((initialSpend * (1 + values[1] / 100)).toFixed(2));

        setConstraints(updatedConstraints);
    };

    // Effect to initialize constraints based on adSpendData and paidMediaColumns
    useEffect(() => {
        if (paidMediaColumns.length > 0 && Object.keys(adSpendData).length > 0) {
            const initialConstraints = paidMediaColumns.map((mediaChannel) => ({
                id: uuidv4(),
                name: mediaChannel,
                latestSpend: adSpendData[mediaChannel] || 0,
                minAmount: parseFloat(((adSpendData[mediaChannel] || 0) * 0.7).toFixed(2)),
                maxAmount: parseFloat(((adSpendData[mediaChannel] || 0) * 1.3).toFixed(2)),
                percentLimit: [-30, 30],
            }));

            setConstraints(initialConstraints);

            // Calculate total budget
            const calculatedTotalBudget = initialConstraints.reduce((sum, constraint) => sum + parseFloat(constraint.latestSpend || 0), 0);
            setTotalBudget(calculatedTotalBudget);
        }
    }, [adSpendData, paidMediaColumns, setConstraints, setTotalBudget]);

    // Define table columns
    const columns = useMemo(() => [
        { Header: <Typography fontWeight="bold" variant="h6">Action</Typography>, accessor: "action" },
        { Header: <Typography fontWeight="bold" variant="h6">Name</Typography>, accessor: "name" },
        { Header: <Typography fontWeight="bold" variant="h6">Initial Spend</Typography>, accessor: "latestSpend" },
        { Header: <Typography fontWeight="bold" variant="h6">Min $</Typography>, accessor: "minAmount" },
        { Header: <Typography fontWeight="bold" variant="h6">Max $</Typography>, accessor: "maxAmount" },
        { Header: <Typography fontWeight="bold" variant="h6">Percent Limits</Typography>, accessor: "percentLimit" }
    ], []);

    // Prepare table rows based on constraints
    const rows = useMemo(() => constraints.map((constraint, index) => ({
        action: (
            <IconButton color="error" onClick={() => handleDeleteConstraint(index)} aria-label={`Delete constraint ${constraint.name}`}>
                <RemoveIcon />
            </IconButton>
        ),
        name: (
            <TextField
                variant="outlined"
                value={constraint.name}
                disabled
                fullWidth
                sx={{ width: '120px', color: '#333' }}
                placeholder="Media Channel Name"
                select
                aria-label={`Media Channel ${constraint.name}`}
            >
                {paidMediaColumns.map((varName) => (
                    <MenuItem key={varName} value={varName}>
                        {varName}
                    </MenuItem>
                ))}
            </TextField>
        ),
        latestSpend: (
            <NumericFormat
                value={constraint.latestSpend.toFixed(2)}
                thousandSeparator=","
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={TextField}
                variant="outlined"
                sx={{ width: '130px', color: '#000' }}
                disabled
                aria-label={`Initial Spend for ${constraint.name}`}
            />
        ),
        minAmount: (
            <NumericFormat
                value={constraint.minAmount.toFixed(2)}
                thousandSeparator=","
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={TextField}
                variant="outlined"
                sx={{ width: '130px', color: '#000' }}
                disabled
                aria-label={`Minimum Amount for ${constraint.name}`}
            />
        ),
        maxAmount: (
            <NumericFormat
                value={constraint.maxAmount.toFixed(2)}
                thousandSeparator=","
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={TextField}
                variant="outlined"
                sx={{ width: '130px', color: '#000' }}
                disabled
                aria-label={`Maximum Amount for ${constraint.name}`}
            />
        ),
        percentLimit: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '250px' }}>
                <Range
                    values={constraint.percentLimit}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => handleSliderChange(index, values)}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '6px',
                                width: '100%',
                                background: getTrackBackground({
                                    values: constraint.percentLimit,
                                    colors: ['#ccc', '#548BF4', '#ccc'],
                                    min: MIN,
                                    max: MAX,
                                }),
                                borderRadius: '4px',
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                borderRadius: '50%',
                                backgroundColor: isDragged ? '#548BF4' : '#FFF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 2px 6px #AAA',
                                outline: 'none',
                            }}
                            aria-label={`Percent Limits for ${constraint.name}`}
                        >
                            <div
                                style={{
                                    height: '10px',
                                    width: '2px',
                                    backgroundColor: isDragged ? '#FFF' : '#548BF4',
                                }}
                            />
                        </div>
                    )}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span style={{ fontSize: '12px', color: '#333' }}>-100%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>-30%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>0%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>+30%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>+100%</span>
                </div>
            </div>
        ),
    })), [constraints, paidMediaColumns]);

    return (
        <Box>
            {/* Total Budget Display */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                <Typography variant="h6">Total Budget Limit:</Typography>
                <NumericFormat
                    value={totalBudget.toFixed(2)}
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    customInput={TextField}
                    variant="outlined"
                    sx={{ width: '150px', marginLeft: 2, color: '#000' }}
                    disabled
                    aria-label="Total Budget Limit"
                />
            </Box>

            {/* Channel Limits Table */}
            <Typography variant="h6" sx={{ marginBottom: 2 }}>Channel Limits</Typography>
            {constraints.length > 0 ? (
                <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                />
            ) : (
                <Typography>No constraints available.</Typography>
            )}

            {/* Add Constraint and Run Optimization Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap: 2 }}>
                <MDButton
                    variant="contained"
                    color="dark"
                    onClick={handleAddConstraint}
                    disabled={constraints.length >= paidMediaColumns.length}
                    sx={{ marginTop: 2 }}
                    aria-label="Add Constraint"
                >
                    Add Constraint
                </MDButton>

                {/* Run Optimization Button */}
                <MDButton
                    variant="contained"
                    color="primary"
                    onClick={onRunOptimization}
                    disabled={constraints.length === 0 || isOptimizing}
                    sx={{ marginTop: 2 }}
                    aria-label="Run Optimization"
                >
                    {isOptimizing ? 'Running...' : 'Run Optimization'}
                </MDButton>
            </Box>
        </Box>
    );

}

Constraints.propTypes = {
    constraints: PropTypes.array.isRequired,
    setConstraints: PropTypes.func.isRequired,
    totalBudget: PropTypes.number.isRequired,
    setTotalBudget: PropTypes.func.isRequired,
    budgetTimingStart: PropTypes.instanceOf(Date),
    budgetTimingEnd: PropTypes.instanceOf(Date),
    setBudgetTimingStart: PropTypes.func.isRequired,
    setBudgetTimingEnd: PropTypes.func.isRequired,
    modelType: PropTypes.string.isRequired,
    adSpendData: PropTypes.object,
    paidMediaColumns: PropTypes.array,
    onRunOptimization: PropTypes.func.isRequired, // New Prop
    isOptimizing: PropTypes.bool, // New Prop
};

Constraints.defaultProps = {
    adSpendData: {},
    paidMediaColumns: [],
    isOptimizing: false,
};

export default Constraints;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Icon, Stepper, Step, StepLabel, Link, Box } from '@mui/material';
import MDTypography from "components/MDTypography";
import MDButton from 'components/MDButton';
import MDBox from "components/MDBox";
import { useBrand } from 'layouts/utils/BrandContext';
import AddTask from '@mui/icons-material/AddTask';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import ScenarioPlannerIcon from '@mui/icons-material/EditCalendar';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import 'assets/css/stepper.css';

const steps = [
  { label: "Define your brand", icon: <AddTask />, description: "Step 1 - Define your brand by specifying its name, subname, industry, logo and optionally data to upload." },
  { label: "Load your data", icon: <CloudUploadIcon />, description: "Step 2 - Upload the necessary data required for the models to run." },
  { label: "Configure variables", icon: <SettingsIcon />, description: "Step 3 - Configure variables such as sales metrics, advertising, organic advertising, uncontrollable metrics." },
  { label: "Run all models", icon: <PlayArrowIcon />, description: "Step 4 - Run all available models to generate insights based on your data." },
  { label: "View Results", icon: <BarChartIcon />, description: "Step 5- View the model outputs such as executive summary, ROI results, model statistics and key recommendations." },
  { label: "View Insights", icon: <InsightsIcon />, description: "Step 6- View models insights to help in decision making." },
  { label: "Scenario Planner", icon: <ScenarioPlannerIcon />, description: "Step 7 - Run simulations to project future outcomes based on different scenarios." },
  { label: "Generate Slide Deck", icon: <PresentToAllIcon />, description: "Step 8 - Package all results and insights into a shareable presentation deck." },
];

function StepIconComponent({ icon, isActive, isCompleted }) {
  return (
    <Box
      sx={{
        backgroundColor: isActive ? '#1F9C3A' : '#EEEEEE',
        borderRadius: '50%',
        padding: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: isActive ? '0px 4px 12px rgba(0, 0, 0, 0.5)' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        opacity: 1,
        position: 'relative',
        cursor: 'pointer',
      }}
    >
 {React.cloneElement(icon, { style: { fontSize: 40, color: isActive ? '#ffffff' : '#000000' } })}

      {isCompleted && (
        <CheckCircleIcon
          sx={{
            position: 'absolute',
            top: -21,
            right: 7,
            fontSize: 20,
            color: '#1F9C3A',
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
}

function StepperComponent({ activeStep, onStepClick }) {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={{
        background: 'linear-gradient(195deg, #FFFFFF, #FFFFFF)',
        padding: '1.5rem 0 1rem',
        borderRadius: '0.5rem',
        boxShadow: '0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14), 0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4)',
        '& .MuiStepLabel-label': {
          marginTop: 2,
          fontWeight: 'normal',
          color: '#000000 !important',
        },
        '& .MuiStepLabel-label.Mui-active': {
          color: '#1F9C3A !important',
          fontWeight: 'bold',
        },
        '& .MuiStepLabel-label.Mui-completed': {
          color: 'darkgrey !important',
        },
      }}
    >
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel
            onClick={() => onStepClick(index)}
            StepIconComponent={() => (
              <StepIconComponent
                icon={step.icon}
                isActive={activeStep === index}
                isCompleted={index < activeStep}
               />
            )}
            sx={{
              cursor: 'pointer',
            }}
          >
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function StarterGuideTab() {
    const { currentStep, setCurrentStep, saveContext, selectedRefresh, selectedBrand } = useBrand();
    const { brandName, refreshName } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        //console.log("current step: ", currentStep);
        if (currentStep !== undefined) {
            setActiveStep(currentStep);
        } else {
            setActiveStep(0);
        }
    }, [currentStep]);

    const handleStepClick = (stepIndex) => {
        console.log("selectedRefresh: ", selectedRefresh);
        setCurrentStep(stepIndex);
        setActiveStep(stepIndex);
        saveContext(
            {
                selectedBrand,
                selectedRefresh,
                currentStep: stepIndex,
            }
        );
    };

  return (
    <>
      <MDBox pb={2} pt={3} >
        <StepperComponent activeStep={activeStep} onStepClick={handleStepClick} />
      </MDBox>
      <Card
        sx={{
          mt: 2,
          mb: 3,
          p: 3,
          backgroundColor: '#FFFFFF',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <MDBox display="flex" justifyContent="space-between" mb={2}>
          <MDBox textAlign="left">
            <MDTypography variant="normal">
              Get up and running on MMM Labs fast with these key activities.
              <br />
              <Icon>phone</Icon> Speak with a MMM Labs specialist.
              Book a{' '}
              <Link
                href="https://www.mmmlabs.ai/book-a-demo"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{ fontWeight: 'bold', color: 'blue' }}
              >
                demo
              </Link>
            </MDTypography>

            <MDTypography pt={3} variant="h5">
              {steps[activeStep].label}
            </MDTypography>
            <MDTypography pt={3} variant="normal">
              {steps[activeStep].description}
            </MDTypography>

            {activeStep === 0 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate('/brand-manager')}
                sx={{ ml: 1}}
              >
                Select a Brand
              </MDButton>
            )}
            {activeStep === 1 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/data-hub/${brandName}/${refreshName}/data-connection`)}
                sx={{ ml: 1}}
              >
                Upload Data
              </MDButton>
            )}
    
            {activeStep === 2 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/brand-manager/${brandName}/${refreshName}/data-configuration`)}
                sx={{ ml: 1}}
              >
                Configure Data
              </MDButton>
            )}
    
            {activeStep === 3 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/modeling/${brandName}/${refreshName}/modelingSetup`)}
                sx={{ ml: 1 }}
              >
                Run Models
              </MDButton>
            )}
    
            {activeStep === 4 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/modeling/$brandName}/${refreshName}/modelsResults`)}
                sx={{ ml: 1 }}
              >
                Models Results
              </MDButton>
            )}
    
            {activeStep === 5 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/ai-insights/${brandName}/${refreshName}/exec`)}
                sx={{ ml: 1 }}
              >
                Insights
              </MDButton>
            )}
    
            {activeStep === 6 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/scenario-planner/${brandName}/${refreshName}`)}
                sx={{ ml: 1 }}
              >
                Scenario Planner
              </MDButton>
            )}
    
            {activeStep === 7 && (
              <MDButton
                variant="contained"
                color="dark"
                onClick={() => navigate(`/insight-deck-builder/${brandName}/${refreshName}/deckBuilder`)}
                sx={{ ml: 1 }}
              >
                Deck Builder
              </MDButton>
            )}            
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

export default StarterGuideTab;

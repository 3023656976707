import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Card, Alert, Dialog, DialogContent, DialogTitle, DialogActions, Button, Breadcrumbs, Link, CircularProgress, Tooltip } from '@mui/material';
import { blue } from '@mui/material/colors';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import RefreshList from './RefreshList';
import RefreshDetails from './RefreshDetails';
import { useBrand } from 'layouts/utils/BrandContext';

const BrandDetails = () => {
  const { selectedBrand, setSelectedBrand, selectedRefresh, setSelectedRefresh, selectedFile, loadContext, user_company, user_email } = useBrand();
  const { brandName } = useParams();
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const refreshDetailsRef = useRef();  // Create a ref for RefreshDetails
  const auth = useAuthInfo();

  const fetchRefreshes = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams({
          brand_name: selectedBrand?.brand_name,
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/get_refreshes?${queryParams}`;

        try {
          const response = await axios.get(fullUrl, {
            headers: {
                 "Accept": "application/json",
                 "Authorization": `Bearer ${auth.accessToken}`
            },
          });
          if (response.data && Array.isArray(response.data.refreshes)) {
            setSelectedBrand({ brand_name: brandName, refreshes: response.data.refreshes });
            if (response.data.selected_refresh) {
              setSelectedRefresh(response.data.selected_refresh);
            }
          } else {
            setSelectedBrand({ brand_name: brandName, refreshes: [] });
          }
        } catch (error) {
          console.error('Error getting refreshes:', error);
          setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
          setOpenDialog(true);
        } finally {
          setLoading(false);
        }
   };

  useEffect(() => {
      if (!brandName) return;
         fetchRefreshes();
      }, [brandName]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRefreshUpdate = () => {
    fetchRefreshes();
  }

  const handleFetchFilesVersions = () => {
    if (refreshDetailsRef.current && refreshDetailsRef.current.fetchFilesVersions) {
         console.log("BrandDetails>>handleFetchFilesVersions BEFORE");
        refreshDetailsRef.current.fetchFilesVersions();
         console.log("BrandDetails>>handleFetchFilesVersions AFTER");
    } else {
        console.error("refreshDetailsRef.current is null or undefined");
    }
 }

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <br/>
     {loading ? (
        <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress sx={{ color: blue[500] }} />
           <MDTypography variant="body2" sx={{ mt: 2 }}>Loading brands...</MDTypography>
        </MDBox>
      ) : selectedBrand && (
        <Card>
          <MDBox p={2}>
            <MDBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign:'center', p: 2 }}>
				<MDTypography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
				  Brand: <strong>{selectedBrand.brand_name}</strong> -
				  Refresh: <strong>
				    {selectedRefresh && selectedRefresh.refresh_name ? (
				      selectedRefresh.refresh_name
				    ) : (
				      <span style={{ color: '#AAAAAA' }}>None</span>
				    )}
				  </strong> -
				  File: <strong>
				    {selectedRefresh && selectedRefresh.selectedFile ? (
				      selectedRefresh.selectedFile.file.file_name
				    ) : (
				      <span style={{ color: '#AAAAAA' }}>None</span>
				    )}
				  </strong>
				  <br/>
				  Click on a refresh from the list below to configure data
				  <Tooltip title="A refresh contains data to configure variables such as sales metrics, advertising, organic advertising, uncontrollable metrics." arrow>
				    <HelpOutlineIcon sx={{ fontSize: '16px', ml: 1, cursor: 'pointer', verticalAlign: 'middle' }} />
				  </Tooltip>
				</MDTypography>
            </MDBox>
            <MDBox sx={{ mt: 3 }}>
              <RefreshList onRefreshUpdate={handleRefreshUpdate} onFetchFilesVersions={handleFetchFilesVersions} />
            </MDBox>
                {/* Hidden container - We need RefreshDetails for the ref with onFetchFilesVersions */}
                <div style={{ display: 'none' }}>
                    <RefreshDetails ref={refreshDetailsRef} />
                </div>
          </MDBox>
        </Card>
      )}
    </>
  );
};

export default BrandDetails;




import axios from 'axios';

export const fetchSignedUrl = async (fileUrl, token, generation = null) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const fullUrl = `${baseUrl}/utils/generate_signed_url`;

  try {
    const response = await axios.get(fullUrl, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      },
      params: {
        file_url: fileUrl,
        ...(generation && { generation })  // Add generation only if it's provided
      },
    });
    return response.data.signed_url;
  } catch (error) {
    console.error('Error fetching signed URL:', error);
    return null;
  }
};

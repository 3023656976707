import React, { useState, useEffect } from 'react';
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Link, Alert, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSignedUrl } from 'hooks/useSignedUrl';
import { v4 as uuidv4 } from 'uuid';
import { useBrand } from 'layouts/utils/BrandContext';

const AddBrandDialog = ({ open, onClose, onSuccess }) => {
  const { user_company, user_email } = useBrand();
  const [brand_name, setBrandName] = useState('');
  const [sub_name, setSubName] = useState('');
  const [industry, setIndustry] = useState('');
  const [file_data, setFileData] = useState(null);
  const [file_custom_data, setFileCustomData] = useState(null);
  const [file_logo, setFileLogo] = useState(null);
  const [logo_preview, setLogoPreview] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });

  // Error state for each field
  const [brandNameError, setBrandNameError] = useState('');
  const [subNameError, setSubNameError] = useState('');
  const [industryError, setIndustryError] = useState('');
  const [logoError, setLogoError] = useState('');

  const auth = useAuthInfo();
  const mmm_labs_bucket = process.env.REACT_APP_MMM_LABS_BUCKET;

  const signedTemplateUrl = useSignedUrl(`https://storage.cloud.google.com/${mmm_labs_bucket}/templates/templates_MMMLabs_Data_Template.xlsx`);

  const onDropFileData = acceptedFiles => {
    setFileData(acceptedFiles[0]);
  };

  const onDropCustomFile = acceptedFiles => {
    setFileCustomData(acceptedFiles[0]);
  };

  const onDropLogo = acceptedFiles => {
    const logoFile = acceptedFiles[0];
    if (logoFile) {
      setFileLogo(logoFile);
      const url = URL.createObjectURL(logoFile);
      setLogoPreview(url);
    }
  };

  const removeDataFile = () => {
    setFileData(null);
  };

  const removeCustomFile = () => {
    setFileCustomData(null);
  };

  const removeLogo = () => {
    setFileLogo(null);
    setLogoPreview('');
  };

  const { getRootProps: getFileCustomProps, getInputProps: getFileCustomInputProps } = useDropzone({
    onDrop: onDropCustomFile,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDropRejected: () => {
      setAlert({ show: true, message: 'Only Excel or CSV files are allowed.', color: 'warning' });
    }
  });

  const { getRootProps: getFileDataProps, getInputProps: getFileDataInputProps } = useDropzone({
    onDrop: onDropFileData,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDropRejected: () => {
      setAlert({ show: true, message: 'Only Excel or CSV files are allowed.', color: 'warning' });
    }
  });

  const { getRootProps: getLogoProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: onDropLogo,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif']
    },
    onDropRejected: () => {
      setAlert({ show: true, message: 'Only image files are allowed.', color: 'warning' });
    }
  });

  const cleanupLocalData = () => {
    setBrandName('');
    setSubName('');
    setIndustry('');
    setFileCustomData(null);
    setFileData(null);
    setFileLogo(null);
    setLogoPreview('');
    setAlert({ show: false, message: '', color: '' });
    setBrandNameError('');
    setSubNameError('');
    setIndustryError('');
    setLogoError('');
  };

  const sanitizeName = (name) => {
      // Replace any character that is not a letter, number, space, or dash with an empty string
      return name.replace(/[^a-zA-Z0-9\s-]/g, '');
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const handleSubmit = async () => {
    let hasError = false;

    if (!brand_name) {
      setBrandNameError('Brand Name is required');
      hasError = true;
    } else {
      setBrandNameError('');
    }

    if (!industry) {
      setIndustryError('Industry is required');
      hasError = true;
    } else {
      setIndustryError('');
    }

    if (!file_logo) {
      setLogoError('Logo is required');
      hasError = true;
    } else {
      setLogoError('');
    }

    if (hasError) {
      return;
    }

    const formData = new FormData();
    const brand_id = uuidv4();

    formData.append('brand_id', brand_id);
    formData.append('brand_name', brand_name);
    formData.append('sub_name', sub_name);
    formData.append('industry', industry);
    formData.append('user_company', user_company);
    formData.append('user_email', user_email);
    formData.append('file_logo', file_logo);
    if (file_data) {
      formData.append('file_data', file_data);
    }
    if (file_custom_data) {
      formData.append('file_custom_data', file_custom_data);
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/brands/create_brand`;
    try {
      const response = await axios.post(fullUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${auth.accessToken}`
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        }
      });

      if (response.data) {
        cleanupLocalData();
        setAlert({ show: true, message: response.data.message, color: 'info' });
        onSuccess(response.data);
        onClose();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
    }
  };

  useEffect(() => {
    if (!open) {
      cleanupLocalData();
    }
  }, [open]);

  const textFieldStyles = {
        margin: 'dense',
        variant: 'outlined',
        fullWidth: true,
        sx: {
            '& .MuiInputBase-root': {
                height: '44px',
            },
        },
  };

  const signedUrl = file_logo ? URL.createObjectURL(file_logo) : null;

  return (
    <Dialog open={open} onClose={onClose} onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              onClose();
            }
          }}
          onKeyPress={(e) => { if (e.key === 'Enter') handleSubmit(); }}
          disableEscapeKeyDown
      >
      <DialogTitle>Add a Brand</DialogTitle>
      <DialogContent sx={{ mt: -2 }}>
        {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
      </DialogContent>
      <DialogContent>
        <TextField
          margin="dense"
          label="Brand Name"
          fullWidth
          variant="outlined"
          value={sanitizeName(brand_name)}
          onChange={e => setBrandName(e.target.value)}
          error={!!brandNameError}
          helperText={brandNameError}
        />
        <TextField
          margin="dense"
          label="Subname"
          fullWidth
          variant="outlined"
          value={sub_name}
          onChange={e => setSubName(e.target.value)}
          helperText={subNameError}
        />
        <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                required
                {...textFieldStyles}
        >
          <InputLabel id="industry-label">Industry</InputLabel>
          <Select
            labelId="industry-label"
            id="industry"
            value={industry}
            label="Industry"
            onChange={handleIndustryChange}
            error={!!industryError}
            IconComponent={ArrowDropDownIcon}
            sx={{
                '& .MuiSelect-icon': {
                    display: 'block',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                },
            }}
          >
            <MenuItem value=""><em>Please select...</em></MenuItem>
            <MenuItem value="Consulting">Consulting</MenuItem>
            <MenuItem value="Education">Education</MenuItem>
            <MenuItem value="Entertainment">Entertainment</MenuItem>
            <MenuItem value="Financial Services">Financial Services</MenuItem>
            <MenuItem value="Government">Government</MenuItem>
            <MenuItem value="Healthcare">Healthcare</MenuItem>
            <MenuItem value="Insurance">Insurance</MenuItem>
            <MenuItem value="Manufacturing">Manufacturing</MenuItem>
            <MenuItem value="Media and Communications">Media and Communications</MenuItem>
            <MenuItem value="Retail">Retail</MenuItem>
            <MenuItem value="Technology">Technology</MenuItem>
            <MenuItem value="Transportation">Transportation</MenuItem>
            <MenuItem value="Utilities">Utilities</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
          {industryError && (
            <Alert severity="error" sx={{ mt: 1 }}>{industryError}</Alert>
          )}
        </FormControl>
        <MDBox sx={{ mt: 2 }}>
          <MDTypography variant="normal">Logo</MDTypography>
          <MDBox {...getLogoProps()} sx={{ border: '2px dashed #ccc', borderRadius: '4px', p: 2, textAlign: 'center', mt: 1, position: 'relative', cursor: 'pointer' }}>
            <input {...getLogoInputProps()} />
            {logo_preview ? (
              <img src={signedUrl} alt="Logo Preview" style={{ marginTop: '1px', maxHeight: '80px' }} />
            ) : (
              <MDTypography variant="h6">Drop your logo here or click</MDTypography>
            )}
            {file_logo && (
              <IconButton
                size="small"
                onClick={removeLogo}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </MDBox>
          {logoError && (
            <Alert severity="error" sx={{ mt: 1 }}>{logoError}</Alert>
          )}
        </MDBox>
        <MDBox sx={{ mt: 1 }}>
          <MDTypography variant="normal">
            Data Setup (optional) - Download sample template:
            <Link
             href={signedTemplateUrl || '#'}
              target="_blank" rel="noopener noreferrer"
              color="inherit"
              sx={{ fontWeight: 'bold', color: 'blue', paddingLeft: '10px' }}
            >
              MMM Labs Template
            </Link>
          </MDTypography>
          <MDBox {...getFileDataProps()} sx={{ border: '2px dashed #ccc', borderRadius: '4px', p: 2, textAlign: 'center', mt: 1, position: 'relative', cursor: 'pointer' }}>
            <input {...getFileDataInputProps()} />
            {!file_data ? (
              <MDTypography variant="h6">Drop your file here or click</MDTypography>
            ) : (
              <MDTypography variant="h6">{file_data.name}</MDTypography>
            )}
            {file_data && (
              <IconButton
                size="small"
                onClick={removeDataFile}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </MDBox>
        </MDBox>
        <MDBox sx={{ mt: 1 }}>
          <MDTypography variant="normal">Custom upload (optional)</MDTypography>
          <MDBox {...getFileCustomProps()} sx={{ border: '2px dashed #ccc', borderRadius: '4px', p: 2, textAlign: 'center', mt: 1, position: 'relative', cursor: 'pointer' }}>
            <input {...getFileCustomInputProps()} />
            {!file_custom_data ? (
              <MDTypography variant="h6">Drop your file here or click</MDTypography>
            ) : (
              <MDTypography variant="h6">{file_custom_data.name}</MDTypography>
            )}
            {file_custom_data && (
              <IconButton
                size="small"
                onClick={removeCustomFile}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            }
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBrandDialog;

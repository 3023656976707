import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppBar,Tabs, Tab, Icon } from '@mui/material';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import Footer from "assets/Footer";
import ModelStatisticsTab from 'layouts/ai-insights/components/ModelStatisticsTab';
import StarterGuideTab from 'layouts/starter-guide/components/StarterGuideTab';
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useBrand } from 'layouts/utils/BrandContext';


function StarterGuide() {
    const { loadContext, selectedBrand, selectedRefresh } = useBrand();
    const { brandName, refreshName, tab } = useParams();
    const navigate = useNavigate();

    const tabMapping = {
        step: 0,
    };

    const [tabValue, setTabValue] = useState(tabMapping[tab] || 0);

    useEffect(() => {
    // Update the tab value whenever the URL changes
    if (tabMapping[tab] !== undefined) {
      setTabValue(tabMapping[tab]);
    }
    }, [tab]);

    const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/starter-guide/${brandName}/${refreshName}/${selectedTab}/step`);
    };

    useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
    }, [brandName, refreshName, loadContext]);

    const tabStyle = useCallback((index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
    }), [tabValue]);


    return (
        <DashboardLayout>
             <DashboardNavbar />
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Starter Guide"
                    icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>flag</Icon>}
                    style={tabStyle(0)}
                    sx={{
                            backgroundColor: tabValue === 0 ? '#E3F2FD' : 'transparent',
                            border: tabValue === 0 ? '2px solid #247EEA' : 'none'
                     }}
                  />
                </Tabs>
         <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
            </AppBar>
             {tabValue === 0 && <StarterGuideTab />}
            <Footer />
        </DashboardLayout>
    );
}

export default StarterGuide;


import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from 'layouts/utils/BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, metric, theme) {
  return {
    fontWeight:
      metric.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SalesMetric({ metric_variable = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(metric_variable)) {
    metric_variable = [];
  }

  const filterValidMetrics = (metrics) => Array.isArray(metrics) ? metrics.filter(metric => metric_variable.includes(metric)) : [];

  const [salesMetric, setSalesMetric] = useState(() => {
    if (configurationDataUserSelection?.salesMetric && Array.isArray(configurationDataUserSelection.salesMetric) && configurationDataUserSelection.salesMetric.length) {
      return filterValidMetrics(configurationDataUserSelection.salesMetric);
    }
    return metric_variable.length > 0 ? [metric_variable[0]] : [];
  });

  useEffect(() => {
    const initialSalesMetric = configurationDataUserSelection?.salesMetric && Array.isArray(configurationDataUserSelection.salesMetric) && configurationDataUserSelection.salesMetric.length
      ? filterValidMetrics(configurationDataUserSelection.salesMetric)
      : (metric_variable.length > 0 ? [metric_variable[0]] : []);
    setSalesMetric(initialSalesMetric);
    updateConfigurationDataUserSelection('salesMetric', initialSalesMetric);
  }, [metric_variable, configurationDataUserSelection?.salesMetric, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = Array.isArray(value) ? value : value.split(',');
    const validValues = filterValidMetrics(newValue);
    setSalesMetric(validValues);
    updateConfigurationDataUserSelection('salesMetric', validValues);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="metric-variable-label">Metric</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="metric-variable-label"
        id="multiple-metric-variable"
        value={salesMetric.length > 0 ? salesMetric : ''}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-metric-variable" label="Metric" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {metric_variable.length > 0 ? (
          metric_variable.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, salesMetric, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

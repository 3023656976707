import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, Typography, Box } from '@mui/material';
import { useBrand } from 'layouts/utils/BrandContext';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';
import ScenarioList from 'layouts/scenario-planner/components/ScenarioList';

function ScenarioPlanner() {
  const { loadContext, selectedBrand, selectedRefresh } = useBrand();
  const { brandName, refreshName } = useParams();
  const location = useLocation();
  const initialState = location.state ? location.state.state : {};
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  useEffect(() => {
      const fetchContext = async () => {
        if (brandName && refreshName && !selectedBrand && !selectedRefresh) {
          await loadContext(brandName, refreshName);
        }
      };
      fetchContext();
  }, [brandName, refreshName, loadContext]);

  const navigate = useNavigate();



 return (
  <DashboardLayout>
      <DashboardNavbar />

        <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Scenario Planner"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>edit_calendar</Icon> }
                  style={tabStyle(0)}
                  sx={{
                        backgroundColor: tabValue === 0 ? '#E3F2FD' : 'transparent',
                        border: tabValue === 0 ? '2px solid #247EEA' : 'none'
                  }}
                />
                 </Tabs>
          </AppBar>
     <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
          {tabValue === 0 && <ScenarioList />}
      <Footer />
    </DashboardLayout>
  );
}

export default ScenarioPlanner;

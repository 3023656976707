
function getAccountUrl() {
    let accountUrl;
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case "local":
            accountUrl = "https://65811862.propelauthtest.com";
            break;
        case "dev":
            accountUrl = "https://62334241.propelauthtest.com";
            break;
        case "prod":
            accountUrl = "https://auth.mmmlabs.ai";
            break;
        default:
            accountUrl = "https://auth.mmmlabs.ai";
    }
    return accountUrl;
}

export default getAccountUrl;

import React, { useMemo, useState } from 'react';
import 'assets/css/table.css';
import MDTypography from 'components/MDTypography';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import DataTable from "assets/Tables/DataTable";
import { useBrand } from 'layouts/utils/BrandContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/css/custom_date_picker.css';

function SalesSummaryTable({ data = [] }) {
  const { configurationDataUserSelection } = useBrand();
  const date_column = configurationDataUserSelection?.date && configurationDataUserSelection.date[0] ? configurationDataUserSelection.date[0] : '';
  const channels = configurationDataUserSelection?.salesChannels || [];
  const metrics = configurationDataUserSelection?.salesMetric || [];
  const [selectedRange, setSelectedRange] = useState([null, null]);
  const [startDate, endDate] = selectedRange;

  //console.log("Sales data:", JSON.stringify(data, null, 2));

  const parseDate = (dateString) => {
    if (!dateString || typeof dateString !== 'string') return null;
    const parsedDate = new Date(dateString.split('T')[0]);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  const handleDateRangeChange = (range) => {
    if (!data.length) return;

    const dataDates = data.map(item => parseDate(item[date_column])).filter(date => date);

    if (!dataDates.length) return;

    dataDates.sort((a, b) => a - b);

    const dataStartDate = dataDates[0];
    const dataEndDate = dataDates[dataDates.length - 1];

    let start = null;
    let end = dataEndDate;

    switch (range) {
      case '1M':
        start = new Date(dataEndDate.getFullYear(), dataEndDate.getMonth() - 1, dataEndDate.getDate());
        break;
      case '3M':
        start = new Date(dataEndDate.getFullYear(), dataEndDate.getMonth() - 3, dataEndDate.getDate());
        break;
      case '6M':
        start = new Date(dataEndDate.getFullYear(), dataEndDate.getMonth() - 6, dataEndDate.getDate());
        break;
      case 'YTD':
        start = new Date(dataEndDate.getFullYear(), 0, 1);
        break;
      case '1Y':
        start = new Date(dataEndDate.getFullYear() - 1, dataEndDate.getMonth(), dataEndDate.getDate());
        break;
      case '2Y':
        start = new Date(dataEndDate.getFullYear() - 2, dataEndDate.getMonth(), dataEndDate.getDate());
        break;
      case 'All':
        start = dataStartDate;
        end = dataEndDate;
        break;
      default:
        break;
    }

    if (start && start < dataStartDate) {
      start = dataStartDate;
    }
    setSelectedRange([start, end]);
  };

  const safeData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data.filter((item) => {
      const recordDate = parseDate(item[date_column]);
      if (startDate && endDate) {
        return recordDate >= startDate && recordDate <= endDate;
      }
      return true;
    });
  }, [data, date_column, startDate, endDate]);

  const summary = useMemo(() => safeData.reduce((acc, curr) => {
    const Sales_Channel = curr["channel"] || curr["Sales Channel"];
    if (!Sales_Channel || !channels.includes(Sales_Channel)) return acc;

    if (!acc[Sales_Channel]) {
      acc[Sales_Channel] = metrics.reduce((acc, metric) => {
        acc[metric] = 0;
        return acc;
      }, {});
    }

    metrics.forEach(metric => {
      acc[Sales_Channel][metric] += curr[metric] || 0;
    });
    return acc;
  }, {}), [safeData, channels, metrics]);

  const rows = useMemo(() => Object.entries(summary).map(([channel, metricValues]) => {
    const row = { Sales_Channel: channel };
    metrics.forEach(metric => {
      row[metric] = metric.includes('Revenue')
        ? `$${parseFloat(metricValues[metric]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        : Number(metricValues[metric]).toLocaleString();
    });
    return row;
  }), [summary, metrics]);

  const columns = useMemo(() => [
    { Header: <MDTypography fontWeight="bold" variant="h6">Sales Channel</MDTypography>, accessor: "Sales_Channel" },
    ...metrics.map(metric => ({
      Header: <MDTypography fontWeight="bold" variant="h6">{metric}</MDTypography>,
      accessor: metric
    }))
  ], [metrics]);

  return (
    <Card>
      <MDBox mx={2} m={2} display="flex" alignItems="center">
        <DatePicker
          selected={startDate}
          onChange={(update) => {
            setSelectedRange(update);
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          isClearable
          placeholderText="Select date range"
          className="date-picker"
        />
        <MDButton onClick={() => handleDateRangeChange('1M')}>1M</MDButton>
        <MDButton onClick={() => handleDateRangeChange('3M')}>3M</MDButton>
        <MDButton onClick={() => handleDateRangeChange('6M')}>6M</MDButton>
        <MDButton onClick={() => handleDateRangeChange('YTD')}>YTD</MDButton>
        <MDButton onClick={() => handleDateRangeChange('1Y')}>1Y</MDButton>
        <MDButton onClick={() => handleDateRangeChange('2Y')}>2Y</MDButton>
        <MDButton onClick={() => handleDateRangeChange('All')}>All</MDButton>
      </MDBox>
      {safeData.length === 0 ? (
        <MDTypography variant="normal" mx={2} mt={2} mb={1}>
          No Sales summary data available for the selected date range from <strong>{startDate ? startDate.toDateString() : 'N/A'}</strong> to <strong>{endDate ? endDate.toDateString() : 'N/A'}</strong>.
        </MDTypography>
      ) : (
        <MDBox m={2}>
          <div className="data-table-wrapper">
            <DataTable
              table={{ columns, rows }}
              isSorted={true}
              entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
              showTotalEntries={true}
              noEndBorder
              canSearch
            />
          </div>
        </MDBox>
      )}
    </Card>
  );
}

export default SalesSummaryTable;

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, Typography, Box } from '@mui/material';
import { useBrand } from 'layouts/utils/BrandContext';
import ModelingSetupTab from 'layouts/modeling/components/ModelingSetupTab';
import ModelsResultsTab from 'layouts/modeling/components/ModelsResultsTab';
import BreadcrumbsComponent from 'layouts/utils/Breadcrumbs';

function Modeling() {
  const { selectedBrand, selectedRefresh, selectedFile } = useBrand();
  const { brandName, refreshName, tab } = useParams();
  const navigate = useNavigate();

  const tabMapping = {
    modelingSetup: 0,
    modelsResults: 1,
  };

  const [tabValue, setTabValue] = useState(tabMapping[tab] || 0)

 useEffect(() => {
    // Update the tab value whenever the URL changes
    if (tabMapping[tab] !== undefined) {
      setTabValue(tabMapping[tab]);
    }
  }, [tab]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    // Get the key from the tabMapping and navigate to the correct URL
    const tabKeys = Object.keys(tabMapping);
    const selectedTab = tabKeys[newValue];
    navigate(`/modeling/${brandName}/${refreshName}/${selectedTab}`);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />

        <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue} >
                <Tab
                    label="Modeling Setup"
                    icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>model_training</Icon> }
                    style={tabStyle(0)}
                    sx={{
                            backgroundColor: tabValue === 0 ? '#E3F2FD' : 'transparent',
                            border: tabValue === 0 ? '2px solid #247EEA' : 'none'
                     }}
                />
               <Tab
                    label="Model Results"
                    icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>assessment</Icon>}
                    style={tabStyle(1)}
                     sx={{
                            backgroundColor: tabValue === 1 ? '#E3F2FD' : 'transparent',
                            border: tabValue === 1 ? '2px solid #247EEA' : 'none'
                     }}
                />
                 </Tabs>
          <BreadcrumbsComponent selectedBrand={selectedBrand} selectedRefresh={selectedRefresh} />
          </AppBar>

         {tabValue === 0 && <ModelingSetupTab setTabValue={setTabValue}/>}
         {tabValue === 1 && <ModelsResultsTab refresh={selectedRefresh} />}
      <Footer />
    </DashboardLayout>
  );
}

export default Modeling;

